import {
    GET_COUNTRY_REQUESTING,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR,
    GET_CITY_COUNTRY_REQUESTING,
    GET_CITY_COUNTRY_SUCCESS,
    GET_CITY_COUNTRY_ERROR
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    countries: [],
    cities: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COUNTRY_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case GET_COUNTRY_SUCCESS:
            return {
                ...state,
                countries: action.countries,
                requesting: false,
            };
        case GET_COUNTRY_ERROR:
            return {
                ...state,
                error: action.error,
                requesting: false,
            };
        case GET_CITY_COUNTRY_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case GET_CITY_COUNTRY_SUCCESS:
            return {
                ...state,
                cities: action.cities,
                requesting: false,
            };
        case GET_CITY_COUNTRY_ERROR:
            return {
                ...state,
                error: action.error,
                requesting: false,
            };
        default:
            return state;
    }
};

export default reducer;