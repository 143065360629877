import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {unitMeasuresGetRequesting} from '../../redux/unitMeasure/actions';

class UnitMeasureComponent extends React.Component {

    componentWillMount() {
        const {
            client: {token},
            unitMeasuresGetRequesting,
        } = this.props;
        unitMeasuresGetRequesting(token);
    }

    render() {
        const {
            unitMeasure: {unitMeasures},
            InitialUnitMeasure
        } = this.props;
        return (
            <Field name="unidad_medida" type="select" className="select-unitMeasure" component="select">
                {InitialUnitMeasure ? <option value={InitialUnitMeasure.nombre}>{InitialUnitMeasure.nombre}</option> :
                    <option>...Seleccione</option>}
                {unitMeasures.length > 0 && unitMeasures.map((unitMeasure, index) => {
                    if (InitialUnitMeasure == undefined)
                        return <option key={index} value={unitMeasure.nombre}>{unitMeasure.medida}</option>
                    else if (unitMeasure.id !== InitialUnitMeasure.id)
                        return <option key={index} value={unitMeasure.nombre}>{unitMeasure.medida}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    unitMeasure: state.unitMeasure,
});

const connected = connect(mapStateToProps, {
    unitMeasuresGetRequesting
})(UnitMeasureComponent);

export default connected;