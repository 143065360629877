import {
    GET_COUNTRY_REQUESTING,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_ERROR,
    GET_CITY_COUNTRY_REQUESTING,
    GET_CITY_COUNTRY_SUCCESS,
    GET_CITY_COUNTRY_ERROR
} from "./constants";

export const getCountryRequesting = (token) => (
    {
        type: GET_COUNTRY_REQUESTING,
        token
    }
);
export const getCountrySuccess = (countries) => (
    {
        type: GET_COUNTRY_SUCCESS,
        countries
    }
);
export const getCountryError = (error) => (
    {
        type: GET_COUNTRY_ERROR,
        error
    }
);
export const getCityCountryRequesting = (countryId,token) => (
    {
        type: GET_CITY_COUNTRY_REQUESTING,
        countryId,
        token
    }
);
export const getCityCountrySuccess = (cities) => (
    {
        type: GET_CITY_COUNTRY_SUCCESS,
        cities
    }
);
export const getCityCountryError = (error) => (
    {
        type: GET_CITY_COUNTRY_ERROR,
        error
    }
);
