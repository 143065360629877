import {COUNTRIES_GET_ERROR, COUNTRIES_GET_REQUESTING, COUNTRIES_GET_SUCCESS} from "./constants";

export const countriesGetRequesting = (token) => ({
    type: COUNTRIES_GET_REQUESTING,
    token,
});

export const countriesGetSuccess = (countries) => ({
    type: COUNTRIES_GET_SUCCESS,
    countries,
});

export const countriesGetError = (error) => ({
    type: COUNTRIES_GET_ERROR,
    error,
});

