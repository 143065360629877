import {
    CHECK_AUTH,
    CHECK_AUTH_ERROR,
    CHECK_AUTH_SUCCESS,
    CLIENT_SET,
    CLIENT_UNSET
} from "./constans";

const initialState = {
    token: '',
    isLogged: false,
    requesting: false,
    isLoad: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_SET :
            return {
                token: action.token,
                isLogged: true
            };
        case CLIENT_UNSET :
            return {
                token: '',
                isLogged: false
            };
        case CHECK_AUTH :
            return {
                ...state,
                requesting: true,
                isLoad: false
            };
        case CHECK_AUTH_SUCCESS :
            return {
                requesting: false,
                token: action.token,
                isLogged: true,
                isLoad: true
            };
        case CHECK_AUTH_ERROR :
            return {
                requesting: false,
                token: '',
                isLogged: false,
                isLoad: true
            };
        default :
            return state;
    }
};

export default reducer;