import {GET_SUBCATEGORY_ERROR, GET_SUBCATEGORY_REQUESTING, GET_SUBCATEGORY_SUCCESS} from "./constants";


export const getSubcategoryRequesting = (token) => ({
    type: GET_SUBCATEGORY_REQUESTING,
    token
});

export const getSubcategorySuccess = (subcategories) => ({
    type: GET_SUBCATEGORY_SUCCESS,
    subcategories
});

export const getSubcategoryError = (error) => ({
    type: GET_SUBCATEGORY_ERROR,
    error
});