import {
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS, CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS,
    QUOTATION_FOCUS, RESET_STATES,
    SERVICE_FOCUS,
    SERVICE_FOCUS_CLEAN,
    SERVICES_DESIGN_GET_ERROR,
    SERVICES_DESIGN_GET_REQUESTING,
    SERVICES_DESIGN_GET_SUCCESS,
    SERVICES_INSTALLATION_GET_ERROR,
    SERVICES_INSTALLATION_GET_REQUESTING,
    SERVICES_INSTALLATION_GET_SUCCESS,
    SERVICES_MAINTENANCE_GET_ERROR,
    SERVICES_MAINTENANCE_GET_REQUESTING,
    SERVICES_MAINTENANCE_GET_SUCCESS,
    SERVICES_SEARCH_DESIGN_ERROR,
    SERVICES_SEARCH_DESIGN_REQUESTING,
    SERVICES_SEARCH_DESIGN_SUCCESS,
    SERVICES_SEARCH_INSTALLATION_ERROR,
    SERVICES_SEARCH_INSTALLATION_REQUESTING,
    SERVICES_SEARCH_INSTALLATION_SUCCESS,
    SERVICES_SEARCH_MAINTENANCE_ERROR,
    SERVICES_SEARCH_MAINTENANCE_REQUESTING,
    SERVICES_SEARCH_MAINTENANCE_SUCCESS,
    STATES_SERVICE_GET_ERROR,
    STATES_SERVICE_GET_REQUESTING,
    STATES_SERVICE_GET_SUCCESS
} from "./constants";

const initialState = {
    requestingDesignGet: false,
    successDesignGet: false,

    requestingInstallationGet: false,
    successInstallationGet: false,

    requestingMaintenanceGet: false,
    successMaintenanceGet: false,

    requestingSearchDesign: false,
    successSearchDesign: false,

    requestingSearchInstallation: false,
    successSearchInstallation: false,

    requestingSearchMaintenance: false,
    successSearchMaintenance: false,

    requestingStates: false,
    successStates: false,

    requestingConfirmed: false,
    successConfirmed: false,

    stringSearchDesign: '',
    errorsDesign: '',
    filterDesign: '',
    paginateDesign: 1,
    hasMoreDesign: true,
    hasLessDesign: false,

    stringSearchInstallation: '',
    errorsInstallation: '',
    filterInstallation: '',
    paginateInstallation: 1,
    hasMoreInstallation: true,
    hasLessInstallation: false,

    stringSearchMaintenance: '',
    errorsMaintenance: '',
    filterMaintenance: '',
    paginateMaintenance: 1,
    hasMoreMaintenance: true,
    hasLessMaintenance: false,

    servicesDesign: [],
    servicesInstallation: [],
    servicesMaintenance: [],
    servicesSearchDesign: [],
    servicesSearchInstallation: [],
    servicesSearchMaintenance: [],
    statesService: [],
    service: {},
    quotation: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SERVICES_DESIGN_GET_REQUESTING:
            return {
                ...state,
                requestingDesignGet: true,
                paginateDesign: action.paginateDesign,
                filterDesign: action.filterDesign,
            };
        case SERVICES_DESIGN_GET_SUCCESS:
            return {
                ...state,
                requestingDesignGet: false,
                successDesignGet: true,
                paginateDesign: state.paginateDesign,
                filterDesign: state.filterDesign,
                hasLessDesign: state.paginateDesign !== 1,
                hasMoreDesign: true,
                servicesDesign: action.servicesDesign,
            };
        case SERVICES_DESIGN_GET_ERROR:
            return {
                ...state,
                requestingDesignGet: false,
                successDesignGet: false,
                errorsDesign: action.errorDesign,
                paginateDesign: state.paginateDesign,
                hasMoreDesign: false,
                hasLessDesign: state.paginateDesign !== 1,
                filterDesign: state.filterDesign,
                servicesDesign: [],
            };
        case SERVICES_INSTALLATION_GET_REQUESTING:
            return {
                ...state,
                requestingInstallationGet: true,
                paginateInstallation: action.paginateInstallation,
                filterInstallation: action.filterInstallation,
            };
        case SERVICES_INSTALLATION_GET_SUCCESS:
            return {
                ...state,
                requestingInstallationGet: false,
                successInstallationGet: true,
                paginateInstallation: state.paginateInstallation,
                filterInstallation: state.filterInstallation,
                hasLessInstallation: state.paginateInstallation !== 1,
                hasMoreInstallation: true,
                servicesInstallation: action.servicesInstallation,
            };
        case SERVICES_INSTALLATION_GET_ERROR:
            return {
                ...state,
                requestingInstallationGet: false,
                successInstallationGet: false,
                errorsInstallation: action.errorInstallation,
                paginateInstallation: state.paginateInstallation,
                hasMoreInstallation: false,
                hasLessInstallation: state.paginateInstallation !== 1,
                filterInstallation: state.filterInstallation,
                servicesInstallation: [],
            };
        case SERVICES_MAINTENANCE_GET_REQUESTING:
            return {
                ...state,
                requestingMaintenanceGet: true,
                paginateMaintenance: action.paginateMaintenance,
                filterMaintenance: action.filterMaintenance,
            };
        case SERVICES_MAINTENANCE_GET_SUCCESS:
            return {
                ...state,
                requestingMaintenanceGet: false,
                successMaintenanceGet: true,
                paginateMaintenance: state.paginateMaintenance,
                filterMaintenance: state.filterMaintenance,
                hasLessMaintenance: state.paginateMaintenance !== 1,
                hasMoreMaintenance: true,
                servicesMaintenance: action.servicesMaintenance,
            };
        case SERVICES_MAINTENANCE_GET_ERROR:
            return {
                ...state,
                requestingMaintenanceGet: false,
                successMaintenanceGet: false,
                errorsMaintenance: action.errorMaintenance,
                paginateMaintenance: state.paginateMaintenance,
                hasMoreMaintenance: false,
                hasLessMaintenance: state.paginateMaintenance !== 1,
                filterMaintenance: state.filterMaintenance,
                servicesMaintenance: [],
            };
        case SERVICES_SEARCH_DESIGN_REQUESTING:
            return {
                ...state,
                requestingSearchDesign: true,
                stringSearchDesign: action.search,
            };
        case SERVICES_SEARCH_DESIGN_SUCCESS:
            return {
                ...state,
                requestingSearchDesign: false,
                successSearchDesign: true,
                stringSearchDesign: state.stringSearchDesign,
                servicesSearchDesign: action.servicesSearchDesign,
            };
        case SERVICES_SEARCH_DESIGN_ERROR:
            return {
                ...state,
                requestingSearchDesign: false,
                successSearchDesign: false,
                errorsDesign: action.errorDesign,
                stringSearchDesign: state.stringSearchDesign,
            };
        case SERVICES_SEARCH_INSTALLATION_REQUESTING:
            return {
                ...state,
                requestingSearchInstallation: true,
                stringSearchInstallation: action.search,
            };
        case SERVICES_SEARCH_INSTALLATION_SUCCESS:
            return {
                ...state,
                requestingSearchInstallation: false,
                successSearchInstallation: true,
                stringSearchInstallation: state.stringSearchInstallation,
                servicesSearchInstallation: action.servicesInstallationSearch
            };
        case SERVICES_SEARCH_INSTALLATION_ERROR:
            return {
                ...state,
                requestingSearchInstallation: false,
                successSearchInstallation: false,
                errorsInstallation: action.error,
                stringSearchInstallation: state.search,
            };
        case SERVICES_SEARCH_MAINTENANCE_REQUESTING:
            return {
                ...state,
                requestingSearchMaintenance: true,
                stringSearchMaintenance: action.search,
            };
        case SERVICES_SEARCH_MAINTENANCE_SUCCESS:
            return {
                ...state,
                requestingSearchMaintenance: false,
                successSearchMaintenance: true,
                stringSearchMaintenance: state.stringSearchMaintenance,
                servicesSearchMaintenance: action.servicesMaintenanceSearch,
            };
        case SERVICES_SEARCH_MAINTENANCE_ERROR:
            return {
                ...state,
                requestingSearchMaintenance: false,
                successSearchMaintenance: false,
                errorsMaintenance: action.errorSearch,
                stringSearchMaintenance: state.search,
            };
        case STATES_SERVICE_GET_REQUESTING:
            return {
                ...state,
                requestingStates: true,
            };

        case STATES_SERVICE_GET_SUCCESS:
            return {
                ...state,
                requestingStates: false,
                successStates: true,
                statesService: action.states,
            };
        case STATES_SERVICE_GET_ERROR:
            return {
                ...state,
                requestingStates: false,
                error: action.error,
            };
        case SERVICE_FOCUS:
            return {
                ...state,
                service: action.service,
            };
        case SERVICE_FOCUS_CLEAN:
            return {
                ...state,
                service: {},
            };
        case CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING:
            return {
                ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesDesign: state.servicesDesign.length > 0 ? state.servicesDesign.map((service) => service.id === action.service.id ? action.service : service) : state.servicesDesign,
                servicesSearchDesign: state.servicesSearchDesign.length > 0 ? state.servicesSearchDesign.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchDesign,
            };
        case CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING:
            return {
                ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesInstallation: state.servicesInstallation.length > 0 ? state.servicesInstallation.map((service) => service.id === action.service.id ? action.service : service) : state.servicesInstallation,
                servicesSearchInstallation: state.servicesSearchInstallation.length > 0 ? state.servicesSearchInstallation.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchInstallation,
            };
        case CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING:
            return {
                ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesMaintenance: state.servicesMaintenance.length > 0 ? state.servicesMaintenance.map((service) => service.id === action.service.id ? action.service : service) : state.servicesMaintenance,
                servicesSearchMaintenance: state.servicesSearchMaintenance.length > 0 ? state.servicesSearchMaintenance.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchMaintenance,
            };
        case CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case QUOTATION_FOCUS:
            return {
                ...state,
                quotation: action.quotation,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING:
            return {
                 ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesDesign: state.servicesDesign.length > 0 ? state.servicesDesign.map((service) => service.id === action.service.id ? action.service : service) : state.servicesDesign,
                servicesSearchDesign: state.servicesSearchDesign.length > 0 ? state.servicesSearchDesign.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchDesign,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING:
            return {
                ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesInstallation: state.servicesInstallation.length > 0 ? state.servicesInstallation.map((service) => service.id === action.service.id ? action.service : service) : state.servicesInstallation,
                servicesSearchInstallation: state.servicesSearchInstallation.length > 0 ? state.servicesSearchInstallation.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchInstallation,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING:
            return {
                ...state,
                requestingConfirmed: true,
                successConfirmed: false,
                error: '',
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS:
            return {
                ...state,
                requestingConfirmed: false,
                successConfirmed: true,
                servicesMaintenance: state.servicesMaintenance.length > 0 ? state.servicesMaintenance.map((service) => service.id === action.service.id ? action.service : service) : state.servicesMaintenance,
                servicesSearchMaintenance: state.servicesSearchMaintenance.length > 0 ? state.servicesSearchMaintenance.map((service) => service.id === action.service.id ? action.service : service) : state.servicesSearchMaintenance,
            };
        case CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR:
            return {
                ...state,
                requestingConfirmed: false,
                error: action.error,
            };
        case RESET_STATES:
            return {
                ...state,
                requestingDesignGet: false,
                successDesignGet: false,

                requestingInstallationGet: false,
                successInstallationGet: false,

                requestingMaintenanceGet: false,
                successMaintenanceGet: false,

                requestingSearchDesign: false,
                successSearchDesign: false,

                requestingSearchInstallation: false,
                successSearchInstallation: false,

                requestingSearchMaintenance: false,
                successSearchMaintenance: false,

                requestingStates: false,
                successStates: false,

                requestingConfirmed: false,
                successConfirmed: false,

                service: {},
                quotation: {},
            };
        default:
            return state;
    }
};

export default reducer;
