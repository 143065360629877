import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {getSubcategoryError, getSubcategorySuccess} from "./actions";
import {GET_SUBCATEGORY_REQUESTING} from "./constants";


const getSubcategoryUrl = `${process.env.REACT_APP_API_URL}/api/subcategoriesEmergencyCall`;

const getSubcategoryApi = (token) => {
    return fetch(getSubcategoryUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* getSubcategoryFlow(action) {
    try {
        const {token} = action;
        const subcategories = yield call(getSubcategoryApi, token);
        yield put(getSubcategorySuccess(subcategories));
    } catch (error) {
        yield put(getSubcategoryError(error));
    }
}

function* subcategoryWatcher() {
    yield all([
        takeEvery(GET_SUBCATEGORY_REQUESTING, getSubcategoryFlow),
    ])
}

export default subcategoryWatcher;