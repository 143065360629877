import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {getOtherProjectError, getOtherProjectSuccess, getProjectError, getProjectSuccess} from "./actions";
import {GET_OTHER_PROJECT_REQUESTING, GET_PROJECT_REQUESTING} from "./constants";


const getProjectUrl = `${process.env.REACT_APP_API_URL}/api/projects`;
const getOtherProjectUrl = `${process.env.REACT_APP_API_URL}/api/projectsProductMaintenanceAndInstall`;

const getProjectApi = (token) => {
    return fetch(getProjectUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* getProjectFlow(action) {
    try {
        const {token} = action;
        const projects = yield call(getProjectApi, token);
        yield put(getProjectSuccess(projects));
    } catch (error) {
        yield put(getProjectError(error));
    }
}

const getOtherProjectApi = (token) => {
    return fetch(getOtherProjectUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* getOtherProjectFlow(action) {
    try {
        const {token} = action;
        const otherProjects = yield call(getOtherProjectApi, token);
        yield put(getOtherProjectSuccess(otherProjects));
    } catch (error) {
        yield put(getOtherProjectError(error));
    }
}

function* projectWatcher() {
    yield all([
        takeEvery(GET_PROJECT_REQUESTING, getProjectFlow),
        takeEvery(GET_OTHER_PROJECT_REQUESTING, getOtherProjectFlow)
    ])
}

export default projectWatcher;
