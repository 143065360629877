import {
    GET_TYPE_IDENTIFICATION_REQUESTING,
    GET_TYPE_IDENTIFICATION_SUCCESS,
    GET_TYPE_IDENTIFICATION_ERROR
} from "./constants";

export const getTypeIdentificationRequesting = (token) => (
    {
        type: GET_TYPE_IDENTIFICATION_REQUESTING,
        token
    }
);
export const getTypeIdentificationSuccess = (typeIdentifications) => (
    {
        type: GET_TYPE_IDENTIFICATION_SUCCESS,
        typeIdentifications
    }
);
export const getTypeIdentificationError = (error) => (
    {
        type: GET_TYPE_IDENTIFICATION_ERROR,
        error
    }
);
