import {
    CHECK_AUTH,
    CHECK_AUTH_ERROR,
    CHECK_AUTH_SUCCESS,
    CLIENT_SET,
    CLIENT_UNSET
} from "./constans";


export const clientSet = (token) => ({
    type: CLIENT_SET,
    token
});

export const clientUnSet = () => ({
    type: CLIENT_UNSET
});

export const checkAuth = () => ({
    type: CHECK_AUTH
});

export const checkAuthSuccess = (token) => ({
    type: CHECK_AUTH_SUCCESS,
    token,
});

export const checkAuthError = (error) => ({
    type: CHECK_AUTH_ERROR,
    error,
});