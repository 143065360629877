export const GET_USER_REQUESTING = 'GET_USER_REQUESTING';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const SEARCH_USER_REQUESTING = 'SEARCH_USER_REQUESTING';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';

export const CHANGE_STATE_USER_REQUESTING = 'CHANGE_STATE_USER_REQUESTING';
export const CHANGE_STATE_USER_SUCCESS = 'CHANGE_STATE_USER_SUCCESS';
export const CHANGE_STATE_USER_ERROR = 'CHANGE_STATE_USER_ERROR';

export const CREATE_USER_REQUESTING = 'CREATE_USER_REQUESTING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_REQUESTING = 'UPDATE_USER_REQUESTING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const SET_FOCUS_USER = 'SET_FOCUS_USER';

export const DELETE_DEVICE_REQUESTING = 'DELETE_DEVICE_REQUESTING';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR';
