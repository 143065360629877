import React from 'react';
import {connect} from 'react-redux';
import SearchComponent from "../search/search";
import FilterComponent from "../filter/filter";
import {TableProduct} from "./table/table-product";
import ModalNewProduct from "./modals/add-product";
import ModalEditProduct from "./modals/edit-product"
import {TableProviders} from "./table/table-providers";
import ModalProviders from "./modals/add-providers";
import ModalEditProvider from "./modals/edit-providers";
import ModalAddDocument from "./modals/add-document";
import {
    getProviderRequesting,
    changeStateProviderRequesting,
    searchProviderRequesting,
    setFocusProvider
} from "../../redux/provider/actions";
import {NotFound} from "../../commons/errors/notFound";
import {statesProviderRequesting} from "../../commons/states/actions";
import {getCountryRequesting} from "../../commons/countries/actions";
import {deleteDocumentProviderRequesting} from "../../redux/documents/actions";
import {
    getProductRequesting,
    changeStateProductRequesting,
    productSearchRequesting,
    setFocusProduct,
    capacityFocus,
    capacityDeleteRequesting,
} from "../../redux/product/actions";
import {statesProductRequesting} from "../../commons/states/actions";
import ModalAddCapacity from './modals/add-capacity';
import ModalEditCapacity from './modals/edit-capacity';
import ModalViewCapacities from './modals/view-capacities';

class ProductComponent extends React.Component {

    state = {
        isProduct: false,
        isEditProduct: false,
        isProviders: false,
        isEditProvider: false,
        isAddDocument: false,
        addCapacity: false,
        editCapacity: false,
        viewCapacities: false,
    };

    handleShowModal = (modal, id = 0, product = {}) => {
        const {setFocusProvider, setFocusProduct} = this.props;
        if (modal === 'editar' || modal === 'Crear documento')
            setFocusProvider(id, product);
        if (modal === 'editar producto')
            setFocusProduct(id, product);

        this.setState({
            isProduct: modal === 'nuevo producto',
            isEditProduct: modal === 'editar producto',
            isProviders: modal === 'nuevo proveedor',
            isEditProvider: modal === 'editar',
            isAddDocument: modal === 'Crear documento',
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isProduct: false,
            isEditProduct: false,
            isProviders: false,
            isEditProvider: false,
            isAddDocument: false,
            addCapacity: false,
            editCapacity: false,
            viewCapacities: false,
        })
    };


    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillMount() {
        const {
            getProviderRequesting,
            client: {token},
            provider: {cursor, filter},
            statesProviderRequesting,
            getCountryRequesting,
            //product
            getProductRequesting,
            product: {paginateProduct, filterProduct},
            statesProductRequesting
        } = this.props;
        statesProviderRequesting(token);
        getCountryRequesting(token);
        getProviderRequesting(cursor, filter, token);
        getCountryRequesting(token);
        //product
        // getProductRequesting(paginateProduct, filterProduct, token);
        // statesProductRequesting(token);
    }

    //Funciones producto
    handleGetCsvProduct = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/products`, "_self")
    };

    handleChangeStateProduct = (id) => {
        const {changeStateProductRequesting, client} = this.props;
        changeStateProductRequesting(id, client.token);
    };

    handleSearchProduct = (value) => {
        const {productSearchRequesting, product} = this.props;
        if ((product.searchProduct !== value.search) && value.search !== '')
            productSearchRequesting(value.search);
    };

    handleFilterProduct = (value) => {
        const {getProductRequesting, client, product} = this.props;
        if (product.filterProduct !== value)
            getProductRequesting(product.paginateProduct, value, client.token);
    };

    handleGetDataProduct = (paginate) => {
        const {getProductRequesting, client, product} = this.props;
        if (paginate === 1 && product.hasMore)
            getProductRequesting(product.paginateProduct + paginate, product.filterProduct, client.token);
        if (paginate === -1 && product.hasLess)
            getProductRequesting(product.paginateProduct + paginate, product.filterProduct, client.token);
    };

    //funciones proveedor
    handleGetDataProvider = (cursor) => {
        const {getProviderRequesting, client, provider} = this.props;
        if (cursor === 1 && provider.hasMore)
            getProviderRequesting(provider.cursor + cursor, provider.filter, client.token);
        if (cursor === -1 && provider.hasLess)
            getProviderRequesting(provider.cursor + cursor, provider.filter, client.token);
    };

    handleFilterProvider = (value) => {
        const {getProviderRequesting, client, provider} = this.props;
        if (provider.filter !== value)
            getProviderRequesting(provider.cursor, value, client.token);
    };

    handleChangeStateProvider = (id) => {
        const {changeStateProviderRequesting, client} = this.props;
        changeStateProviderRequesting(id, client.token);
    };

    handleSearch = (value) => {
        const {searchProviderRequesting, client, provider} = this.props;
        if ((provider.search !== value.search) && value.search !== '')
            searchProviderRequesting(value.search, client.token);
    };

    handleDeleteDocument = (document) => {
        const {deleteDocumentProviderRequesting, client} = this.props;
        deleteDocumentProviderRequesting(document, client.token)
    };

    handleGetCsvProvider = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/providers/export`, "_self")
    };

    handleDeleteCapacity = (object) => {
        const {capacityDeleteRequesting, client: {token}} = this.props;
        capacityDeleteRequesting(token, object);
    };

    handleFocusCapacity = (object) => {
        const {
            product: {capacity},
            capacityFocus
        } = this.props;
        if (capacity.id !== object.id)
            capacityFocus(object);
        this.setState({
            editCapacity: true,
        });
    };

    handleAddCapacity = (product) => {
        const {setFocusProduct} = this.props;
        setFocusProduct(product.id, product);
        this.setState({
            addCapacity: true,
        });
    };

    handleViewCapacities = (product) => {
        const {setFocusProduct} = this.props;
        setFocusProduct(product.id, product);
        this.setState({
            viewCapacities: true,
        });
    };

    handleHiddenModalsCapacities = () => {
        this.setState({
            addCapacity: false,
            editCapacity: false,
        })
    };

    render() {
        const {provider: {providers, search, provider}, product: {products, searchProduct, product}, state: {states}, state: {statesProduct}} = this.props;
        return (
            <div className="styles-container">
                {/*Proveedores*/}
                <SearchComponent
                    type={'Proveedores'}
                    placeholder={'Digita nombre del proveedor'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== '' && (
                        provider.length > 0 ? <TableProviders
                            handleShowModal={this.handleShowModal.bind(this)}
                            handleChangeState={this.handleChangeStateProvider.bind(this)}
                            handleDeleteDocument={this.handleDeleteDocument.bind(this)}
                            providers={provider}
                        /> : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        activeModal={true}
                        type={'nuevo proveedor'}
                        states={states}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilterProvider.bind(this)}
                        getData={this.handleGetDataProvider.bind(this)}
                        handleGetCsv={this.handleGetCsvProvider.bind(this)}
                    />
                    {providers.length > 0 ? (
                        <TableProviders
                            providers={providers}
                            handleShowModal={this.handleShowModal.bind(this)}
                            handleChangeState={this.handleChangeStateProvider.bind(this)}
                            handleDeleteDocument={this.handleDeleteDocument.bind(this)}
                        />
                    ) : <NotFound/>}
                    {this.state.isProviders && (
                        <ModalProviders
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    {this.state.isEditProvider && (
                        <ModalEditProvider
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>

                    )}
                    {this.state.isAddDocument && (
                        <ModalAddDocument
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                </div>
                {/*/!*Productos*!/*/}
                {/*<SearchComponent*/}
                    {/*type={'Productos'}*/}
                    {/*placeholder={'Consultar nombre'}*/}
                    {/*handleSearch={this.handleSearchProduct.bind(this)}*/}
                {/*>{searchProduct !== '' && (*/}
                    {/*product.length > 0*/}
                        {/*? <TableProduct handleShowModal={this.handleShowModal.bind(this)}*/}
                                        {/*products={product}*/}
                                        {/*handleChangeState={this.handleChangeStateProduct.bind(this)}*/}
                                        {/*shippingTypes={[]}*/}
                                        {/*addCapacity={this.handleAddCapacity.bind(this)}*/}
                                        {/*focusCapacity={this.handleFocusCapacity.bind(this)}*/}
                                        {/*deleteCapacity={this.handleDeleteCapacity.bind(this)}*/}
                                        {/*viewCapacities={this.handleViewCapacities.bind(this)}*/}
                        {/*/>*/}
                        {/*: <NotFound/>*/}
                {/*)}*/}
                {/*</SearchComponent>*/}
                {/*<div className="conteiner-page-sector1">*/}
                    {/*<FilterComponent*/}
                        {/*activeModal={true}*/}
                        {/*type={'nuevo producto'}*/}
                        {/*getData={this.handleGetDataProduct.bind(this)}*/}
                        {/*handleShowModal={this.handleShowModal.bind(this)}*/}
                        {/*handleGetCsv={this.handleGetCsvProduct.bind(this)}*/}
                        {/*handleFilter={this.handleFilterProduct.bind(this)}*/}
                        {/*states={statesProduct}*/}
                    {/*/>*/}
                    {/*{products.length > 0 ? (*/}
                        {/*<TableProduct*/}
                            {/*products={products}*/}
                            {/*handleShowModal={this.handleShowModal.bind(this)}*/}
                            {/*handleChangeState={this.handleChangeStateProduct.bind(this)}*/}
                            {/*shippingTypes={[]}*/}
                            {/*addCapacity={this.handleAddCapacity.bind(this)}*/}
                            {/*focusCapacity={this.handleFocusCapacity.bind(this)}*/}
                            {/*deleteCapacity={this.handleDeleteCapacity.bind(this)}*/}
                            {/*viewCapacities={this.handleViewCapacities.bind(this)}*/}
                        {/*/>*/}
                    {/*) : <NotFound/>}*/}
                    {/*{this.state.isProduct && (*/}
                        {/*<ModalNewProduct*/}
                            {/*stop={this.stopPropagation.bind(this)}*/}
                            {/*hidden={this.handleHiddenModal.bind(this)}/>*/}
                    {/*)}*/}
                    {/*{this.state.isEditProduct && (*/}
                        {/*<ModalEditProduct*/}
                            {/*stop={this.stopPropagation.bind(this)}*/}
                            {/*hidden={this.handleHiddenModal.bind(this)}*/}
                        {/*/>*/}
                    {/*)}*/}
                    {/*{this.state.viewCapacities && (*/}
                        {/*<ModalViewCapacities stop={this.stopPropagation.bind(this)}*/}
                                             {/*hidden={this.handleHiddenModal.bind(this)}*/}
                                             {/*editCapacity={this.handleFocusCapacity.bind(this)}*/}
                                             {/*deleteCapacity={this.handleDeleteCapacity.bind(this)}*/}
                        {/*/>*/}
                    {/*)}*/}
                    {/*{this.state.addCapacity && (*/}
                        {/*<ModalAddCapacity stop={this.stopPropagation.bind(this)}*/}
                                          {/*hidden={this.handleHiddenModalsCapacities.bind(this)}/>*/}
                    {/*)}*/}

                    {/*{this.state.editCapacity && (*/}
                        {/*<ModalEditCapacity stop={this.stopPropagation.bind(this)}*/}
                                           {/*hidden={this.handleHiddenModalsCapacities.bind(this)}/>*/}
                    {/*)}*/}
                {/*</div>*/}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    provider: state.provider,
    product: state.product,
    state: state.state,
});

const connected = connect(mapStateToProps, {
    //providers
    getProviderRequesting,
    changeStateProviderRequesting,
    statesProviderRequesting,
    searchProviderRequesting,
    setFocusProvider,
    deleteDocumentProviderRequesting,
    //products
    getProductRequesting,
    changeStateProductRequesting,
    productSearchRequesting,
    setFocusProduct,
    statesProductRequesting,
    getCountryRequesting,
    capacityFocus,
    capacityDeleteRequesting,
})(ProductComponent);


export default connected
