import React from "react";
import { ModalLeaderManager } from "../modals/add-leader-manager";
import { Multiselect } from "react-widgets";
import connect from "react-redux/es/connect/connect";
import {
  getCategoryLeaderManagerRequesting,
  deleteCategoryLeaderManagerRequesting,
  getSubcategoryLeaderManagerRequesting,
  deleteSubcategoryLeaderManagerRequesting,
} from "../../../redux/leader-manager/actions";
import "react-widgets/dist/css/react-widgets.css";
import "react-widgets/lib/less/react-widgets.less";


						
							 


class TableLeaderManager extends React.Component {
  state = {
    isLeaderManager: false,
  };

  handleShowModal = (modal) => {
    this.setState({
      isLeaderManager: modal === "gerente lider",
    });
  };
  handleHiddenModal = () => {
    this.setState({
      isLeaderManager: false,
      // *******************************
    });
		  
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleAddCategory = (category, leaderManager) => {
    const {
      getCategoryLeaderManagerRequesting,
      client: { token },
    } = this.props;
    getCategoryLeaderManagerRequesting(token, leaderManager, category.id);
  };

  handleAddSubcategory = (subcategory, leaderManager) => {
    const {
      getSubcategoryLeaderManagerRequesting,
      client: { token },
    } = this.props;
    getSubcategoryLeaderManagerRequesting(token, leaderManager, subcategory.id);
  };


  handleDeleteCategory = (category, leaderManager) => {
    const {
      deleteCategoryLeaderManagerRequesting,
      client: { token },
    } = this.props;
    deleteCategoryLeaderManagerRequesting(token, leaderManager, category.id);
  };

  handleDeleteSubcategory = (subcategory, leaderManager) => {
    const {
      deleteSubcategoryLeaderManagerRequesting,
      client: { token },
    } = this.props;
    deleteSubcategoryLeaderManagerRequesting(
      token,
      leaderManager,
      subcategory.id
    );
  };

  render() {
    const {
      leaderManagers,
      handleChangeState,
      category: { categories },
      handleDeleteDocument,
      subcategory: { subcategories },
      handleDeleteDevice,
    } = this.props;
    const getSubcategories = (leaderManager) => {
      let subcategories = leaderManager.subcategorias.data.map(
        (subcategory, index) => ({
          id: subcategory.id,
          nombre: subcategory.nombre,
        })
      );
      return subcategories;
    };

    let endSubcategory = subcategories.map((subcategory, index) => ({
      id: subcategory.id,
      nombre: subcategory.nombre,
    }));
    return (
      <div className="Conteiner-table">
        {this.state.isLeaderManager && (
          <ModalLeaderManager
            stop={this.stopPropagation.bind(this)}
            hidden={this.handleHiddenModal.bind(this)}
          />
        )}
        <table className="table-leader-manager">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Cedula</th>
              <th>Ciudad</th>
              <th>País</th>
              <th>Dirección</th>
              <th>Teléfono</th>
              <th>Correo</th>
              <th>Persona de Contacto</th>
              <th>Número de Contacto</th>
              <th>Número de Servicios Atendidos</th>
              <th>Calificación General</th>
              <th>Foto</th>
              <th>Token</th>
              <th>Estado</th>
              <th>Documentos adjuntos</th>
              <th>Categoria a las que Pertenece</th>
              <th>Servicios que Ofrece</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {leaderManagers.map((leaderManager, index) => (

              <tr key={index}>
                <td>{leaderManager.usuario.data[0].nombre}</td>
                <td>{leaderManager.usuario.data[0].identificacion}</td>
                <td>{leaderManager.usuario.data[0].pais.data.nombre}</td>
                <td>{leaderManager.usuario.data[0].ciudad.nombre}</td>
                <td>{leaderManager.usuario.data[0].direccion}</td>
                <td>{leaderManager.usuario.data[0].telefono}</td>
                <td>{leaderManager.usuario.data[0].correo_electronico}</td>
                <td>{leaderManager.nombre_contacto}</td>
                <td>{leaderManager.telefono_contacto}</td>
                <td>{leaderManager.servicios_atendidos}</td>
                <td>{leaderManager.calificacion}</td>
                <td>
                  {
                    <img
                      src={leaderManager.usuario.data[0].foto}
                      alt=""
                      style={{ width: 90, height: 90 }}
                    />
																  
																											 
																				   
								 
														   
														
                  }

                </td>
                <td>
                  {leaderManager.usuario.data[0].token ? (
                    <button
                      className="Btn-item-table"
                      onClick={() => handleDeleteDevice(leaderManager.id)}
                    >
                      Eliminar token
                      <i className="icon-cancel styles-icons" />
                    </button>
                  ) : (
                    <p style={{ color: "gray" }}>Sin token activo</p>
                  )}
                </td>
                <td>
                  <input className="style-checkbox" type="checkbox" onClick={() =>
                      handleChangeState(leaderManager.usuario.data[0].id)}
                    checked={leaderManager.usuario.data[0].estado.nombre ==="Habilitado"}
                  />
                  <p className={leaderManager.usuario.data[0].estado.nombre !== "Habilitado" ? "style-checkbox-on": "style-checkbox-off"}
                  >
                    {leaderManager.usuario.data[0].estado.nombre}
                  </p>
                </td>
                <td>
                  {
                    <div>
                      {leaderManager.documentos.data.length > 0 &&
                        leaderManager.documentos.data.map((document, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <a href={document.src} target={"_blank"}>
                              {document.nombre.length > 30
                                ? document.nombre.substring(0, 30) + "..."
                                : document.nombre}
                            </a>
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDeleteDocument(document.id)}
                              className="icon-trash styles-icons2"
                            />
                          </div>
                        ))}
                      <button
                        className="Btn-general-edit-tabla"
                        onClick={this.props.handleShowModal.bind(
                          this,
                          "Crear documento",
                          leaderManager.id,
                          leaderManager
                        )}
                      >
                        Agregar
                        <i />
                      </button>
                    </div>
                  }
                </td>
                <td>
                  <Multiselect
                    data={categories}
                    textField="nombre"
                    value={
                      leaderManager.categorias.data.length > 0
                        ? leaderManager.categorias.data
                        : []
                    }
                    onChange={(value, metadata) =>
                      metadata.action === "insert"
                        ? this.handleAddCategory(
                            metadata.dataItem,
                            leaderManager.id
                          )
                        : this.handleDeleteCategory(
                            metadata.dataItem,
                            leaderManager.id
                          )
                    }
                  />
                </td>
                <td>
                  <Multiselect
                    data={endSubcategory}
                    textField="nombre"
                    value={getSubcategories(leaderManager)}
                    onChange={(value, metadata) =>
                      metadata.action === "insert"
                        ? this.handleAddSubcategory(
                            metadata.dataItem,
                            leaderManager.id
                          )
                        : this.handleDeleteSubcategory(
                            metadata.dataItem,
                            leaderManager.id
                          )
                    }
                  />
                </td>
                <td>
                  <button
                    className="Btn-item-table"
                    onClick={() =>
                      this.props.handleShowModal(
                        "editar",
                        leaderManager.id,
                        leaderManager
                      )
                    }
                  >
                    Editar <i className="icon-pencil styles-icons" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leaderManager: state.leaderManager,
  client: state.client,
  state: state.state,
  category: state.category,
  subcategory: state.subcategory,
});

const connected = connect(mapStateToProps, {
  getCategoryLeaderManagerRequesting,
  deleteCategoryLeaderManagerRequesting,
  getSubcategoryLeaderManagerRequesting,
  deleteSubcategoryLeaderManagerRequesting,
})(TableLeaderManager);

export default connected;
