export const ORDERS_GET_REQUESTING = 'ORDERS_GET_REQUESTING';
export const ORDERS_GET_SUCCESS = 'ORDERS_GET_SUCCESS';
export const ORDERS_GET_ERROR = 'ORDERS_GET_ERROR';

export const ORDER_SHIPPING_PUT_REQUESTING = 'ORDER_SHIPPING_PUT_REQUESTING';
export const ORDER_SHIPPING_PUT_SUCCESS = 'ORDER_SHIPPING_PUT_SUCCESS';
export const ORDER_SHIPPING_PUT_ERROR = 'ORDER_SHIPPING_PUT_ERROR';

export const ORDER_STATE_POST_REQUESTING = 'ORDER_STATE_POST_REQUESTING';
export const ORDER_STATE_POST_SUCCESS = 'ORDER_STATE_POST_SUCCESS';
export const ORDER_STATE_POST_ERROR = 'ORDER_STATE_POST_ERROR';

export const ORDER_STATES_POST_REQUESTING = 'ORDER_STATES_POST_REQUESTING';
export const ORDER_STATES_POST_SUCCESS = 'ORDER_STATES_POST_SUCCESS';
export const ORDER_STATES_PORT_ERROR = 'ORDER_STATES_PORT_ERROR';

export const SET_FOCUS_ORDER = 'SET_FOCUS_ORDER';

export const ORDER_CHANGE_STATE_REQUESTING = 'ORDER_CHANGE_STATE_REQUESTING';
export const ORDER_CHANGE_STATE_SUCCESS = 'ORDER_CHANGE_STATE_SUCCESS';
export const ORDER_CHANGE_STATE_ERROR = 'ORDER_CHANGE_STATE_ERROR';

export const ORDER_SEARCH_REQUESTING = 'ORDER_SEARCH_REQUESTING';
export const ORDER_SEARCH_SUCCESS = 'ORDER_SEARCH_SUCCESS';
export const ORDER_SEARCH_ERROR = 'ORDER_SEARCH_ERROR';

export const UPDATE_STATE_ORDER_REQUESTING = 'UPDATE_STATE_ORDER_REQUESTING';
export const UPDATE_STATE_ORDER_SUCCESS = 'UPDATE_STATE_ORDER_SUCCESS ';
export const UPDATE_STATE_ORDER_ERROR = 'UPDATE_STATE_ORDER_ERROR ';

export const CONFIRMED_WIRE_TRANSFER_ORDER_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_ORDER_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_ORDER_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_ORDER_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_ORDER_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_ORDER_PUT_ERROR';