import {GET_TYPE_IDENTIFICATION_REQUESTING,GET_TYPE_IDENTIFICATION_SUCCESS,GET_TYPE_IDENTIFICATION_ERROR} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    typeIdentifications: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TYPE_IDENTIFICATION_REQUESTING:
            return{
                ...state,
                requesting: true,
            };
        case GET_TYPE_IDENTIFICATION_SUCCESS:
            return{
                ...state,
                typeIdentifications: action.typeIdentifications,
                requesting: false,
            };
        case GET_TYPE_IDENTIFICATION_ERROR:
            return {
                ...state,
                error: action.error,
                requesting: false,
            };
        default:
            return state;
    }
};

export default reducer;