import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../errors/apiErrors";
import {
    statesGetSuccess,
    statesGetError,
    statesEmergencyCallSuccess,
    statesEmergencyCallError,
    statesUserSuccess,
    statesUserError,
    statesProviderSuccess,
    statesProviderError,
    statesProductError,
    statesProductSuccess,
    statesOrderSuccess,
    statesOrderError,
} from "./actions";
import {
    STATES_EMERGENCY_CALL_REQUESTING,
    STATES_GET_REQUESTING,
    STATES_USER_REQUESTING,
    STATES_PROVIDER_REQUESTING,
    STATES_PRODUCT_REQUESTING,
    STATES_ORDER_REQUESTING,
} from "./constans";

const statesURL = `${process.env.REACT_APP_API_URL}/api/states`;
const statesUserURL = `${process.env.REACT_APP_API_URL}/api/states/users`;
const statesProductURL = `${process.env.REACT_APP_API_URL}/api/states/products`;
const statesOrderURL = `${process.env.REACT_APP_API_URL}/api/states/orders`;

const statesGetApi = (token) => {
    return fetch(statesURL, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422) {
                let message = '';
                for (let key in json.data) {
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesGetFlow(action) {
    try {
        const {token} = action;
        const states = yield call(statesGetApi, token);
        yield put(statesGetSuccess(states));
    } catch (error) {
        yield put(statesGetError(error))
    }
}

//David

const statesEmergencyCallURL = `${process.env.REACT_APP_API_URL}/api/states/index/emergencycall`;
const statesProviderURL = `${process.env.REACT_APP_API_URL}/api/states/index/provider`;

const stateEmergencyCallAPI = (token) => {
    return fetch(statesEmergencyCallURL, {
        method: 'GET',
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesEmergencyCallFlow(action) {
    try{
        const {token} = action;
        const states = yield call(stateEmergencyCallAPI, token);
        yield put(statesEmergencyCallSuccess(states));
    }catch (error) {
        yield put(statesEmergencyCallError(error));
    }
}
//clients

const stateUserAPI = (token) => {
    return fetch(statesUserURL, {
        method: 'GET',
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesUserFlow(action) {
    try{
        const {token} = action;
        const states = yield call(stateUserAPI, token);
        yield put(statesUserSuccess(states));
    }catch (error) {
        yield put(statesUserError(error));
    }
}

const stateProviderAPI = (token) => {

    return fetch(statesProviderURL, {
        method: 'GET',
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesProviderFlow(action) {
    try{
        const {token} = action;
        const states = yield call(stateProviderAPI, token);
        yield put(statesProviderSuccess(states));
    }catch (error) {
        yield put(statesProviderError(error));
    }
}
//Products
const stateProductAPI = (token) => {
    return fetch(statesProductURL, {
        method: 'GET',
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesProductFlow(action) {
    try{
        const {token} = action;
        const states = yield call(stateProductAPI, token);
        yield put(statesProductSuccess(states));
    }catch (error) {
        yield put(statesProductError(error));
    }
}
//orders
const stateOrderAPI = (token) => {
    return fetch(statesOrderURL, {
        method: 'GET',
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesOrderFlow(action) {
    try{
        const {token} = action;
        const states = yield call(stateOrderAPI, token);
        yield put(statesOrderSuccess(states));
    }catch (error) {
        yield put(statesOrderError(error));
    }
}

function* statesWatcher() {
    yield all([
        takeEvery(STATES_GET_REQUESTING, statesGetFlow),
        takeEvery(STATES_EMERGENCY_CALL_REQUESTING,statesEmergencyCallFlow),
        takeEvery(STATES_PROVIDER_REQUESTING, statesProviderFlow),
        takeEvery(STATES_USER_REQUESTING,statesUserFlow),
        takeEvery(STATES_PRODUCT_REQUESTING,statesProductFlow),
        takeEvery(STATES_ORDER_REQUESTING,statesOrderFlow),
    ])
}

export default statesWatcher;