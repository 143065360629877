import React from 'react';
import FormattedCurrency from 'react-formatted-currency';

export class TableSupplies extends React.Component {

    render() {
        const {supplies} = this.props;
        return (
            <div className="Conteiner-table">
                <table className="table-supplies">
                    <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Referencia</th>
                        <th>Proveedor</th>
                        <th>Foto</th>
                        <th>Precio</th>
                        <th>Precio Gerente Lider</th>
                        <th>Precio descuento</th>
                        <th>Tiempo maximo envio</th>
                        <th>Precio envio</th>
                        <th>Cantidad inventario</th>
                        <th>Limite del inventario</th>
                        <th>Medidas</th>
                        <th>Marca</th>
                        <th>Estado</th>
                        <th>Accion</th>
                    </tr>
                    </thead>
                    <tbody>
                    {supplies.map((supply, index) => (
                        <tr key={index}>
                            <td>{supply.id}</td>
                            <td>{supply.nombre}</td>
                            <td>{supply.descripcion}</td>
                            <td>{supply.referencia}</td>
                            <td>{supply.proveedor.nombre}</td>
                            <td>
                                {supply.foto !== ''
                                    ? <img src={supply.foto} alt={supply.nombre} style={{width: 60, height: 60}}/>
                                    : 'Sin foto.'
                                }
                            </td>
                            <td>
                                <FormattedCurrency
                                    amount={supply.precio}
                                    currency={supply.proveedor.datos_pais.moneda}
                                    symbol={supply.proveedor.datos_pais.simbolo_moneda}
                                    locale={supply.proveedor.datos_pais.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>
                                <FormattedCurrency
                                    amount={supply.precio_gerente_lider}
                                    currency={supply.proveedor.datos_pais.moneda}
                                    symbol={supply.proveedor.datos_pais.simbolo_moneda}
                                    locale={supply.proveedor.datos_pais.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>
                                <FormattedCurrency
                                    amount={supply.precio_descuento}
                                    currency={supply.proveedor.datos_pais.moneda}
                                    symbol={supply.proveedor.datos_pais.simbolo_moneda}
                                    locale={supply.proveedor.datos_pais.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>{supply.tiempo_envio} días max</td>
                            <td>
                                <FormattedCurrency
                                    amount={supply.precio_envio}
                                    currency={supply.proveedor.datos_pais.moneda}
                                    symbol={supply.proveedor.datos_pais.simbolo_moneda}
                                    locale={supply.proveedor.datos_pais.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>{supply.inventario_disponible}</td>
                            <td>{supply.limite_inventario}</td>
                            <td>{supply.medidas} {supply.unidad_medida}</td>
                            <td>{supply.marca}</td>
                            <td>
                                <input className="style-checkbox"
                                       readOnly={'readOnly'}
                                       type="checkbox"
                                       checked={supply.estado === 'Habilitado'}
                                       onClick={() => this.props.handleChangeState(supply)}/>
                                <p className={supply.estado === 'Habilitado'
                                    ? "style-checkbox-off"
                                    : "style-checkbox-on"}>
                                    {supply.estado}
                                </p>
                            </td>
                            <td>
                                <button className="Btn-item-table"
                                        style={{backgroundColor: 'orange', borderColor: 'orange'}}
                                        onClick={() => {
                                            this.props.handleFocus(supply);
                                            this.props.handleShowModal('edit');
                                        }}>
                                    Editar
                                    <i className="icon-pencil styles-icons"/>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}