import {all, fork} from 'redux-saga/effects'
import LoginSagas from "./redux/login/sagas";
import CheckAuthSaga from './commons/client/sagas';
import StateSagas from './commons/states/sagas';
import LogoutSagas from './redux/menu-home/sagas';
import ServiceSagas from './redux/service/sagas';
import EmergencyCallSagas from './redux/emergency/sagas';
import ProviderSagas from './redux/provider/sagas';
import CitySagas from './redux/city/sagas';
import DocumentSagas from './redux/documents/sagas';
import LeaderManagerSagas from './redux/leader-manager/sagas';
import CategorySagas from './redux/category/sagas';
import UserSagas from './redux/user/sagas';
import TypeIdentificationSagas from './commons/type_identification/sagas';
import CountrySagas from './commons/countries/sagas';
import ProjectSagas from './redux/project/sagas';
import RateSagas from './redux/rate/sagas';
import SubcategorySagas from './redux/subcategory/sagas';
import BrandSagas from './redux/brand/sagas';
import PaymentMethod from './redux/paymentMethod/sagas';
import ShippingType from './redux/shippingType/sagas';
import ProductSagas from './redux/product/sagas'
import OrderSagas from './redux/order/sagas';
import SupplySagas from './redux/supply/sagas';
import unitMeasureSagas from './redux/unitMeasure/sagas';
export function* IndexSagas() {
    yield all([
        fork(LoginSagas),
        fork(CheckAuthSaga),
        fork(LogoutSagas),
        fork(StateSagas),
        fork(ServiceSagas),
        fork(EmergencyCallSagas),
        fork(ProviderSagas),
        fork(CitySagas),
        fork(DocumentSagas),
        fork(LeaderManagerSagas),
        fork(CategorySagas),
        fork(UserSagas),
        fork(TypeIdentificationSagas),
        fork(CountrySagas),
        fork(ProjectSagas),
        fork(RateSagas),
        fork(SubcategorySagas),
        fork(BrandSagas),
        fork(PaymentMethod),
        fork(ShippingType),
        fork(ProductSagas),
        fork(OrderSagas),
        fork(SupplySagas),
        fork(unitMeasureSagas),
    ]);
}
