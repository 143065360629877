import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import login from "./redux/login/reducer";
import {reducer as toastrReducer} from 'react-redux-toastr';
import client from './commons/client/reducer';
import state from './commons/states/reducer';
import logout from './redux/menu-home/reducer';
import service from './redux/service/reducer';
import emergencyCall from './redux/emergency/reducer';
import provider from './redux/provider/reducer';
import city from './redux/city/reducer';
import document from './redux/documents/reducer';
import leaderManager from './redux/leader-manager/reducer';
import category from './redux/category/reducer';
import user from './redux/user/reducer';
import typeIdentification from './commons/type_identification/reducer';
import country from './commons/countries/reducer';
import project from './redux/project/reducer';
import rate from './redux/rate/reducer';
import subcategory from './redux/subcategory/reducer';
import brand from './redux/brand/reducer';
import paymentMethod from './redux/paymentMethod/reducer';
import shippingType from './redux/shippingType/reducer';
import product from './redux/product/reducer';
import order from './redux/order/reducer';
import supply from './redux/supply/reducer';
import unitMeasure from './redux/unitMeasure/reducer';
const IndexReducer = combineReducers({
    form,
    login,
    toastr: toastrReducer,
    client,
    state,
    logout,
    service,
    emergencyCall,
    provider,
    city,
    document,
    category,
    leaderManager,
    user,
    typeIdentification,
    country,
    project,
    rate,
    subcategory,
    brand,
    paymentMethod,
    shippingType,
    product,
    order,
    supply,
    unitMeasure,
});

export default IndexReducer