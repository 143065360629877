import {
    LOGOUT_ERROR,
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    VERIFY_TOKEN,
    VERIFY_TOKEN_ERROR,
    VERIFY_TOKEN_SUCCESS,
} from './constants'

export const logoutRequest = (token) => ({
    type: LOGOUT_REQUESTING,
    token
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});


export const logoutError = (error) => ({
    type: LOGOUT_ERROR,
    error,
});

export const verifyToken = (response) => ({
    type: VERIFY_TOKEN,
    response,
});

export const verifyTokenSuccess = (response) => ({
    type: VERIFY_TOKEN_SUCCESS,
    response,
});

export const verifyTokenError = () => ({
    type: VERIFY_TOKEN_ERROR,
});