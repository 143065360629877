export const CREATE_DOCUMENT_PROVIDER_REQUESTING = 'CREATE_DOCUMENT_PROVIDER_REQUESTING';
export const CREATE_DOCUMENT_PROVIDER_SUCCESS = 'CREATE_DOCUMENT_PROVIDER_SUCCESS';
export const CREATE_DOCUMENT_PROVIDER_ERROR = 'CREATE_DOCUMENT_PROVIDER_ERROR';

export const DELETE_DOCUMENT_PROVIDER_REQUESTING = 'DELETE_DOCUMENT_PROVIDER_REQUESTING';
export const DELETE_DOCUMENT_PROVIDER_SUCCESS = 'DELETE_DOCUMENT_PROVIDER_SUCCESS';
export const DELETE_DOCUMENT_PROVIDER_ERROR = 'DELETE_DOCUMENT_PROVIDER_ERROR';

export const CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING = 'CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING';
export const CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS = 'CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS';
export const CREATE_DOCUMENT_LEADER_MANAGER_ERROR = 'CREATE_DOCUMENT_LEADER_MANAGER_ERROR';


