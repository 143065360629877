import {
    CHANGE_STATE_PROVIDER_ERROR,
    CHANGE_STATE_PROVIDER_REQUESTING,
    CHANGE_STATE_PROVIDER_SUCCESS,
    CREATE_PROVIDER_ERROR,
    CREATE_PROVIDER_REQUESTING,
    CREATE_PROVIDER_SUCCESS,
    GET_PROVIDER_ERROR,
    GET_PROVIDER_REQUESTING,
    GET_PROVIDER_SUCCESS,
    SEARCH_PROVIDER_ERROR,
    SEARCH_PROVIDER_REQUESTING,
    SEARCH_PROVIDER_SUCCESS,
    SET_FOCUS_PROVIDER, UPDATE_DOCUMENT, UPDATE_PROVIDER,
    UPDATE_PROVIDER_ERROR,
    UPDATE_PROVIDER_REQUESTING,
    UPDATE_PROVIDER_SUCCESS,
    GET_ALL_PROVIDER_ERROR,
    GET_ALL_PROVIDER_REQUESTING,
    GET_ALL_PROVIDER_SUCCESS
} from "./constants";


export const getProviderRequesting = (cursor, filter, token) => ({
    type: GET_PROVIDER_REQUESTING,
    cursor,
    filter,
    token
});

export const getProviderSuccess = (providers) => ({
    type: GET_PROVIDER_SUCCESS,
    providers
});

export const getProviderError = (error) => ({
    type: GET_PROVIDER_ERROR,
    error
});

export const changeStateProviderRequesting = (providerId, token) => ({
    type: CHANGE_STATE_PROVIDER_REQUESTING,
    providerId,
    token
});

export const changeStateProviderSuccess = (provider) => ({
    type: CHANGE_STATE_PROVIDER_SUCCESS,
    provider
});

export const changeStateProviderError = (error) => ({
    type: CHANGE_STATE_PROVIDER_ERROR,
    error
});

export const searchProviderRequesting = (search, token) => ({
    type: SEARCH_PROVIDER_REQUESTING,
    search,
    token
});

export const searchProviderSuccess = (provider) => ({
    type: SEARCH_PROVIDER_SUCCESS,
    provider
});

export const searchProviderError = (error) => ({
    type: SEARCH_PROVIDER_ERROR,
    error
});

export const createProviderRequesting = (provider, token) => ({
    type: CREATE_PROVIDER_REQUESTING,
    provider,
    token
});

export const createProviderSuccess = (providerCreated) => ({
    type: CREATE_PROVIDER_SUCCESS,
    providerCreated
});

export const createProviderError = (error) => ({
    type: CREATE_PROVIDER_ERROR,
    error
});

export const setFocusProvider = (id = 0, provider = {}) => ({
    type: SET_FOCUS_PROVIDER,
    id,
    provider
});

export const updateProviderRequesting = (provider, token, id) => ({
    type: UPDATE_PROVIDER_REQUESTING,
    provider,
    token,
    id
});

export const updateProviderSuccess = (providerUpdate) => ({
    type: UPDATE_PROVIDER_SUCCESS,
    providerUpdate
});

export const updateProviderError = (error) => ({
    type: UPDATE_PROVIDER_ERROR,
    error
});

export const updateDocument = (provider) => ({
    type: UPDATE_DOCUMENT,
    provider
});

export const updateProvider = (documentDeleted) => ({
    type: UPDATE_PROVIDER,
    documentDeleted
});
export const getAllProviderRequesting = (token) => ({
    type: GET_ALL_PROVIDER_REQUESTING,
    token
});
export const getAllProviderSuccess  = (providers) => ({
    type: GET_ALL_PROVIDER_SUCCESS,
    providers
});
export const getAllProviderError  = (error) => ({
    type: GET_ALL_PROVIDER_ERROR,
    error
});