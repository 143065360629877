export const STATES_GET_REQUESTING = 'STATES_GET_REQUESTING';
export const STATES_GET_SUCCESS = 'STATES_GET_SUCCESS';
export const STATES_GET_ERROR = 'STATES_GET_ERROR';


//David

export const STATES_EMERGENCY_CALL_REQUESTING = 'STATES_EMERGENCY_CALL_REQUESTING';
export const STATES_EMERGENCY_CALL_SUCCESS = 'STATES_EMERGENCY_CALL_SUCCESS';
export const STATES_EMERGENCY_CALL_ERROR = 'STATES_EMERGENCY_CALL_ERROR';

export const STATES_PROVIDER_REQUESTING = 'STATES_PROVIDER_REQUESTING';
export const STATES_PROVIDER_SUCCESS = 'STATES_PROVIDER_SUCCESS';
export const STATES_PROVIDER_ERROR = 'STATES_PROVIDER_ERROR';

export const STATES_USER_REQUESTING='STATES_USER_REQUESTING';
export const STATES_USER_SUCCESS='STATES_USER_SUCCESS';
export const STATES_USER_ERROR='STATES_USER_ERROR';

//Alejandro
//Estados producto y ordenes
export const STATES_PRODUCT_REQUESTING='STATES_PRODUCT_REQUESTING';
export const STATES_PRODUCT_SUCCESS='STATES_PRODUCT_SUCCESS';
export const STATES_PRODUCT_ERROR='STATES_PRODUCT_ERROR';

export const STATES_ORDER_REQUESTING='STATES_ORDER_REQUESTING';
export const STATES_ORDER_SUCCESS='STATES_ORDER_SUCCESS';
export const STATES_ORDER_ERROR='STATES_ORDER_ERROR';

