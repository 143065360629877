import React from 'react';

export class ModalAudit extends React.Component {

    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Historial de cambios</h1>
                    </div>
                    <div className="text-modal">
                        <h2>Información de compra</h2>

                    </div>

                    <div className="Conteiner-table">
                        <table className="table-info-product1">
                            <thead>
                            <tr>
                                <th>Nombre de producto</th>
                                <th>Referencia</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Acción</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Nombre</td>
                                <td>Foto</td>
                                <td>Descripcion</td>
                                <td>Precio real</td>
                                <td>
                                    <button className="Btn-item-table">Editar<i
                                        className="icon-pencil styles-icons"/></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal">Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Crear producto<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
