import {GET_CATEGORY_ERROR, GET_CATEGORY_REQUESTING, GET_CATEGORY_SUCCESS} from "./constants";


export const getCategoryRequesting = (token) => ({
    type: GET_CATEGORY_REQUESTING,
    token
});

export const getCategorySuccess = (categories) => ({
    type: GET_CATEGORY_SUCCESS,
    categories
});

export const getCategoryError = (error) => ({
    type: GET_CATEGORY_ERROR,
    error
});