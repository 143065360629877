import React from 'react';
import {Field, reduxForm} from "redux-form";
import {createUserRequesting} from "../../../redux/user/actions";
import connect from "react-redux/es/connect/connect";
import {getCityCountryRequesting} from "../../../commons/countries/actions";
import {toastr} from "react-redux-toastr";
import {ErrorComponent} from "../../../commons/errors/errors";

class ModalAddClient extends React.Component {

    handleAction = (values) => {
        const {client, createUserRequesting} = this.props;
        createUserRequesting(values, client.token);
    };

    handleGetCities = (id) => {
        const {getCityCountryRequesting, client: {token}} = this.props;
        getCityCountryRequesting(id, token);
    };


    componentWillUpdate(nextProps, nextState, nextContext) {
        const {user: {error}} = this.props;

        if(nextProps.user.error !== ''&& nextProps.user.error !== error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.user.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }
        if(nextProps.user.created){
            toastr.success('Cliente creado', 'Cliente creado, ya puede ver los clientes' );
            this.props.hidden();
        }
    }

    render() {
        const {
            handleSubmit,
            typeIdentification: {typeIdentifications},
            country: {countries,cities}
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>

                    <h1 className="arreglojulian">Agregar Nuevo cliente</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field name={'name'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Tipo de identificación: </label>
                            <Field component={'select'} name="type_identification_id" className="select-rol">
                                <option value="category">Seleccionar tipo de identificación</option>
                                {typeIdentifications.map((type, index) =>
                                    <option value={type.id}
                                            key={index}>{type.nombre}</option>)}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Cédula: </label>
                            <Field name={'identification'} type="text" className="input input-name"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>País: </label>
                            <Field component={'select'} name="country_id" className="select-rol"
                                   onChange={(e) => this.handleGetCities(e.target.value)}>
                                <option value="0">Seleccionar pais</option>
                                {countries.map((country, index) => <option value={country.nombre}
                                                                        key={index}>{country.nombre}</option>)}
                            </Field>
                        </div>
                {/*         <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name="city_id" className="select-rol">
                                <option value="0">Seleccionar ciudad</option>
                                {cities.map((city, index) => <option value={city.id}
                                                                        key={index}>{city.nombre}</option>)}
                            </Field>
                        </div> */}
                        <div className="item-modal">
                            <label>Dirección: </label>
                            <Field name={'address'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Celular: </label>
                            <Field name={'phone'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Correo: </label>
                            <Field name={'email'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Contraseña:</label>
                            <Field name={'password'} type="password" component={'input'}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)} >Cancelar<i
                                    className="icon-cancel styles-icons"/></button>
                                <button className="Btn-modal" type={'submit'}>Crear cliente<i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'userModal',
    enableReinitialize: true
})(ModalAddClient);

const mapStateToProps = state => ({
    client: state.client,
    user: state.user,
    typeIdentification: state.typeIdentification,
    country: state.country,
});

const connected = connect(mapStateToProps, {
    createUserRequesting,
    getCityCountryRequesting
})(formed);


export default connected
