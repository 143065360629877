import {
    GET_RATE_EMERGENCY_CALL_ERROR,
    GET_RATE_EMERGENCY_CALL_REQUESTING,
    GET_RATE_EMERGENCY_CALL_SUCCESS,
    GET_RATE_ERROR,
    GET_RATE_INSTALLATION_ERROR,
    GET_RATE_INSTALLATION_REQUESTING,
    GET_RATE_INSTALLATION_SUCCESS,
    GET_RATE_MAINTENANCE_ERROR,
    GET_RATE_MAINTENANCE_REQUESTING,
    GET_RATE_MAINTENANCE_SUCCESS,
    GET_RATE_REQUESTING,
    GET_RATE_SUCCESS, GET_RATE_SUPPLY_ERROR, GET_RATE_SUPPLY_REQUESTING, GET_RATE_SUPPLY_SUCCESS,
    UPDATE_RATE_EMERGENCY_CALL_ERROR,
    UPDATE_RATE_EMERGENCY_CALL_REQUESTING,
    UPDATE_RATE_EMERGENCY_CALL_SUCCESS,
    UPDATE_RATE_ERROR,
    UPDATE_RATE_INSTALLATION_ERROR,
    UPDATE_RATE_INSTALLATION_REQUESTING,
    UPDATE_RATE_INSTALLATION_SUCCESS,
    UPDATE_RATE_MAINTENANCE_ERROR,
    UPDATE_RATE_MAINTENANCE_REQUESTING,
    UPDATE_RATE_MAINTENANCE_SUCCESS,
    UPDATE_RATE_REQUESTING,
    UPDATE_RATE_SUCCESS, UPDATE_RATE_SUPPLY_ERROR, UPDATE_RATE_SUPPLY_REQUESTING, UPDATE_RATE_SUPPLY_SUCCESS
} from "./constants";

export const getRateRequesting = (token, values) => ({
    type: GET_RATE_REQUESTING,
    token,
    values
});

export const getRateSuccess = (rates) => ({
    type: GET_RATE_SUCCESS,
    rates
});

export const getRateError = (error) => ({
    type: GET_RATE_ERROR,
    error
});

export const updateRateRequesting = (rate, token, values) => ({
    type: UPDATE_RATE_REQUESTING,
    rate,
    token,
    values
});

export const updateRateSuccess  = (rateUpdate) => ({
    type: UPDATE_RATE_SUCCESS,
    rateUpdate
});

export const updateRateError = (error) => ({
    type: UPDATE_RATE_ERROR,
    error
});

export const getRateMaintenanceRequesting = (token, values) => ({
    type: GET_RATE_MAINTENANCE_REQUESTING,
    token,
    values
});

export const getRateMaintenanceSuccess = (rateMaintenance) => ({
    type: GET_RATE_MAINTENANCE_SUCCESS,
    rateMaintenance
});

export const getRateMaintenanceError = (error) => ({
    type: GET_RATE_MAINTENANCE_ERROR,
    error
});

export const updateRateMaintenanceRequesting = (rateMaintenance, token, values) => ({
    type: UPDATE_RATE_MAINTENANCE_REQUESTING,
    rateMaintenance,
    token,
    values
});

export const updateRateMaintenanceSuccess  = (rateMaintenanceUpdate) => ({
    type: UPDATE_RATE_MAINTENANCE_SUCCESS,
    rateMaintenanceUpdate
});

export const updateRateMaintenanceError = (error) => ({
    type: UPDATE_RATE_MAINTENANCE_ERROR,
    error
});

export const getRateInstallationRequesting = (token, values) => ({
    type: GET_RATE_INSTALLATION_REQUESTING,
    token,
    values
});

export const getRateInstallationSuccess = (rateInstallation) => ({
    type: GET_RATE_INSTALLATION_SUCCESS,
    rateInstallation
});

export const getRateInstallationError = (error) => ({
    type: GET_RATE_INSTALLATION_ERROR,
    error
});

export const updateRateInstallationRequesting = (rateInstallation, token, values) => ({
    type: UPDATE_RATE_INSTALLATION_REQUESTING,
    rateInstallation,
    token,
    values
});

export const updateRateInstallationSuccess  = (rateInstallationUpdate) => ({
    type: UPDATE_RATE_INSTALLATION_SUCCESS,
    rateInstallationUpdate
});

export const updateRateInstallationError = (error) => ({
    type: UPDATE_RATE_INSTALLATION_ERROR,
    error
});

export const getRateEmergencyCallRequesting = (token, values) => ({
    type: GET_RATE_EMERGENCY_CALL_REQUESTING,
    token,
    values
});

export const getRateEmergencyCallSuccess = (rateEmergencyCalls) => ({
    type: GET_RATE_EMERGENCY_CALL_SUCCESS,
    rateEmergencyCalls
});

export const getRateEmergencyCallError = (error) => ({
    type: GET_RATE_EMERGENCY_CALL_ERROR,
    error
});

export const updateRateEmergencyCallRequesting = (rateEmergencyCall, token, values) => ({
    type: UPDATE_RATE_EMERGENCY_CALL_REQUESTING,
    rateEmergencyCall,
    token,
    values
});

export const updateRateEmergencyCallSuccess  = (rateEmergencyCallUpdate) => ({
    type: UPDATE_RATE_EMERGENCY_CALL_SUCCESS,
    rateEmergencyCallUpdate
});

export const updateRateEmergencyCallError = (error) => ({
    type: UPDATE_RATE_EMERGENCY_CALL_ERROR,
    error
});

export const getRateSupplyRequesting = (token, values) => ({
    type: GET_RATE_SUPPLY_REQUESTING,
    token,
    values
});

export const getRateSupplySuccess = (rateSupplies) => ({
    type: GET_RATE_SUPPLY_SUCCESS,
    rateSupplies
});

export const getRateSupplyError = (error) => ({
    type: GET_RATE_SUPPLY_ERROR,
    error
});

export const updateRateSupplyRequesting = (rateSupply, token, values) => ({
    type: UPDATE_RATE_SUPPLY_REQUESTING,
    rateSupply,
    token,
    values
});

export const updateRateSupplySuccess  = (rateSupplyUpdate) => ({
    type: UPDATE_RATE_SUPPLY_SUCCESS,
    rateSupplyUpdate
});

export const updateRateSupplyError = (error) => ({
    type: UPDATE_RATE_SUPPLY_ERROR,
    error
});

