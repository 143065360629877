export const EMERGENCY_CALLS_GET = 'EMERGENCY_CALLS_GET';
export const EMERGENCY_CALLS_GET_SUCCESS = 'EMERGENCY_CALLS_GET_SUCCESS';
export const EMERGENCY_CALLS_GET_ERROR = 'EMERGENCY_CALLS_GET_ERROR';

export const EMERGENCY_CALL_FOCUS = 'EMERGENCY_CALL_FOCUS';
export const EMERGENCY_CALL_FOCUS_CLEAN = 'EMERGENCY_CALL_FOCUS_CLEAN';

export const GET_EMERGENCY_CALL_REQUESTING = 'GET_EMERGENCY_CALL_REQUESTING';
export const GET_EMERGENCY_CALL_SUCCESS = 'GET_EMERGENCY_CALL_SUCCESS';
export const GET_EMERGENCY_CALL_ERROR = 'GET_EMERGENCY_CALL_ERROR';

export const SEARCH_EMERGENCY_CALL_REQUESTING = 'SEARCH_EMERGENCY_CALL_REQUESTING';
export const SEARCH_EMERGENCY_CALL_SUCCESS = 'SEARCH_EMERGENCY_CALL_SUCCESS';
export const SEARCH_EMERGENCY_CALL_ERROR = 'SEARCH_EMERGENCY_CALL_ERROR';
