import React from 'react';
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {updateProviderRequesting} from "../../../redux/provider/actions";
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import {FilePond} from "react-filepond";
import {getCityCountryRequesting} from "../../../commons/countries/actions";

const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action" style="color: black">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={1}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};


class ModalEditProvider extends React.Component {

    handleGetCities = (id) => {
        const {getCityCountryRequesting, client: {token}} = this.props;
        getCityCountryRequesting(id, token);
    };

    componentWillUpdate(nextProps) {
        const {provider} = this.props;

        if (nextProps.provider.error !== '' && nextProps.provider.error !== provider.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.provider.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if (nextProps.provider.success && !provider.success) {
            toastr.success('Proveedor actualizado', 'Se ha actualizado la información correctamente');
            this.props.hidden();
        }
    };

    handleAction = (values) => {
        const {client, updateProviderRequesting, provider: {focusProvider}} = this.props;
        updateProviderRequesting(values, client.token, focusProvider);
    };


    render() {
        const {country: {countries, cities}, handleSubmit, initialValues} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="text-modal">Editar proveedor</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Logo: </label>
                            <Field name={'foto'} component={FileInput}/>
                        </div>
                        <div className="item-modal">
                            <label>Correo Electrónico: </label>
                            <Field name={'correo'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field name={'nombre'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Sede: </label>
                            <Field name={'sede'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Nit del Proveedor: </label>
                            <Field name={'identificacion'} type="text" className="input input-name"
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>País: </label>
                            <Field component={'select'} name="pais" className="select-rol"
                                   onChange={(e) => this.handleGetCities(e.target.value)}>
                                <option value={initialValues.pais_id}>{initialValues.pais}</option>
                                {countries.map((country, index) => {
                                        if (country.nombre !== initialValues.pais)
                                            return <option value={parseInt(country.id)}
                                                           key={index}>{country.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name="ciudad" className="select-rol">
                                <option value={initialValues.ciudad_id}>{initialValues.ciudad}</option>
                                {cities.map((city, index) => {
                                        if (city.nombre !== initialValues.ciudad)
                                            return <option value={parseInt(city.id)} key={index}>{city.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Dirección: </label>
                            <Field name={'direccion'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Teléfono: </label>
                            <Field name={'telefono'} type="text" className="input input-name" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Celular: </label>
                            <Field name={'celular'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>% de Comisión: </label>
                            <Field name={'comision'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal">
                                    Editar proveedor
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'updateProviderModal'
})(ModalEditProvider);

const mapStateToProps = state => ({
    client: state.client,
    provider: state.provider,
    city: state.city,
    country: state.country,
    initialValues: state.provider.providerDataFocus
});

const connected = connect(mapStateToProps, {
    updateProviderRequesting,
    getCityCountryRequesting
})(formed);

export default connected