import {ALL_CITIES_GET_ERROR, ALL_CITIES_GET_REQUESTING, ALL_CITIES_GET_SUCCESS,} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    allCities: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_CITIES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case ALL_CITIES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                allCities: action.allCities
            };
        case ALL_CITIES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;