import React from 'react';
import {FilePond} from "react-filepond";
import {updateLeaderManagerRequesting} from "../../../redux/leader-manager/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {getCityCountryRequesting} from "../../../commons/countries/actions";

const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={1}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

class ModalEditLeaderManager extends React.Component {


    handleGetCities = (id) => {
        const {getCityCountryRequesting, client: {token}} = this.props;
        getCityCountryRequesting(id, token);
    };

    componentWillUpdate(nextProps){
        const {leaderManager} = this.props;

        if(nextProps.leaderManager.error !== '' && nextProps.leaderManager.error !== leaderManager.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.leaderManager.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if(nextProps.leaderManager.success && !leaderManager.success){
            toastr.success('Gerente líder actualizado', 'Se ha actualizado la información correctamente' );
            this.props.hidden();
        }
    };

    handleAction = (values) => {
        const {client, updateLeaderManagerRequesting, leaderManager : {leaderManagerDataFocus}} = this.props;
        updateLeaderManagerRequesting(values, client.token, leaderManagerDataFocus.id, leaderManagerDataFocus.id_conductora);
    };


    render() {

        const {country: {countries, cities},  handleSubmit, initialValues} = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Editar gerente líder</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                    <div className="item-modal">
                        <label>Subir foto: </label>
                        <Field name={'foto'} component={FileInput}/>
                    </div>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <Field name={'usuario.data[0].nombre'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Cédula: </label>
                        <Field name={'usuario.data[0].identificacion'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Correo electronico: </label>
                        <Field name={'usuario.data[0].correo_electronico'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Teléfono fijo: </label>
                        <Field name={'usuario.data[0].telefono'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Dirección: </label>
                        <Field name={'usuario.data[0].direccion'} type="text" className="input input-city" component={'input'}/>
                    </div>
                        <div className="item-modal">
                            <label>País: </label>
                            <Field component={'select'} name="pais" className="select-rol"
                                   onChange={(e) => this.handleGetCities(e.target.value)}>
                                <option value={initialValues.usuario.data[0].pais.data.id}>{initialValues.usuario.data[0].pais.data.nombre}</option>
                                {countries.map((country, index) => {
                                        if (country.nombre !== initialValues.usuario.data[0].pais.data.nombre)
                                            return <option value={country.id} key={index}>{country.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name="ciudad" className="select-rol">
                                <option value={initialValues.usuario.data[0].ciudad.id}>{initialValues.usuario.data[0].ciudad.nombre}</option>
                                {cities.map((city, index) => {
                                        if (city.nombre !== initialValues.usuario.data[0].ciudad.nombre)
                                            return <option value={city.id} key={index}>{city.nombre}</option>
                                    }
                                )}
                            </Field>
                        </div>
                    <div className="item-modal">
                        <label>Nombre de Contacto: </label>
                        <Field name={'nombre_contacto'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Teléfono de Contacto: </label>
                        <Field name={'telefono_contacto'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Actualizar gerente líder<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'updateDriverModal'
})(ModalEditLeaderManager);

const mapStateToProps = state => ({
    client: state.client,
    leaderManager: state.leaderManager,
    city: state.city,
    country: state.country,
    initialValues: state.leaderManager.leaderManagerDataFocus
});

const connected = connect(mapStateToProps, {
    updateLeaderManagerRequesting,
    getCityCountryRequesting
})(formed);

export default connected
