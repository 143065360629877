import React from 'react';
import {getCountryRequesting} from "../../commons/countries/actions";
import {getCategoryRequesting} from "../../redux/category/actions";
import connect from "react-redux/es/connect/connect";
import {Field, reduxForm} from "redux-form";
import {getRateSupplyRequesting} from "../../redux/rate/actions";

class FilterConfigEmergencyCallComponent extends React.Component {


    componentWillMount() {
        const {getCountryRequesting, getCategoryRequesting, client: {token},} = this.props;
        getCountryRequesting(token);
        getCategoryRequesting(token);


    }

    handleLoad = (values) => {
      const {getRateSupplyRequesting, client} = this.props;
        getRateSupplyRequesting(client.token, values);
    };

    render(){

        const {country, category, handleSubmit} = this.props;

        return(
            <form onSubmit={handleSubmit(this.handleLoad)}>
            <div className="Btn-general-filter">
                <div className="container-add-direction">
                    <div className="div-btn-filter-config">
                        <Field component={'select'} name="country_id" className="select-rol">
                            <option value="country">País</option>
                            {country && country.countries.map((country, index) =>
                                <option value={country.id} key={index}>{country.nombre}</option>
                            )}
                        </Field>

                        <Field component={'select'} name="category_id" className="select-rol">
                            <option value="category">Categoría</option>
                            {category && category.categories.map((category, index) =>
                                <option value={category.id} key={index}>{category.nombre}</option>
                            )}
                        </Field>

                        <button style={{width: '300%',justifyContent: 'center'}}
                                className="Btn-item-table"
                                type={"submit"}
                        >Ver en tabla<i
                            className="icon-pencil styles-icons"/></button>

                    </div>
                </div>
            </div>
            </form>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    country: state.country,
    category: state.category,
});

const connected = connect(mapStateToProps, {
    getCountryRequesting,
    getCategoryRequesting,
    getRateSupplyRequesting
})(FilterConfigEmergencyCallComponent);

const formed = reduxForm({
    form: 'filterConfig'
})(connected);

export default formed