import React from 'react';
import {reduxForm, Field} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {updateRateEmergencyCallRequesting} from "../../../../redux/rate/actions";
import {ErrorComponent} from "../../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class RowPercentageVisitLeaderManager extends React.Component {


    constructor(props) {
        super(props);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {rateEmergencyCalls} = this.props;

        if (nextProps.rateEmergencyCalls.error !== '' && nextProps.rateEmergencyCalls.error !== rateEmergencyCalls.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.rateEmergencyCalls.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if (nextProps.rateEmergencyCalls.successChange !== rateEmergencyCalls.successChange) {

            toastr.success('Porcentaje cambiado', 'Se han cambiado los porcentajes de llamadas de emergencia.');
        }
    }

    handleAction = (values) => {
        const {client, handleEditable, updateRateEmergencyCallRequesting, rateEmergencyCalls, formConfig} = this.props;
        updateRateEmergencyCallRequesting(rateEmergencyCalls, client.token, {...values, ...formConfig.values});
        handleEditable('percentageVisitLeaderManager', false);
    };

    render() {
        const {handleEditable, handleSubmit, editable} = this.props;

        return (
            <tr>
                <td className="table-config-width">% Visita gerente lider</td>
                {editable && (
                    <div>
                        <form onSubmit={handleSubmit(this.handleAction)}>
                            <td>
                                <div className="container-config">
                                    <Field value={''}
                                           disabled={!editable}
                                           type="text"
                                           component={'input'}
                                           placeholder="Digite el valor"
                                           name={'porcentaje_visita_gerente_lider'}
                                    />
                                </div>
                            </td>
                            <td>
                                <button
                                    type={'submit'}
                                    className="Btn-item2-table"> Guardar<i
                                    className="icon-pencil styles-icons"/>
                                </button>
                            </td>

                        </form>
                    </div>
                )}
                {!editable && (
                    <div>
                        <td>
                            <div className="container-config">
                                <Field value={''}
                                       disabled={!editable}
                                       type="text"
                                       component={'input'}
                                       placeholder="Digite el valor"
                                       name={'porcentaje_visita_gerente_lider'}
                                />
                            </div>
                        </td>
                        <td>
                            <button onClick={() => handleEditable('percentageVisitLeaderManager', true)}
                                    className="Btn-item-table">Editar<i
                                className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                    </div>
                )}
            </tr>
        );
    }
}

const formed = reduxForm({
    form: 'rowPercentageVisitLeaderManager',
    enableReinitialize: true
})(RowPercentageVisitLeaderManager);

const mapStateToProps = state => ({
    client: state.client,
    rateEmergencyCalls: state.rate,
    initialValues: state.rate.rateEmergencyCalls,
    formConfig: state.form.filterConfig
});

const connected = connect(mapStateToProps, {
    updateRateEmergencyCallRequesting
})(formed);

export default connected;