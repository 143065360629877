import React from "react";
import Lightbox from "react-images";
import { ModalDocumentsSteps } from "./documents-steps";

export class ModalStepsService extends React.Component {
  state = {
    lightboxIsOpen: false,
    photos: [],
    currentImage: 0,
    showModalDocuments: false,
    documents: [],
  };

  handleShowModal = (modal, step) => {
    this.setState({
      showModalDocuments: modal === "documents",
      documents: step.documentos.data,
    });
  };

  handleHiddenModal = () => {
    this.setState({
      showModalDocuments: false,
    });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleChangePhotos = (step) => {
    let photos = [];
    if (step.fotos.data.length > 0) {
      step.fotos.data.map((photo) => {
        photos.push({ src: photo.src });
      });
    }

    this.setState({
      lightboxIsOpen: true,
      photos: photos,
      photosCurrent: step.fotos,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  render() {
    const { steps, service } = this.props;
    let subcategory =
      service.sub_categoria.nombre !== undefined
        ? service.sub_categoria.nombre
        : "";
    return (
      <div className={"body-blur"} onClick={this.props.hidden.bind(this)}>
        {this.state.showModalDocuments && (
          <ModalDocumentsSteps
            stop={this.stopPropagation.bind(this)}
            hidden={this.handleHiddenModal.bind(this)}
            documents={this.state.documents}
          />
        )}
        <div className="register-modal" onClick={this.props.stop.bind(this)}>
          <div className="button-container-close">
            <button
              className="Btn-modal-cancel"
              onClick={this.props.hidden.bind(this)}
            >
              <i className="icon-cancel" />
            </button>
          </div>
          <div className="text-modal">
            <h1>Estados del servicio</h1>
          </div>
          <h5>Nombre del contratista: {service.gerente_lider.nombre}</h5>
          <h5>
            Telefono del contratista: {service.gerente_lider.telefono_contacto}
          </h5>
          <div className="item-1-modal-status">
            {steps.length > 0 &&
              steps.map((step, index) => (
                <div key={index} className="sub-item-1-modal-status">
                  <Lightbox
                    images={this.state.photos}
                    isOpen={this.state.lightboxIsOpen}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    onClose={this.closeLightbox}
                    imageCountSeparator={" / "}
                    currentImage={this.state.currentImage}
                    backdropClosesModal={true}
                  />
                  {/*{this.state.showModalDocuments && (*/}
                  {/*<ModalStatusService stop={this.stopPropagation.bind(this)}*/}
                  {/*hidden={this.handleHiddenModal.bind(this)}*/}
                  {/*documents={this.state.documents}*/}
                  {/*/>*/}
                  {/*)}*/}
                  <div className="sub-item-2-modal-status">
                    <div className="sub-item-3-modal-status">
                      <div>
                        <h5>
                          <i className="icon-ok" />
                          Paso {index + 1}: {step.paso}
                        </h5>
                        {step && step.fecha_creacion.length ? (
                          <p>{step.fecha_creacion.date.split(".")[0]}</p>
                        ) : (
                          <p>No registra fecha</p>
                        )}
                      </div>
                    </div>
                    <div>
                      <p>
                        {step.observacion === null
                          ? "Sin observación"
                          : step.observacion}
                      </p>
                    </div>
                  </div>
                  <div className="sub-item-4-modal-status">
                    {step.documentos.data.length > 0 ? (
                      <button
                        className="Btn-item-table"
                        onClick={() => this.handleShowModal("documents", step)}
                      >
                        {/*<i className="icon-phone"/>*/}(
                        {step.documentos.data.length}) Documentos
                      </button>
                    ) : (
                      <button className="Btn-item-table">
                        {/*<i className="icon-phone"/>*/}
                        Sin documentos
                      </button>
                    )}
                    {step.fotos.data.length > 0 ? (
                      <button
                        className="Btn-item-table"
                        onClick={() => this.handleChangePhotos(step)}
                      >
                        {/*<i className="icon-ok"/>*/}({step.fotos.data.length})
                        Fotos e imagenes
                      </button>
                    ) : (
                      <button className="Btn-item-table">
                        {/*<i className="icon-ok"/>*/}
                        Sin fotos o imagenes
                      </button>
                    )}
                  </div>
                  {/*{service.sub_categoria.nombre === 'Diseño' && index === 1 && service.estado_cotizacion === 'Esperando' && (*/}
                  {/*<button className="Btn-item" onClick={() => this.props.acceptQuotation(service.id_cotizacion, subcategory)}>*/}
                  {/*Aceptar pre-diseño*/}
                  {/*</button>*/}
                  {/*)}*/}
                </div>
              ))}
            {/*<button className="Btn-item">Servicio para hoy</button>*/}
            <button
              onClick={this.props.hidden.bind(this)}
              className="Btn-modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    );
  }
}
