import {
    STATES_USER_REQUESTING,
    STATES_USER_SUCCESS,
    STATES_USER_ERROR,
    STATES_EMERGENCY_CALL_ERROR,
    STATES_EMERGENCY_CALL_REQUESTING, STATES_EMERGENCY_CALL_SUCCESS,
    STATES_GET_ERROR,
    STATES_GET_REQUESTING,
    STATES_GET_SUCCESS,
    STATES_PROVIDER_ERROR,
    STATES_PROVIDER_REQUESTING,
    STATES_PROVIDER_SUCCESS,
    STATES_PRODUCT_ERROR,
    STATES_PRODUCT_REQUESTING,
    STATES_PRODUCT_SUCCESS,
    STATES_ORDER_ERROR,
    STATES_ORDER_REQUESTING,
    STATES_ORDER_SUCCESS,
} from "./constans";

export const statesGetRequesting = (token) => ({
    type: STATES_GET_REQUESTING,
    token,
});

export const statesGetSuccess = (states) => ({
    type: STATES_GET_SUCCESS,
    states,
});

export const statesGetError = (error) => ({
    type: STATES_GET_ERROR,
    error,
});

//David

export const statesEmergencyCallRequesting = (token) => ({
    type: STATES_EMERGENCY_CALL_REQUESTING,
    token
});

export const statesEmergencyCallSuccess = (states) => ({
    type: STATES_EMERGENCY_CALL_SUCCESS,
    states
});

export const statesEmergencyCallError = (error) => ({
   type: STATES_EMERGENCY_CALL_ERROR,
   error
});

export const statesProviderRequesting = (token) => ({
   type: STATES_PROVIDER_REQUESTING,
   token
});

export const statesProviderSuccess = (states) => ({
   type: STATES_PROVIDER_SUCCESS,
   states
});

export const statesProviderError = (error) => ({
   type: STATES_PROVIDER_ERROR,
   error
});

export const statesUserRequesting = (token) => ({
    type: STATES_USER_REQUESTING,
    token
});

export const statesUserSuccess = (states) => ({
    type: STATES_USER_SUCCESS,
    states
});

export const statesUserError = (error) => ({
    type: STATES_USER_ERROR,
    error
});

//Alejandro
//Accoiones productos y ordenes
export const statesOrderRequesting = (token) => ({
    type: STATES_ORDER_REQUESTING,
    token
});

export const statesOrderSuccess = (states) => ({
    type: STATES_ORDER_SUCCESS,
    states
});

export const statesOrderError = (error) => ({
    type: STATES_ORDER_ERROR,
    error
});
export const statesProductRequesting = (token) => ({
    type: STATES_PRODUCT_REQUESTING,
    token
});

export const statesProductSuccess = (states) => ({
    type: STATES_PRODUCT_SUCCESS,
    states
});

export const statesProductError = (error) => ({
    type: STATES_PRODUCT_ERROR,
    error
});