import {
    EMERGENCY_CALLS_GET,
    EMERGENCY_CALLS_GET_SUCCESS,
    EMERGENCY_CALLS_GET_ERROR,
    EMERGENCY_CALL_FOCUS,
    EMERGENCY_CALL_FOCUS_CLEAN,
    GET_EMERGENCY_CALL_REQUESTING,
    GET_EMERGENCY_CALL_SUCCESS,
    GET_EMERGENCY_CALL_ERROR,
    SEARCH_EMERGENCY_CALL_REQUESTING,
    SEARCH_EMERGENCY_CALL_SUCCESS,
    SEARCH_EMERGENCY_CALL_ERROR,
} from "./constants";

export const emergencyCallsGet = (filter, paginate) => ({
    type: EMERGENCY_CALLS_GET,
    filter,
    paginate,
});

export const emergencyCallsGetSuccess = (emergencyCalls, paginate) => ({
    type: EMERGENCY_CALLS_GET_SUCCESS,
    emergencyCalls,
    paginate,
});

export const emergencyCallsGetError = (error) => ({
    type: EMERGENCY_CALLS_GET_ERROR,
    error,
});

export const emergencyCallFocus = (emergencyCall) => ({
    type: EMERGENCY_CALL_FOCUS,
    emergencyCall,
});

export const emergencyCallFocusClean = () => ({
    type: EMERGENCY_CALL_FOCUS_CLEAN
});

export const getEmergencyCallRequesting = (cursor, filter, token) => ({
    type: GET_EMERGENCY_CALL_REQUESTING,
    cursor,
    filter,
    token
});

export const getEmergencyCallSuccess = (emergencyCalls) => ({
   type: GET_EMERGENCY_CALL_SUCCESS,
   emergencyCalls
});

export const getEmergencyCallError = (error) => ({
   type: GET_EMERGENCY_CALL_ERROR,
   error
});

export const searchEmergencyCallRequesting = (search, token) => ({
   type: SEARCH_EMERGENCY_CALL_REQUESTING,
   search,
   token
});

export const searchEmergencyCallSuccess = (emergencyCall) => ({
   type: SEARCH_EMERGENCY_CALL_SUCCESS,
   emergencyCall
});

export const searchEmergencyCallError = (error) => ({
   type: SEARCH_EMERGENCY_CALL_ERROR,
   error
});