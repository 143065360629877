import {
    SERVICES_DESIGN_GET_ERROR,
    SERVICES_DESIGN_GET_REQUESTING,
    SERVICES_DESIGN_GET_SUCCESS,
    SERVICES_INSTALLATION_GET_ERROR,
    SERVICES_INSTALLATION_GET_REQUESTING,
    SERVICES_INSTALLATION_GET_SUCCESS,
    SERVICES_MAINTENANCE_GET_ERROR,
    SERVICES_MAINTENANCE_GET_REQUESTING,
    SERVICES_MAINTENANCE_GET_SUCCESS,
    SERVICES_SEARCH_DESIGN_ERROR,
    SERVICES_SEARCH_DESIGN_REQUESTING,
    SERVICES_SEARCH_DESIGN_SUCCESS,
    SERVICES_SEARCH_INSTALLATION_REQUESTING,
    SERVICES_SEARCH_INSTALLATION_SUCCESS,
    SERVICES_SEARCH_INSTALLATION_ERROR,
    SERVICES_SEARCH_MAINTENANCE_REQUESTING,
    SERVICES_SEARCH_MAINTENANCE_SUCCESS,
    SERVICES_SEARCH_MAINTENANCE_ERROR,
    STATES_SERVICE_GET_REQUESTING,
    STATES_SERVICE_GET_SUCCESS,
    STATES_SERVICE_GET_ERROR,
    SERVICE_FOCUS,
    SERVICE_FOCUS_CLEAN,
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR,
    QUOTATION_FOCUS, RESET_STATES,
} from "./constants";

export const servicesDesignGetRequesting = (paginateDesign, filterDesign, token) => ({
    type: SERVICES_DESIGN_GET_REQUESTING,
    paginateDesign, filterDesign, token,
});

export const servicesDesignGetSuccess = (servicesDesign) => ({
    type: SERVICES_DESIGN_GET_SUCCESS,
    servicesDesign,
});

export const servicesDesignGetError = (errorDesign) => ({
    type: SERVICES_DESIGN_GET_ERROR,
    errorDesign,
});

export const servicesInstallationGetRequesting = (paginateInstallation, filterInstallation, token) => ({
    type: SERVICES_INSTALLATION_GET_REQUESTING,
    paginateInstallation, filterInstallation, token
});

export const servicesInstallationGetSuccess = (servicesInstallation) => ({
    type: SERVICES_INSTALLATION_GET_SUCCESS,
    servicesInstallation,
});

export const servicesInstallationGetError = (errorInstallation) => ({
    type: SERVICES_INSTALLATION_GET_ERROR,
    errorInstallation,
});

export const servicesMaintenanceGetRequesting = (paginateMaintenance, filterMaintenance, token) => ({
    type: SERVICES_MAINTENANCE_GET_REQUESTING,
    paginateMaintenance, filterMaintenance, token,
});

export const servicesMaintenanceGetSuccess = (servicesMaintenance) => ({
    type: SERVICES_MAINTENANCE_GET_SUCCESS,
    servicesMaintenance,
});

export const servicesMaintenanceGetError = (errorMaintenance) => ({
    type: SERVICES_MAINTENANCE_GET_ERROR,
    errorMaintenance,
});

export const servicesSearchDesignRequesting = (search) => ({
    type: SERVICES_SEARCH_DESIGN_REQUESTING,
    search,
});

export const servicesSearchDesignSuccess = (servicesSearchDesign) => ({
    type: SERVICES_SEARCH_DESIGN_SUCCESS,
    servicesSearchDesign,
});

export const servicesSearchDesignError = (errorSearch) => ({
    type: SERVICES_SEARCH_DESIGN_ERROR,
    errorSearch,
});

export const servicesSearchInstallationRequesting = (search) => ({
    type: SERVICES_SEARCH_INSTALLATION_REQUESTING,
   search,
});

export const servicesSearchInstallationSuccess = (servicesInstallationSearch) => ({
    type: SERVICES_SEARCH_INSTALLATION_SUCCESS,
    servicesInstallationSearch
});

export const servicesSearchInstallationError = (errorSearch) => ({
    type: SERVICES_SEARCH_INSTALLATION_ERROR,
    errorSearch,
});

export const servicesSearchMaintenanceGetRequesting = (search) => ({
    type: SERVICES_SEARCH_MAINTENANCE_REQUESTING,
    search,
});

export const servicesSearchMaintenanceGetSuccess = (servicesMaintenanceSearch) => ({
    type: SERVICES_SEARCH_MAINTENANCE_SUCCESS,
    servicesMaintenanceSearch,
});

export const servicesSearchMaintenanceGetError = (errorSearch) => ({
    type: SERVICES_SEARCH_MAINTENANCE_ERROR,
    errorSearch,
});

export const statesServiceGetRequesting = (token) => ({
    type: STATES_SERVICE_GET_REQUESTING,
    token,
});

export const statesServiceGetSuccess = (states) => ({
    type: STATES_SERVICE_GET_SUCCESS,
    states,
});

export const statesServiceGetError = (error) => ({
    type: STATES_SERVICE_GET_ERROR,
    error,
});

export const serviceFocus = (service) => ({
    type: SERVICE_FOCUS,
    service,
});

export const serviceFocusClean = () => ({
    type: SERVICE_FOCUS_CLEAN,
});

export const confirmedWireTransferDesignPutRequesting = (token, serviceId) => ({
    type: CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING,
    token, serviceId,
});

export const confirmedWireTransferDesignPutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferDesignPutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR,
    error,
});

export const confirmedWireTransferInstallationPutRequesting = (token, serviceId) => ({
    type: CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING,
    token, serviceId,
});

export const confirmedWireTransferInstallationPutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferInstallationPutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR,
    error,
});

export const confirmedWireTransferMaintenancePutRequesting = (token, serviceId) => ({
    type: CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING,
    token, serviceId,
});

export const confirmedWireTransferMaintenancePutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferMaintenancePutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR,
    error,
});

export const quotationFocus = (quotation) => ({
    type: QUOTATION_FOCUS,
    quotation,
});

export const confirmedWireTransferQuotationDesignPutRequesting = (token, quotationId) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING,
    token, quotationId,
});

export const confirmedWireTransferQuotationDesignPutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferQuotationDesignPutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR,
    error,
});

export const confirmedWireTransferQuotationInstallationPutRequesting = (token, quotationId) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING,
    token, quotationId,
});

export const confirmedWireTransferQuotationInstallationPutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferQuotationInstallationPutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR,
    error,
});

export const confirmedWireTransferQuotationMaintenancePutRequesting = (token, quotationId) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING,
    token, quotationId,
});

export const confirmedWireTransferQuotationMaintenancePutSuccess = (service) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS,
    service,
});

export const confirmedWireTransferQuotationMaintenancePutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR,
    error,
});

export const resetStates = () => ({
    type: RESET_STATES,
});