import React from 'react';

import RowPercentageRoca from "../rows/rows-supply/rowPercentageRoca";
import connect from "react-redux/es/connect/connect";
import {reduxForm} from "redux-form";

class TableSupplyValue
    extends React.Component {

    state = {
        editable: false,
        value: '',

    };

    handleChange = (key, value) => {
        this.setState({key: value})
        // if (Number.isInteger(value))
        //
    };

    handleEditable = (value, editable) => {
        this.setState({
            editable: value === 'percentageRoca' && editable,
        });
    };
    render() {
        return (
            <div className="Conteiner-table">
                <table className="table-values" style={{width: '45.7%'}}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>
                            <th style={{width: '400px'}}>Item</th>
                            <th>Accíon</th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <div>
                    <RowPercentageRoca editable={this.state.editable} handleEditable={this.handleEditable.bind(this)}/>
                    </div>
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    rate: state.rate,
});

const connected = connect(mapStateToProps, {

})(TableSupplyValue);

const formed = reduxForm({
    form: 'valueConfigSupply'
})(connected);

export default formed