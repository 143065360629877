import {
    GET_RATE_EMERGENCY_CALL_ERROR,
    GET_RATE_EMERGENCY_CALL_REQUESTING,
    GET_RATE_EMERGENCY_CALL_SUCCESS,
    GET_RATE_ERROR,
    GET_RATE_INSTALLATION_ERROR,
    GET_RATE_INSTALLATION_REQUESTING,
    GET_RATE_INSTALLATION_SUCCESS,
    GET_RATE_MAINTENANCE_ERROR,
    GET_RATE_MAINTENANCE_REQUESTING,
    GET_RATE_MAINTENANCE_SUCCESS,
    GET_RATE_REQUESTING,
    GET_RATE_SUCCESS, GET_RATE_SUPPLY_ERROR, GET_RATE_SUPPLY_REQUESTING, GET_RATE_SUPPLY_SUCCESS,
    SET_FOCUS_RATE,
    UPDATE_RATE_EMERGENCY_CALL_ERROR,
    UPDATE_RATE_EMERGENCY_CALL_REQUESTING,
    UPDATE_RATE_EMERGENCY_CALL_SUCCESS,
    UPDATE_RATE_ERROR,
    UPDATE_RATE_INSTALLATION_ERROR,
    UPDATE_RATE_INSTALLATION_REQUESTING,
    UPDATE_RATE_INSTALLATION_SUCCESS,
    UPDATE_RATE_MAINTENANCE_ERROR,
    UPDATE_RATE_MAINTENANCE_REQUESTING,
    UPDATE_RATE_MAINTENANCE_SUCCESS,
    UPDATE_RATE_REQUESTING,
    UPDATE_RATE_SUCCESS, UPDATE_RATE_SUPPLY_ERROR, UPDATE_RATE_SUPPLY_REQUESTING, UPDATE_RATE_SUPPLY_SUCCESS
} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    successChange: false,
    error: '',
    rates: {},
    rateMaintenance: {},
    rateInstallation: {},
    rateEmergencyCalls: {},
    rateSupplies: {},
    country_id: {},
    category_id: {},
    project_id: {},
    subcategory_id: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RATE_REQUESTING:
            return{
              ...state,
              requesting: true,
              country_id: action.values.country_id,
              category_id: action.values.category_id,
              project_id: action.values.project_id,

            };
        case GET_RATE_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              rates: action.rates
            };
        case GET_RATE_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: ''
            };
        case UPDATE_RATE_REQUESTING:
            return {
              ...state,
              requesting: true,
              successChange: false,
              error: ''
            };
        case UPDATE_RATE_SUCCESS:
            return {
              ...state,
              requesting: false,
              successChange: true,
              error: '',
              rates: action.rateUpdate
            };
        case UPDATE_RATE_ERROR:
            return {
              ...state,
              requesting: false,
              successChange: false,
              error: action.error
            };
        case GET_RATE_MAINTENANCE_REQUESTING:
            return{
                ...state,
                requesting: true,
                country_id: action.values.country_id,
                category_id: action.values.category_id,
                project_id: action.values.project_id,
            };
        case GET_RATE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                rateMaintenance: action.rateMaintenance
            };
        case GET_RATE_MAINTENANCE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: ''
            };
        case UPDATE_RATE_MAINTENANCE_REQUESTING:
            return {
                ...state,
                requesting: true,
                successChange: false,
                error: ''
            };
        case UPDATE_RATE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                requesting: false,
                successChange: true,
                error: '',
                rateMaintenance: action.rateMaintenanceUpdate
            };
        case UPDATE_RATE_MAINTENANCE_ERROR:
            return {
                ...state,
                requesting: false,
                successChange: false,
                error: action.error
            };
        case GET_RATE_INSTALLATION_REQUESTING:
            return{
                ...state,
                requesting: true,
                country_id: action.values.country_id,
                category_id: action.values.category_id,
                project_id: action.values.project_id,
            };
        case GET_RATE_INSTALLATION_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                rateInstallation: action.rateInstallation
            };
        case GET_RATE_INSTALLATION_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: ''
            };
        case UPDATE_RATE_INSTALLATION_REQUESTING:
            return {
                ...state,
                requesting: true,
                successChange: false,
                error: ''
            };
        case UPDATE_RATE_INSTALLATION_SUCCESS:
            return {
                ...state,
                requesting: false,
                successChange: true,
                error: '',
                rateInstallation: action.rateInstallationUpdate
            };
        case UPDATE_RATE_INSTALLATION_ERROR:
            return {
                ...state,
                requesting: false,
                successChange: false,
                error: action.error
            };
        case GET_RATE_EMERGENCY_CALL_REQUESTING:
            return{
                ...state,
                requesting: true,
                country_id: action.values.country_id,
                category_id: action.values.category_id,
                project_id: action.values.project_id,
            };
        case GET_RATE_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                rateEmergencyCalls: action.rateEmergencyCalls
            };
        case GET_RATE_EMERGENCY_CALL_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: ''
            };
        case UPDATE_RATE_EMERGENCY_CALL_REQUESTING:
            return {
                ...state,
                requesting: true,
                successChange: false,
                error: ''
            };
        case UPDATE_RATE_EMERGENCY_CALL_SUCCESS:
            return {
                ...state,
                requesting: false,
                successChange: true,
                error: '',
                rateEmergencyCalls: action.rateEmergencyCallUpdate
            };
        case UPDATE_RATE_EMERGENCY_CALL_ERROR:
            return {
                ...state,
                requesting: false,
                successChange: false,
                error: action.error
            };
        case GET_RATE_SUPPLY_REQUESTING:
            return{
                ...state,
                requesting: true,
                country_id: action.values.country_id,
                category_id: action.values.category_id,
            };
        case GET_RATE_SUPPLY_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                rateSupplies: action.rateSupplies
            };
        case GET_RATE_SUPPLY_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: ''
            };
        case UPDATE_RATE_SUPPLY_REQUESTING:
            return {
                ...state,
                requesting: true,
                successChange: false,
                error: ''
            };
        case UPDATE_RATE_SUPPLY_SUCCESS:
            return {
                ...state,
                requesting: false,
                successChange: true,
                error: '',
                rateSupplies: action.rateSupplyUpdate
            };
        case UPDATE_RATE_SUPPLY_ERROR:
            return {
                ...state,
                requesting: false,
                successChange: false,
                error: action.error
            };
        default:
            return state;
    }  
};

export default reducer;