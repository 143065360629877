import {
    LOGIN_ERROR,
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    SEND_EMAIL_REQUESTING,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_ERROR,
    VERIFY_TOKEN_REQUESTING,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    RESET_PASSWORD_REQUESTING,
    RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR, MODAL_PASSWORD
} from './constants'

export const loginRequest = ({email, password}) => ({
    type: LOGIN_REQUESTING,
    email,
    password,
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

export const loginError = (error) => ({
    type: LOGIN_ERROR,
    error,
});

export const sendEmailRequesting = (values) => ({
    type: SEND_EMAIL_REQUESTING,
    values
});

export const sendEmailSuccess = (message) => ({
    type: SEND_EMAIL_SUCCESS,
    message
});

export const sendEmailError = (error) => ({
    type: SEND_EMAIL_ERROR,
    error
});

export const verifyTokenRequesting = (values) => ({
    type: VERIFY_TOKEN_REQUESTING,
    values
});

export const verifyTokenSuccess = (message) => ({
    type: VERIFY_TOKEN_SUCCESS,
    message
});

export const verifyTokenError = (error) => ({
    type: VERIFY_TOKEN_ERROR,
    error
});

export const resetPasswordRequesting = (values) => ({
    type: RESET_PASSWORD_REQUESTING,
    values
});

export const resetPasswordSuccess = (message) => ({
    type: RESET_PASSWORD_SUCCESS,
    message
});

export const resetPasswordError = (error) => ({
    type: RESET_PASSWORD_ERROR,
    error
});

export const modalPassword = (modal) => ({
    type: MODAL_PASSWORD,
    modal
});