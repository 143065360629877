import {call, put, takeEvery, all} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {unitMeasuresGetError, unitMeasuresGetSuccess} from "./actions";
import {UNIT_MEASURES_GET_REQUESTING} from "./constants";

const unitMeasuresURL  = `${process.env.REACT_APP_API_URL}/api/unitMeasure`;

function unitMeasuresGetApi(token){
    return fetch(unitMeasuresURL,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error)=>{
            throw error
        })
}

function* unitMeasuresGetFlow(action){
    try {
        const {token} = action;
        const unitMeasures = yield call(unitMeasuresGetApi, token);
        yield put(unitMeasuresGetSuccess(unitMeasures));
    }catch(error) {
        yield put(unitMeasuresGetError(error))
    }
}

function* unitMeasureWatcher() {
    yield all([
        takeEvery(UNIT_MEASURES_GET_REQUESTING, unitMeasuresGetFlow),
    ])
}

export default unitMeasureWatcher