export const GET_PROVIDER_REQUESTING = 'GET_PROVIDER_REQUESTING';
export const GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
export const GET_PROVIDER_ERROR = 'GET_PROVIDER_ERROR';

export const GET_ALL_PROVIDER_REQUESTING = 'GET_ALL_PROVIDER_REQUESTING';
export const GET_ALL_PROVIDER_SUCCESS = 'GET_ALL_PROVIDER_SUCCESS';
export const GET_ALL_PROVIDER_ERROR = 'GET_ALL_PROVIDER_ERROR';

export const CHANGE_STATE_PROVIDER_REQUESTING = 'CHANGE_STATE_PROVIDER_REQUESTING';
export const CHANGE_STATE_PROVIDER_SUCCESS = 'CHANGE_STATE_PROVIDER_SUCCESS';
export const CHANGE_STATE_PROVIDER_ERROR = 'CHANGE_STATE_PROVIDER_ERROR';

export const SEARCH_PROVIDER_REQUESTING = 'SEARCH_PROVIDER_REQUESTING';
export const SEARCH_PROVIDER_SUCCESS = 'SEARCH_PROVIDER_SUCCESS';
export const SEARCH_PROVIDER_ERROR = 'SEARCH_PROVIDER_ERROR';

export const CREATE_PROVIDER_REQUESTING = 'CREATE_PROVIDER_REQUESTING';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_ERROR = 'CREATE_PROVIDER_ERROR';

export const SET_FOCUS_PROVIDER = 'SET_FOCUS_PROVIDER';

export const UPDATE_PROVIDER_REQUESTING = 'UPDATE_PROVIDER_REQUESTING';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'UPDATE_PROVIDER_ERROR';

export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';