import React from 'react';
import LogoIngetem from './../../assets/logos/logo-ingetem.png';
import ImgLogin2 from './../../assets/img/logo-no-text.png';
import ImgLogin3 from './../../assets/img/logo-no-text.png';
import {reduxForm, Field, Form} from "redux-form";
import {connect} from "react-redux";
import {loginRequest, modalPassword} from "../../redux/login/actions";
import {Redirect} from "react-router-dom";
import ModalPassword from "./modals/forgot-password";

class LoginBack extends React.Component {

    state = {
        isPassword : false,
    };

    handleSubmit = (values) => {
        this.props.loginRequest(values);
    };

    handleShowModal = () => {
        const {modalPassword} = this.props;
        modalPassword(true);

    };
    handleHiddenModal = () => {
        const {modalPassword} = this.props;
        modalPassword(false);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const {
            handleSubmit,
            login,
            client,
        } = this.props;
        if (login.success || client.isLogged)
            return <Redirect from="/" to={'/servicio-diseño'}/>;
        return (
            <div>
                <div className="container-register container-register-img">
                    <div className="container-info">
                        <div className="info">
                            <button href="#" className="title">INGETEM</button>
                            <div className="title-register">Administrador general</div>
                            <br/>
                            <div className="step">
                                <span className="circle">1</span>
                                <h2>Gestiona entregas</h2>
                            </div>
                            <div className="step">
                                <span className="circle">2</span>
                                <h2>Controla tu negocio</h2>
                            </div>
                            <div className="step">
                                <span className="circle">3</span>
                                <h2>Administración de información</h2>
                            </div>
                            {/*<div className="step">*/}
                            {/*    <span className="circle">4</span>*/}
                            {/*    <h2>Administración de información</h2>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="form">
                        <div id="form-register">
                            <img className="hiden" src={LogoIngetem} alt=""/>
                            <img className="hiden2" src={LogoIngetem} alt=""/>
                            <h1 className="title-form-h1">Iniciar sesión</h1>
                            <p>Administrador general</p>
                            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                                <div className="input-group">
                                    <Field type="email"
                                           placeholder="Email"
                                           className="email"
                                           required
                                           name="email"
                                           component={'input'}/>
                                </div>
                                <div className="input-group">
                                    <Field type="password"
                                           placeholder="Contraseña"
                                           className="password"
                                           name="password"
                                           required
                                           component={'input'}/>
                                </div>
                                {login.errors.length > 0 && (
                                    <div className="modal-error">
                                        <label className="incorrect">{login.errors}</label>
                                    </div>
                                )}
                                <button className="register" action="submit">
                                    Ingresar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </Form>
                            <div className="separate">
                                <span onClick={this.handleShowModal.bind(this)} style={{color: 'black'}}>¿ Olvido su contraseña ?</span>
                            </div>
                            {login.modal && (
                                <ModalPassword
                                    stop={this.stopPropagation.bind(this)}
                                    hidden={this.handleHiddenModal.bind(this)}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    login: state.login,
    client: state.client,
});

const connected = connect(mapStateToProps, {
    loginRequest,
    modalPassword
})(LoginBack);

const formed = reduxForm({
    form: 'LoginBack'
})(connected);

export default formed;