import {
    CHANGE_STATE_BRAND_ERROR,
    CHANGE_STATE_BRAND_REQUESTING, CHANGE_STATE_BRAND_SUCCESS,
    CREATE_BRAND_ERROR,
    CREATE_BRAND_REQUESTING,
    CREATE_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    GET_BRAND_REQUESTING,
    GET_BRAND_SUCCESS, SET_FOCUS_BRAND, UPDATE_BRAND_ERROR, UPDATE_BRAND_REQUESTING, UPDATE_BRAND_SUCCESS
} from "./constants";


const initialState = {

    requesting: false,
    success: false,
    error: '',
    brands: [],
    brandId: 0,
    focusBrand: 0,
    brandDataFocus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRAND_REQUESTING:
            return{
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case GET_BRAND_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              brands: action.brands
            };
        case GET_BRAND_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error,
              brands: []
            };
        case CREATE_BRAND_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case CREATE_BRAND_SUCCESS:
            return {
              ...state,
              requesting: false,
              error: '',
              success: true,
              brands: [action.brandCreated, ...state.brands]
            };
        case CREATE_BRAND_ERROR:
            return {
              ...state,
              requesting: false,
              error: action.error,
              success: false
            };
        case SET_FOCUS_BRAND:
            return {
              ...state,
              focusBrand: action.id,
              brandDataFocus: action.brand
            };
        case UPDATE_BRAND_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case UPDATE_BRAND_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              brands: state.brands.map((brand) => brand.id === state.focusBrand ? action.brandUpdate : brand)
            };
        case UPDATE_BRAND_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case CHANGE_STATE_BRAND_REQUESTING:
            return {
              ...state,
              requesting: true,
              brandId: action.brandId
            };
        case CHANGE_STATE_BRAND_SUCCESS:
            return {
              ...state,
              brands: state.brands.map(brandItem => (
                  brandItem.id === action.brand.id ? action.brand : brandItem
              ))
            };
        case CHANGE_STATE_BRAND_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        default:
            return state;
    }
};

export default reducer;