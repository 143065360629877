import {
    PRODUCT_CREATE_REQUESTING,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_ERROR,

    PRODUCT_UPDATE_REQUESTING,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_ERROR,

    GET_PRODUCT_REQUESTING,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_ERROR,

    PRODUCT_CHANGE_STATE_REQUESTING,
    PRODUCT_CHANGE_STATE_SUCCESS,
    PRODUCT_CHANGE_STATE_ERROR,

    PRODUCT_SEARCH_REQUESTING,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_ERROR,
    SET_FOCUS_PRODUCT,
    PRODUCTS_ALL_GET_REQUESTING,
    PRODUCTS_ALL_GET_SUCCESS,
    PRODUCTS_ALL_GET_ERROR,

    IMAGE_DELETE_REQUESTING,
    IMAGE_DELETE_SUCCESS,
    IMAGE_DELETE_ERROR,

    SHIPPING_TYPE_ATTACH_REQUESTING,
    SHIPPING_TYPE_ATTACH_SUCCESS,
    SHIPPING_TYPE_ATTACH_ERROR,

    SHIPPING_TYPE_DETACH_REQUESTING,
    SHIPPING_TYPE_DETACH_SUCCESS,
    SHIPPING_TYPE_DETACH_ERROR,
    CAPACITY_POST_REQUESTING,
    CAPACITY_POST_SUCCESS,
    CAPACITY_POST_ERROR,
    CAPACITY_FOCUS,
    CAPACITY_FOCUS_CLEAN,
    CAPACITY_PUT_REQUESTING,
    CAPACITY_PUT_SUCCESS,
    CAPACITY_PUT_ERROR,
    CAPACITY_DELETE_REQUESTING,
    CAPACITY_DELETE_SUCCESS, CAPACITY_DELETE_ERROR
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    focusProduct: 0,
    productDataFocus:{},
    filterProduct: 'Todos',
    hasMore: true,
    hasLess: false,
    paginateProduct: 1,
    products: [],
    product: [],
    productsSelect: [],
    searchProduct:'',
    update: false,

    capacityRequesting: false,
    capacitySuccess: false,
    capacity: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        //process create product
        case PRODUCT_CREATE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case PRODUCT_CREATE_SUCCESS:
            return{
                ...state,
                requesting:false,
                success: true,
                error: '',
                products: [action.productCreated, ...state.products]
            };
        case PRODUCT_CREATE_ERROR:
            return{
                ...state,
                requesting: false,
                success:false,
                error: action.error,
            };

            //process update a product
        case SET_FOCUS_PRODUCT:
            return{
                ...state,
                focusProduct: action.id,
                productDataFocus: action.product
            };
        case PRODUCT_UPDATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                success:false,
                update: false,
                error: ''
            };
        case PRODUCT_UPDATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                update: true,
                product: state.product.map((product)=>product.id ===action.updateProduct.id ? action.updateProduct:product),
                products: state.products.map((product)=>product.id ===action.updateProduct.id ? action.updateProduct:product),
            };
        case PRODUCT_UPDATE_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                update: false,
                error: action.error
            };
            //process for obtain products
        case GET_PRODUCT_REQUESTING :
            return {
                ...state,
                paginateProduct: action.paginateProduct,
                filterProduct: action.filterProduct
            };
        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                products: action.products,
                hasLess: state.paginateProduct !== 1,
                hasMore: true
            };
        case GET_PRODUCT_ERROR:
            return {
                ...state,
                error: action.error,
                hasMore: false,
                paginateProduct: state.paginateProduct !== 1 && state.paginateProduct - 1,
                products: []
            };

            //process for update state product
        case PRODUCT_CHANGE_STATE_REQUESTING:
            return{
                ...state,
                requesting: true,
                productId: action.productId
            };
        case PRODUCT_CHANGE_STATE_SUCCESS:
            return{
                ...state,
                requesting: false,
                products: state.products.map(productItem => (
                    productItem.id === action.product.id ? action.product: productItem
                )),
                product: state.product.length > 0 && state.product.map(productItem => (
                    productItem.id === action.product.id ? action.product : productItem
                ))
            };
        case PRODUCT_CHANGE_STATE_ERROR:
            return{
                requesting: false,
                success: false,
                error: ''
            };

        //buscar datos reducer
        case PRODUCT_SEARCH_REQUESTING:
            return{
                ...state,
                searchProduct: action.searchProduct
            };
        case PRODUCT_SEARCH_SUCCESS:
            return{
                ...state,
                product: action.product,
            };
        case PRODUCT_SEARCH_ERROR:
            return{
                ...state,
                product: []
            };
        case PRODUCTS_ALL_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case PRODUCTS_ALL_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                productsSelect: action.productsSelect,
            };
        case PRODUCTS_ALL_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case IMAGE_DELETE_REQUESTING:
            return {
                ...state,
                deleteImage: action.imageId
            };
        case IMAGE_DELETE_SUCCESS:
            return {
                ...state,
                products: state.products.map(product => {
                    product.fotos.data.map((photo, index) => {
                        if (photo.id === state.deleteImage) {
                            return product.fotos.data.splice(index, 1);
                        }
                    });
                    return product;
                })
            };
        case IMAGE_DELETE_ERROR:
            return {
                ...state,
            };
        case SHIPPING_TYPE_ATTACH_REQUESTING:
            return {
                ...state,
                focusProduct: action.product,
            };
        case SHIPPING_TYPE_ATTACH_SUCCESS:
            return {
                ...state,
                product: state.product.map(product => product.id === action.product.id ? action.product : product),
                products: state.products.map(product => product.id === action.product.id ? action.product : product),
            };
        case SHIPPING_TYPE_ATTACH_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SHIPPING_TYPE_DETACH_REQUESTING:
            return {
                ...state,
                focusProduct: action.product,
            };
        case SHIPPING_TYPE_DETACH_SUCCESS:
            return {
                ...state,
                product: state.product.map(product => product.id === action.product.id ? action.product : product),
                products: state.products.map(product => product.id === action.product.id ? action.product : product),
            };
        case SHIPPING_TYPE_DETACH_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case CAPACITY_POST_REQUESTING:
            return {
                ...state,
                capacityRequesting: true,
                capacitySuccess: false,
                error: '',
            };
        case CAPACITY_POST_SUCCESS:
            return {
                ...state,
                capacityRequesting: false,
                capacitySuccess: true,
                product: state.product.map((product) => product.id === action.product.id ? action.product : product),
                products: state.products.map((product) => product.id === action.product.id ? action.product : product),
            };
        case CAPACITY_POST_ERROR:
            return {
                ...state,
                capacityRequesting: false,
                error: action.error,
            };
        case CAPACITY_FOCUS:
            return {
                ...state,
                capacity: action.capacity,
            };
        case CAPACITY_FOCUS_CLEAN:
            return {
                ...state,
                capacity: {},
            };
        case CAPACITY_PUT_REQUESTING:
            return {
                ...state,
                capacityRequesting: true,
                capacitySuccess: false,
                error: '',
            };
        case CAPACITY_PUT_SUCCESS:
            return {
                ...state,
                capacityRequesting: false,
                capacitySuccess: true,
                productDataFocus: action.product,
                product: state.product.map((product) => product.id === action.product.id ? action.product : product),
                products: state.products.map((product) => product.id === action.product.id ? action.product : product),
            };
        case CAPACITY_PUT_ERROR:
            return {
                ...state,
                capacityRequesting: false,
                error: action.error,
            };
        case CAPACITY_DELETE_REQUESTING:
            return {
                ...state,
                capacityRequesting: true,
                capacitySuccess: false,
                error: '',
            };
        case CAPACITY_DELETE_SUCCESS:
            return {
                ...state,
                capacityRequesting: false,
                capacitySuccess: true,
                productDataFocus: action.product,
                product: state.product.map((product) => product.id === action.product.id ? action.product : product),
                products: state.products.map((product) => product.id === action.product.id ? action.product : product),
            };
        case CAPACITY_DELETE_ERROR:
            return {
                ...state,
                capacityRequesting: true,
                capacitySuccess: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer