import React from 'react';

export class ModalConfirmedWireTransfer extends React.Component {


    render() {
        const {requesting} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Confirmar transferencia bancaria</h1>
                    </div>
                    <div>
                        <h2>Tenga en cuenta que al realizar esta acción no podrá volver atrás.</h2>
                        <h3>¿Desea confirmar el pago?</h3>
                    </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                Cancelar
                                <i className="icon-cancel styles-icons"/>
                            </button>
                            {requesting && (
                                <button style={{backgroundColor: '#787878', color: 'white'}} className="Btn-modal">
                                    Confirmando
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            )}
                            {!requesting && (
                                <button className="Btn-modal" onClick={this.props.confirmed.bind(this)}>
                                    Confirmar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
