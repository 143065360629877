import {
    GET_OTHER_PROJECT_ERROR,
    GET_OTHER_PROJECT_REQUESTING, GET_OTHER_PROJECT_SUCCESS,
    GET_PROJECT_ERROR,
    GET_PROJECT_REQUESTING,
    GET_PROJECT_SUCCESS
} from "./constants";


export const getProjectRequesting = (token) => ({
    type: GET_PROJECT_REQUESTING,
    token
});

export const getProjectSuccess = (projects) => ({
    type: GET_PROJECT_SUCCESS,
    projects
});

export const getProjectError = (error) => ({
    type: GET_PROJECT_ERROR,
    error
});

export const getOtherProjectRequesting = (token) => ({
    type: GET_OTHER_PROJECT_REQUESTING,
    token
});

export const getOtherProjectSuccess = (otherProjects) => ({
    type: GET_OTHER_PROJECT_SUCCESS,
    otherProjects
});

export const getOtherProjectError = (error) => ({
    type: GET_OTHER_PROJECT_ERROR,
    error
});
