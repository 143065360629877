import {handleApiErrors} from "../errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {getTypeIdentificationSuccess,getTypeIdentificationError} from "./actions";
import {GET_TYPE_IDENTIFICATION_REQUESTING} from "./constants";

const getTypeIdentificationUrl  = `${process.env.REACT_APP_API_URL}/api/typeidentifications`;

const getTypeIdentificationAPI = (token) => {
    return fetch(getTypeIdentificationUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }). catch((error) => {
            throw error;
        })
};

function* getTypeIdentificationFlow(action) {
    try {
        const {token} = action;
        const typeIdentifications = yield call(getTypeIdentificationAPI, token);
        yield put(getTypeIdentificationSuccess(typeIdentifications));
    }catch (error) {
        yield put(getTypeIdentificationError(error))
    }
}

function* categoryWatcher() {
    yield all([
        takeEvery(GET_TYPE_IDENTIFICATION_REQUESTING, getTypeIdentificationFlow)
    ])
}

export default categoryWatcher;