import React from 'react';
import {FilePond, File, registerPlugin} from 'react-filepond';

import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import connect from "react-redux/es/connect/connect";
import {createLeaderManagerRequesting} from "../../../redux/leader-manager/actions";
import {Field, reduxForm} from "redux-form";
import {getCityCountryRequesting} from "../../../commons/countries/actions";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileInputUnique = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={1}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

const FileInputMultiple = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={6}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

export class ModalLeaderManager extends React.Component {

    handleGetCities = (id) => {
        const {getCityCountryRequesting, client: {token}} = this.props;
        getCityCountryRequesting(id, token);
    };

    componentWillUpdate(nextProps){
        const {leaderManager} = this.props;

        if(nextProps.leaderManager.error !== '' && nextProps.leaderManager.error !== leaderManager.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.leaderManager.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if(nextProps.leaderManager.success && !leaderManager.success){
            toastr.success('Gerente Líder agregado', 'Gerente líder agregado, ya puede empezar a usar el servicio' );
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {client, createLeaderManagerRequesting} = this.props;
        createLeaderManagerRequesting(values, client.token);
    };


    render() {
        const { country: {countries,cities}, handleSubmit} = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <h1 className="arreglojulian">Agregar Nuevo gerente lider</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                    <div className="item-modal">
                    <label>Subir foto: </label>
                    <Field name={'photo'} component={FileInputUnique}/>
                    </div>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <Field name={'name'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Contraseña: </label>
                        <Field name={'password'}  type="password" className="input input-city" component={'input'} />
                    </div>
                    <div className="item-modal">
                        <label>Cedula: </label>
                        <Field name={'identification_number'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Correo electrónico: </label>
                        <Field name={'email'} type="text" className="input input-name" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Teléfono fijo: </label>
                        <Field name={'phone'} type="text" className="input input-city" component={'input'}/>
                    </div>
                    <div className="item-modal">
                        <label>Dirección: </label>
                        <Field name={'address'} type="text" className="input input-city" component={'input'}/>
                    </div>
                        <div className="item-modal">
                            <label>País: </label>
                            <Field component={'select'} name="country_id" className="select-rol"
                                   onChange={(e) => this.handleGetCities(e.target.value)}>
                                <option value="0">Seleccionar pais</option>
                                {countries.map((country, index) => <option value={country.id}
                                                                           key={index}>{country.nombre}</option>)}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Ciudad: </label>
                            <Field component={'select'} name="city_id" className="select-rol">
                                <option value="0">Seleccionar ciudad</option>
                                {cities.map((city, index) => <option value={city.id}
                                                                     key={index}>{city.nombre}</option>)}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Nombre de Contacto: </label>
                            <Field name={'contact_name'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Número de Contacto: </label>
                            <Field name={'contact_number'} type="text" className="input input-city" component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Documentos</label>
                            <Field name={'leaderManager_documents'} component={FileInputMultiple}/>
                        </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Crear Gerente<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    leaderManager: state.leaderManager,
    city: state.city,
    country: state.country
});

const connected = connect(mapStateToProps, {
    createLeaderManagerRequesting,
    getCityCountryRequesting
})(ModalLeaderManager);

const formed = reduxForm({
    form: 'driverModal'
})(connected);

export default formed
