import React from 'react';
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {orderShippingPutRequesting} from "../../../redux/order/actions";

class ModalEditOrders extends React.Component {

    constructor(props) {
        super(props);
        this.buttonEdit = React.createRef();
    }

    componentWillUpdate(nextProps){
        const {order} = this.props;
        let button = null;

        if (this.buttonEdit.current !== null)
            button = Ladda.create(this.buttonEdit.current);

        if (nextProps.order.requestingShipping)
            button.start();

        if (nextProps.order.error !== '' && nextProps.order.error !== order.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.order.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
            button.stop();
        }
        if (nextProps.order.successShipping)
            button.stop();

        if (nextProps.order.successShipping && !order.successShipping) {
            toastr.success('Orden Actualizada', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {
            client: {token},
            orderShippingPutRequesting,
            order: {focusOrder},
        } = this.props;
        orderShippingPutRequesting(values, token, focusOrder)
    };

    render() {
        const {
            handleSubmit,
            initialValues,
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <h1 className="arreglojulian">
                            Agregar información de la orden
                        </h1>
                        <div className="item-modal">
                            <label>Empresa de mensajeria:</label>
                            <Field name={'empresa_mensajeria'} component={'input'}/>
                        </div>
                        <div>
                            <label>Numero de remesa:</label>
                            <Field name={'numero_remesa'} component={'input'}/>
                        </div>
                        <div className="button-container">
                            <div className="ladda-button btn-container" data-style="expand-left">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" ref={this.buttonEdit}>
                                    Aprobar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'OrderEditModal'
})(ModalEditOrders);

const mapStateToProps = state => ({
    client: state.client,
    order: state.order,
    product: state.product,
    initialValues: state.order.orderDataFocus,
});

const connected = connect(mapStateToProps, {
    orderShippingPutRequesting,
})(formed);

export default connected