import React from 'react';
import connect from "react-redux/es/connect/connect";
import {reduxForm} from "redux-form";
import RowPercentageVisitRoca from "../rows/rows-emergency-call/rowPercentageVisitRoca";
import RowPercentageVisitLeaderManager from "../rows/rows-emergency-call/rowPercentageVisitLeaderManager";
import RowPriceVisit from '../rows/rows-emergency-call/rowPriceVisit';

class TableEmergencyValue
    extends React.Component {


    state = {
        editable: false,
        editable2: false,
        editable3: false,
        value: '',
        value2: '',
        value3: '',
    };

    handleChange = (key, value) => {
        this.setState({key: value})
        // if (Number.isInteger(value))
        //
    };

    handleEditable = (value, editable) => {
        this.setState({
            editable: value === 'percentageVisitRoca' && editable,
            editable2:value === 'percentageVisitLeaderManager' && editable,
            editable3: value === 'priceVisit' && editable,
        });
    };

    render() {
        return (
            <div className="Conteiner-table">
                <table className="table-values" style={{width: '45.7%'}}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>
                            <th style={{width: '430px'}}>Item</th>
                            <th>Accíon</th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <div>
                        <RowPercentageVisitRoca editable={this.state.editable} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPercentageVisitLeaderManager editable={this.state.editable2} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPriceVisit editable={this.state.editable3} handleEditable={this.handleEditable.bind(this)}/>
                    </div>
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    rate: state.rate,
});

const connected = connect(mapStateToProps, {

})(TableEmergencyValue);

const formed = reduxForm({
    form: 'valueConfigEmergencyCall'
})(connected);

export default formed