import {
    EMERGENCY_CALLS_GET,
    EMERGENCY_CALLS_GET_SUCCESS,
    EMERGENCY_CALLS_GET_ERROR,
    EMERGENCY_CALL_FOCUS,
    EMERGENCY_CALL_FOCUS_CLEAN,
    GET_EMERGENCY_CALL_REQUESTING,
    GET_EMERGENCY_CALL_SUCCESS,
    GET_EMERGENCY_CALL_ERROR,
    SEARCH_EMERGENCY_CALL_REQUESTING,
    SEARCH_EMERGENCY_CALL_SUCCESS,
    SEARCH_EMERGENCY_CALL_ERROR,
} from "./constants";

const initialState = {
    requesting: false,
    successful: false,
    messages: '',
    errors: '',
    hasLess: false,
    cursor: 1,
    error: '',
    success: false,
    search: '',
    emergencyCall : [],
    filter: 'Todos',
    paginate: 1,
    hasMore: true,
    emergencyCalls: [],
    emergencyCallFocus: undefined,
};

const reducer = function emergencyCallsReducer(state = initialState, action) {
    switch (action.type) {
        case EMERGENCY_CALLS_GET:
            return {
                ...state,
                requesting: true,
                successful: false,
                messages: '',
                errors: '',
                filter: action.filter,
                paginate: action.paginate,
            };
        case EMERGENCY_CALLS_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                successful: true,
                messages: '',
                paginate: action.paginate,
                emergencyCalls: action.emergencyCalls,
            };
        case EMERGENCY_CALLS_GET_ERROR:
            return {
                ...state,
                requesting: false,
                successful: false,
                messages: '',
                errors: action.error,
                paginate: 1,
                hasMore: false,
                emergencyCalls: [],
            };
        case EMERGENCY_CALL_FOCUS:
            return {
                ...state,
                emergencyCallFocus: action.emergencyCall,
            };
        case EMERGENCY_CALL_FOCUS_CLEAN:
            return {
                ...state,
                emergencyCallFocus: undefined,
            };

        case GET_EMERGENCY_CALL_REQUESTING:
            return {
              ...state,
              cursor: action.cursor,
              filter: action.filter
            };
        case GET_EMERGENCY_CALL_SUCCESS:
            return {
              ...state,
              emergencyCalls: action.emergencyCalls,
              hasLess: state.cursor + 1 > 1,
              hasMore: true
            };
        case GET_EMERGENCY_CALL_ERROR:

            return {
              ...state,
              error: action.error,
              hasMore: false,
              emergencyCalls: []
            };
        case SEARCH_EMERGENCY_CALL_REQUESTING:
            return{
              ...state,
                search: action.search,
            };
        case SEARCH_EMERGENCY_CALL_SUCCESS:

            return {
              ...state,
              emergencyCall: action.emergencyCall
            };
        case SEARCH_EMERGENCY_CALL_ERROR:
            return {
              ...state,
              emergencyCall: []
            };
        default:
            return state
    }
};

export default reducer