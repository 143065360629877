export const SERVICES_DESIGN_GET_REQUESTING = 'SERVICES_DESIGN_GET_REQUESTING';
export const SERVICES_DESIGN_GET_SUCCESS = 'SERVICES_DESIGN_GET_SUCCESS';
export const SERVICES_DESIGN_GET_ERROR = 'SERVICES_DESIGN_GET_ERROR';

export const SERVICES_INSTALLATION_GET_REQUESTING = 'SERVICES_INSTALLATION_GET_REQUESTING';
export const SERVICES_INSTALLATION_GET_SUCCESS = 'SERVICES_INSTALLATION_GET_SUCCESS';
export const SERVICES_INSTALLATION_GET_ERROR = 'SERVICES_INSTALLATION_GET_ERROR';

export const SERVICES_MAINTENANCE_GET_REQUESTING = 'SERVICES_MAINTENANCE_GET_REQUESTING';
export const SERVICES_MAINTENANCE_GET_SUCCESS = 'SERVICES_MAINTENANCE_GET_SUCCESS';
export const SERVICES_MAINTENANCE_GET_ERROR = 'SERVICES_MAINTENANCE_GET_ERROR';

export const SERVICES_SEARCH_DESIGN_REQUESTING = 'SERVICES_SEARCH_DESIGN_REQUESTING';
export const SERVICES_SEARCH_DESIGN_SUCCESS = 'SERVICES_SEARCH_DESIGN_SUCCESS';
export const SERVICES_SEARCH_DESIGN_ERROR = 'SERVICES_SEARCH_DESIGN_ERROR';

export const SERVICES_SEARCH_INSTALLATION_REQUESTING = 'SERVICES_SEARCH_INSTALLATION_REQUESTING';
export const SERVICES_SEARCH_INSTALLATION_SUCCESS = 'SERVICES_SEARCH_INSTALLATION_SUCCESS';
export const SERVICES_SEARCH_INSTALLATION_ERROR = 'SERVICES_SEARCH_INSTALLATION_ERROR';

export const SERVICES_SEARCH_MAINTENANCE_REQUESTING = 'SERVICES_SEARCH_MAINTENANCE_REQUESTING';
export const SERVICES_SEARCH_MAINTENANCE_SUCCESS = 'SERVICES_SEARCH_MAINTENANCE_SUCCESS';
export const SERVICES_SEARCH_MAINTENANCE_ERROR = 'SERVICES_SEARCH_MAINTENANCE_ERROR';

export const STATES_SERVICE_GET_REQUESTING = 'STATES_SERVICE_GET_REQUESTING';
export const STATES_SERVICE_GET_SUCCESS = 'STATES_SERVICE_GET_SUCCESS';
export const STATES_SERVICE_GET_ERROR = 'STATES_SERVICE_GET_ERROR';

export const SERVICE_FOCUS = 'SERVICE_FOCUS';
export const SERVICE_FOCUS_CLEAN = 'SERVICE_FOCUS_CLEAN';

export const CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_ERROR';

export const CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_ERROR';

export const CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_ERROR';

export const QUOTATION_FOCUS = 'QUOTATION_FOCUS';

export const CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_ERROR';

export const CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_ERROR';

export const CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_SUCCESS';
export const CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR = 'CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_ERROR';

export const RESET_STATES = 'RESET_STATES';