import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from "redux-saga/effects";
import {
    changeStateProviderError,
    changeStateProviderSuccess, createProviderError, createProviderSuccess,
    getProviderError,
    getProviderSuccess, searchProviderError,
    searchProviderSuccess, updateProviderError, updateProviderSuccess,
    getAllProviderError,
    getAllProviderSuccess
} from "./actions";
import {
    CHANGE_STATE_PROVIDER_REQUESTING,
    CREATE_PROVIDER_REQUESTING,
    GET_PROVIDER_REQUESTING,
    SEARCH_PROVIDER_REQUESTING,
    UPDATE_PROVIDER_REQUESTING,
    GET_ALL_PROVIDER_REQUESTING
} from "./constants";

const getProviderUrl = `${process.env.REACT_APP_API_URL}/api/providers/filter`;
const changeStateProviderUrl = `${process.env.REACT_APP_API_URL}/api/states/provider/`;
const searchProviderUrl = `${process.env.REACT_APP_API_URL}/api/providers/search`;
const createProviderUrl = `${process.env.REACT_APP_API_URL}/api/providers`;
const updateProviderUrl = `${process.env.REACT_APP_API_URL}/api/providers`;
const providerURL = `${process.env.REACT_APP_API_URL}/api/provider`;

const getAllprovidersApi = (token) => {
    return fetch(`${providerURL}/all`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw json
        }).catch((error) => {
            throw error;
        })
};

function* getAllprovidersGetFlow(action) {
    try {
        const {token} = action;
        const productsAll = yield call(getAllprovidersApi, token);
        yield put(getAllProviderSuccess(productsAll));
    } catch (error) {
        yield put(getAllProviderError(error));
    }
}

const getProviderAPI = (cursor, filter, token) => {
    return fetch(`${getProviderUrl}/${filter}?page=${cursor}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.code === 400)
                throw  json.data.data;
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getProviderFlow(action) {
    try {
        const {cursor, filter, token} = action;
        const providers = yield call(getProviderAPI, cursor, filter, token);
        yield put(getProviderSuccess(providers));
    } catch (error) {
        yield put(getProviderError(error));
    }
}

const stateProviderAPI = (providerId, token) => {

    return fetch(changeStateProviderUrl + providerId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* stateProviderFlow(action) {
    try {
        const {providerId, token} = action;
        const provider = yield call(stateProviderAPI, providerId, token);
        yield put(changeStateProviderSuccess(provider));
    } catch (error) {
        yield put(changeStateProviderError(error));
    }
}

const searchProviderAPI = (search, token) => {

    let body = {
        search: search
    };

    return fetch(searchProviderUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* searchProviderFlow(action) {
    try {
        const {search, token} = action;
        const provider = yield call(searchProviderAPI, search, token);
        yield put(searchProviderSuccess(provider));
    } catch (error) {
        yield put(searchProviderError(error));
    }
}

const createProviderAPI = (provider, token) => {
    let body = new FormData();
    body.append('name', provider.name);
    body.append('nit', provider.nit);
    body.append('email', provider.email);
    body.append('password', provider.password);
    body.append('headquarters', provider.headquarters);
    body.append('address', provider.address);
    body.append('phone', provider.phone);
    body.append('mobile', provider.mobile);
    body.append('commission', provider.commission);
    body.append('country_id', provider.country_id);
    body.append('city_id', provider.city_id);
    if (provider.photo)
        body.append('photo', provider.photo[0].file || '');
    if (provider.provider_documents) {
        provider.provider_documents.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('documentsLenght', provider.provider_documents.length);
    }

    return fetch(createProviderUrl, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createProviderFlow(action) {
    try {
        const {provider, token} = action;
        const providerCreated = yield call(createProviderAPI, provider, token);
        yield put(createProviderSuccess(providerCreated));
    } catch (error) {
        yield put(createProviderError(error));
    }
}

const updateProviderAPI = (provider, token, id) => {

    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('name', provider.nombre || '');
    body.append('headquarters', provider.sede || '');
    body.append('nit', provider.identificacion || '');
    body.append('email', provider.correo || '');
    body.append('phone', provider.telefono || '');
    body.append('mobile', provider.celular || '');
    body.append('address', provider.direccion || '');
    body.append('commission', provider.comision || '');
    body.append('city_id', Number.isInteger(parseInt(provider.ciudad)) ? provider.ciudad : provider.ciudad_id);
    body.append('country_id', Number.isInteger(parseInt(provider.pais)) ? provider.pais : provider.pais_id);
    if (provider.foto.length > 0 && provider.foto[0].file !== undefined)
        body.append('photo', provider.foto[0].file || '');

    return fetch(`${updateProviderUrl}/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateProviderFlow(action) {
    try {
        const {provider, token, id} = action;
        const providerUpdate = yield call(updateProviderAPI, provider, token, id);
        yield put(updateProviderSuccess(providerUpdate));
    } catch (error) {
        yield put(updateProviderError(error));
    }
}

function* getProviderWatcher() {
    yield all([
        takeEvery(GET_PROVIDER_REQUESTING, getProviderFlow),
        takeEvery(CHANGE_STATE_PROVIDER_REQUESTING, stateProviderFlow),
        takeEvery(SEARCH_PROVIDER_REQUESTING, searchProviderFlow),
        takeEvery(CREATE_PROVIDER_REQUESTING, createProviderFlow),
        takeEvery(UPDATE_PROVIDER_REQUESTING, updateProviderFlow),
        takeEvery(GET_ALL_PROVIDER_REQUESTING, getAllprovidersGetFlow)
    ])
}

export default getProviderWatcher;