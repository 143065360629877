import {handleApiErrors} from "../errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {getCountrySuccess,getCountryError} from "./actions";
import {GET_COUNTRY_REQUESTING} from "./constants";
import {getCityCountrySuccess,getCityCountryError} from "./actions";
import {GET_CITY_COUNTRY_REQUESTING} from "./constants";

const getCountryUrl  = `${process.env.REACT_APP_API_URL}/api/countries`;
const getCityCountryUrl = `${process.env.REACT_APP_API_URL}/api/countries/`;

const getCountryAPI = (token) => {

    return fetch(getCountryUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }). catch((error) => {
            throw error;
        })
};

function* getCountryFlow(action) {
    try {
        const {token} = action;
        const countries = yield call(getCountryAPI, token);
        yield put(getCountrySuccess(countries));
    }catch (error) {
        yield put(getCountryError(error))
    }
}
const getCityCountryAPI = (countryId, token) => {

    return fetch(getCityCountryUrl + countryId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};
function* getCityCountryFlow(action) {
    try {
        const {countryId, token} = action;
        const getCityCountry = yield call(getCityCountryAPI, countryId, token);
        yield put(getCityCountrySuccess(getCityCountry));
    }catch (error) {
        yield put(getCityCountryError(error));
    }
}
function* categoryWatcher() {
    yield all([
        takeEvery(GET_COUNTRY_REQUESTING, getCountryFlow),
        takeEvery(GET_CITY_COUNTRY_REQUESTING,getCityCountryFlow)
    ])
}

export default categoryWatcher;