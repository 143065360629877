import React from 'react';
import FormattedCurrency from 'react-formatted-currency';

export class ModalQuotation extends React.Component {

    render() {
        const {quotation} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Cotización # {quotation.id}</h1>
                    </div>
                    <div>
                        <label>Precio de los productos:</label>
                        <FormattedCurrency
                            amount={quotation.precio_orden}
                            currency={'COP'}
                            symbol={'$'}
                            locale={'es-CO'}
                            parts={['code', 'number', 'symbol']}
                            render={({symbol, number}) => {
                                return <p style={{textAlign: 'left'}}>{symbol} {number}</p>
                            }}
                        />
                        <label>Precio instalación:</label>
                        <FormattedCurrency
                            amount={quotation.precio_instalation}
                            currency={'COP'}
                            symbol={'$'}
                            locale={'es-CO'}
                            parts={['code', 'number', 'symbol']}
                            render={({symbol, number}) => {
                                return <p style={{textAlign: 'left'}}>{symbol} {number}</p>
                            }}
                        />
                        <label>Precio total:</label>
                        <FormattedCurrency
                            amount={quotation.precio_total}
                            currency={'COP'}
                            symbol={'$'}
                            locale={'es-CO'}
                            parts={['code', 'number', 'symbol']}
                            render={({symbol, number}) => {
                                return <p style={{textAlign: 'left'}}>{symbol} {number}</p>
                            }}
                        />
                        <label>Drescripción:</label>
                        <p style={{textAlign: 'left'}}>{quotation.descripcion}</p>
                        <label>Estado de la cotización:</label>
                        <p style={{textAlign: 'left'}}>{quotation.estado}</p>
                    </div>
                    <div className="button-container">
                        <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                            Cancelar
                            <i className="icon-cancel styles-icons"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
