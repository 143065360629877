import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../utils/api-errors";
import {LOGOUT_REQUESTING, VERIFY_TOKEN} from "./constants";
import {logoutError, logoutSuccess, verifyTokenSuccess, verifyTokenError} from "./actions";

const header = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('@ingetem:admin')
};
const logoutURL = `${process.env.REACT_APP_API_URL}/api/logout`;
const verifyTokenURL = `${process.env.REACT_APP_API_URL}/api/verify`;

const logoutApi = (token) => {
    return fetch(logoutURL, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200) {
                localStorage.removeItem('@ingetem:admin', json.access_token);
                window.location.href = '/';
            }
        }).catch((error) => {
            throw error
        })
};

function* logoutFlow(action) {
    try {
        const {token} = action;
        const logout = yield call(logoutApi, token);
        yield put(logoutSuccess(logout));
    } catch (error) {
        yield put(logoutError(error));
    }
}

function verifyTokenApi(response) {
    let body = {
        token: response,
    };
    if (localStorage.getItem('@ingetem:admin') === undefined)
        throw false;
    else
        return fetch(verifyTokenURL, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(body),
        })
            .then(handleApiErrors)
            .then(response => response.json())
            .then(json => {
                if (json.code === 200)
                    return json.data;
                throw json.data;
            }).catch((error) => {
                throw error
            })
}

function* verifyTokenFlow(action) {
    try {
        const {response} = action;
        yield call(verifyTokenApi, response);
        yield put(verifyTokenSuccess(response));
    } catch (error) {
        yield put(verifyTokenError(error))
    }

}

function* logoutWatcher() {
    yield all([
        takeEvery(LOGOUT_REQUESTING, logoutFlow),
        takeEvery(VERIFY_TOKEN, verifyTokenFlow)
    ])
}

export default logoutWatcher;