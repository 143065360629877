import React from 'react';
import SearchComponent from "../search/search";
import FilterComponent from "../filter/filter";
import TableLeaderManager from "./table/table-leader-manager";
import ModalEditLeaderManager from "./modals/edit-leader-manager";
import ModalLeaderManager from "./modals/add-leader-manager";
import {getLeaderManagerRequesting, changeStateLeaderManagerRequesting, searchLeaderManagerRequesting,
        setFocusLeaderManager, deleteDeviceLeaderManagerRequesting} from "../../redux/leader-manager/actions";
import connect from "react-redux/es/connect/connect";
import {NotFound} from "../../commons/errors/notFound";
import {statesProviderRequesting} from "../../commons/states/actions";
import ModalAddDocument from "./modals/add-document";
import {deleteDocumentProviderRequesting} from "../../redux/documents/actions";
import {TableProviders} from "../providers-product/table/table-providers";
import {getCountryRequesting} from "../../commons/countries/actions";
import {getCategoryRequesting} from "../../redux/category/actions";
import {getSubcategoryRequesting} from "../../redux/subcategory/actions";
import {TableClient} from "../4-Client/table/table-client";

class LeaderManagerComponent
    extends React.Component {
    state = {
        isLeaderManager: false,
        isEditLeaderManager: false,
        isAddDocument: false,
    };
    handleShowModal = (modal, id = 0, leaderManager = {}) => {
        const{setFocusLeaderManager} = this.props;
        if(modal === 'editar' || modal === 'Crear documento')
            setFocusLeaderManager(id, leaderManager);
        this.setState({
            isLeaderManager: modal === 'Gerente lider',
            isEditLeaderManager: modal === 'editar',
            isAddDocument: modal === 'Crear documento',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isLeaderManager: false,
            isEditLeaderManager:false,
            isAddDocument: false
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillMount() {
        const {getLeaderManagerRequesting, client: {token}, leaderManager: {cursor, filter}, statesProviderRequesting,
            getCountryRequesting, getCategoryRequesting, getSubcategoryRequesting} = this.props;
        getLeaderManagerRequesting(cursor, filter, token);
        statesProviderRequesting(token);
        getCountryRequesting(token);
        getCategoryRequesting(token);
        getSubcategoryRequesting(token);
    }

    handleGetData = (cursor) => {
       const {getLeaderManagerRequesting, client, leaderManager} = this.props;
       if(cursor === 1 && leaderManager.hasMore)
            getLeaderManagerRequesting(leaderManager.cursor + cursor, leaderManager.filter, client.token);
        if(cursor === -1 && leaderManager.hasLess)
            getLeaderManagerRequesting(leaderManager.cursor + cursor, leaderManager.filter, client.token);
    };

    handleFilter = (value) => {
      const {getLeaderManagerRequesting, client, leaderManager} = this.props;
      if(leaderManager.filter !== value)
          getLeaderManagerRequesting(leaderManager.cursor, value, client.token);
    };

    handleChangeState = (id) => {
        const {changeStateLeaderManagerRequesting, client} = this.props;
        changeStateLeaderManagerRequesting(id, client.token)
    };

    handleSearch = (value) => {
        const {searchLeaderManagerRequesting, client, leaderManager} = this.props;
        if((leaderManager.search !== value.search) && value.search !== '')
            searchLeaderManagerRequesting(value.search, client.token);
    };

    handleDeleteDocument = (document) => {
        const {deleteDocumentProviderRequesting, client} = this.props;
        deleteDocumentProviderRequesting(document, client.token)
    };

    handleDeleteDevice = (id) => {
        const {deleteDeviceLeaderManagerRequesting} = this.props;
        deleteDeviceLeaderManagerRequesting(id)
    }

    handleGetCsv = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/leadermanager`, "_blank")
    };

    render() {

        const {leaderManager: {leaderManagers, leaderManager, search}, state: {states}} = this.props;
        return (
            <div className="styles-container">
                 {/* Esta estructura permite mostrar resultados de búsqueda o un mensaje de "no encontrado" según el estado de la búsqueda y los datos disponibles. */}
                <SearchComponent
                    type={'Gerente lider'}
                    placeholder={'Digita nombre del Gerente Líder'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== '' && (
                        leaderManager.length > 0 ? <TableLeaderManager
                         handleShowModal={this.handleShowModal.bind(this)}
                         handleDeleteDevice={this.handleDeleteDevice.bind(this)}
                         handleChangeState={this.handleChangeState.bind(this)}
                         handleDeleteDocument={this.handleDeleteDocument.bind(this)}
                         leaderManagers={leaderManager}
                        />:<NotFound/>
                    )}
                </SearchComponent>
                
                <div className="conteiner-page-sector1">

                    {/* Filtros  */}
                    <FilterComponent
                        activeModal={true}
                        type={'Gerente lider'}
                        states={states}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        getData={this.handleGetData.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                    />
                    {leaderManagers.length > 0 ? (
                    <TableLeaderManager
                        leaderManagers={leaderManagers}
                        handleDeleteDevice={this.handleDeleteDevice.bind(this)}
                        handleChangeState={this.handleChangeState.bind(this)}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleDeleteDocument={this.handleDeleteDocument.bind(this)}
                    />
                    ) : <NotFound/> }
                    {this.state.isLeaderManager && (
                        <ModalLeaderManager
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    {this.state.isEditLeaderManager && (
                        <ModalEditLeaderManager
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    {this.state.isAddDocument && (
                        <ModalAddDocument
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
   leaderManager: state.leaderManager,
   client: state.client,
   state: state.state
});

const connected = connect(mapStateToProps, {
    getLeaderManagerRequesting,
    changeStateLeaderManagerRequesting,
    statesProviderRequesting,
    searchLeaderManagerRequesting,
    setFocusLeaderManager,
    deleteDocumentProviderRequesting,
    getCountryRequesting,
    getCategoryRequesting,
    getSubcategoryRequesting,
    deleteDeviceLeaderManagerRequesting
})(LeaderManagerComponent);

export default connected;
