import {
    CREATE_DOCUMENT_LEADER_MANAGER_ERROR,
    CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING, CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS,
    CREATE_DOCUMENT_PROVIDER_ERROR,
    CREATE_DOCUMENT_PROVIDER_REQUESTING, CREATE_DOCUMENT_PROVIDER_SUCCESS,
    DELETE_DOCUMENT_PROVIDER_ERROR,
    DELETE_DOCUMENT_PROVIDER_REQUESTING,
    DELETE_DOCUMENT_PROVIDER_SUCCESS,
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    providers: [],
    provider: [],
    deleteDocument: 0,
    focusProvider: 0,
    focusLeaderManager: 0,
    leaderManager: [],
    leaderManagers: [],
    products: [],
    product: [],
    focusProduct: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_DOCUMENT_PROVIDER_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case CREATE_DOCUMENT_PROVIDER_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                providers: state.providers.map((provider) => provider.id === state.focusProvider ?
                                                action.provider : provider),
                provider: state.provider.map((provider) => provider.id === state.focusProvider ?
                                                action.provider : provider)
            };
        case CREATE_DOCUMENT_PROVIDER_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case DELETE_DOCUMENT_PROVIDER_REQUESTING:
            return{
                ...state,
                deleteDocument: action.documentId
            };
        case DELETE_DOCUMENT_PROVIDER_SUCCESS:
            return{
                ...state,
                providers: state.providers.map(providerItem => {
                    providerItem.documentos.data.map((document, index) => {
                        if(document.id === state.deleteDocument){
                            return providerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return providerItem;
                }),
                provider: state.provider.map(providerItem => {
                    providerItem.documentos.data.map((document, index) => {
                        if(document.id === state.deleteDocument){
                            return providerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return providerItem;
                }),
                leaderManagers: state.leaderManagers.map(leaderManagerItem => {
                    leaderManagerItem.documentos.data.map((document, index) => {
                        if(document.id === state.deleteDocument){
                            return leaderManagerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return leaderManagerItem;
                }),
                leaderManager: state.leaderManager.map(leaderManagerItem => {
                    leaderManagerItem.documentos.data.map((document, index) => {
                        if(document.id === state.deleteDocument){
                            return leaderManagerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return leaderManagerItem;
                })
            };
        case DELETE_DOCUMENT_PROVIDER_ERROR:
            return{
                ...state,
            };
        case CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === state.focusLeaderManager ?
                    action.leaderManager : leaderManager),
                leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === state.focusLeaderManager ?
                    action.leaderManager : leaderManager),
            };
        case CREATE_DOCUMENT_LEADER_MANAGER_ERROR:
            return{
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;
