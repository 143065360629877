import {UNIT_MEASURES_GET_ERROR, UNIT_MEASURES_GET_REQUESTING, UNIT_MEASURES_GET_SUCCESS} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    unitMeasures: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UNIT_MEASURES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
            };
        case UNIT_MEASURES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                unitMeasures: action.unitMeasures,
            };
        case UNIT_MEASURES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        default :
            return state;
    }
};

export default reducer