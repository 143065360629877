import React from 'react';
import {reduxForm, Field} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {updateRateRequesting} from "../../../../redux/rate/actions";
import {ErrorComponent} from "../../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class RowPriceInside extends React.Component {


    constructor(props) {
        super(props);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {rate} = this.props;

        if (nextProps.rate.error !== '' && nextProps.rate.error !== rate.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.rate.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if (nextProps.rate.successChange !== rate.successChange) {

            toastr.success('Valores cambiados', 'Se han cambiado los valores del servicio de diseño.');
        }
    }

    handleAction = (values) => {
        const {client, handleEditable, updateRateRequesting, rate, formConfig} = this.props;
        updateRateRequesting(rate, client.token, {...values, ...formConfig.values});
        handleEditable('priceInside', false);
    };

    render() {
        const {handleEditable, handleSubmit, editable} = this.props;

        return (
            <tr>
                <td className="table-config-width">Valor interior</td>
                {editable && (
                    <div>
                        <form onSubmit={handleSubmit(this.handleAction)}>
                            <td>
                                <div className="container-config">
                                    <Field value={''}
                                           disabled={!editable}
                                           type="text"
                                           component={'input'}
                                           placeholder="Digite el valor"
                                           name={'interior'}
                                    />
                                </div>
                            </td>
                            <td>
                                <button
                                    type={'submit'}
                                    className="Btn-item2-table"> Guardar<i
                                    className="icon-pencil styles-icons"/>
                                </button>
                            </td>
                        </form>
                    </div>
                )}
                {!editable && (
                    <div>
                        <td>
                            <div className="container-config">
                                <Field value={''}
                                       disabled={!editable}
                                       type="text"
                                       component={'input'}
                                       placeholder="Digite el valor"
                                       name={'interior'}
                                />
                            </div>
                        </td>
                        <td>
                            <button onClick={() => handleEditable('priceInside', true)}
                                    className="Btn-item-table">Editar<i
                                className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                    </div>
                )}
            </tr>
        );
    }
}

const formed = reduxForm({
    form: 'rowPriceInside',
    enableReinitialize: true
})(RowPriceInside);

const mapStateToProps = state => ({
    client: state.client,
    rate: state.rate,
    initialValues: state.rate.rates,
    formConfig: state.form.filterConfig
});

const connected = connect(mapStateToProps, {
    updateRateRequesting
})(formed);

export default connected;
