import React from 'react';
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import SearchComponent from "../search/search";
import FilterComponent from "../filter/filter";

import {TableEmergency} from "./table/table-emergency";
import {emergencyCallsGet, getEmergencyCallRequesting, searchEmergencyCallRequesting} from "../../redux/emergency/actions";
import {statesEmergencyCallRequesting} from "../../commons/states/actions";
import {NotFound} from "../../commons/errors/notFound";

class EmergencyComponent extends React.Component {

    handleShowModal = (modal) => {
        this.setState({
            isEmergency: modal === 'Emergencia',

        })
    };

    componentWillMount() {
        const {emergencyCallsGet, getEmergencyCallRequesting, client, emergencyCall : {cursor, filter},
            statesEmergencyCallRequesting} = this.props;
        getEmergencyCallRequesting(cursor, filter, client.token);
        statesEmergencyCallRequesting(client.token);
       // emergencyCallsGet('Todos', 1);
    };

    handleGetData = (cursor) => {
        const {getEmergencyCallRequesting, client, emergencyCall} = this.props;
        if(cursor === 1 && emergencyCall.hasMore)
            getEmergencyCallRequesting(emergencyCall.cursor + cursor, emergencyCall.filter, client.token);
        if(cursor === -1 && emergencyCall.hasLess)
            getEmergencyCallRequesting(emergencyCall.cursor + cursor, emergencyCall.filter, client.token);
    };


    handleFilter = (value) => {

        const {getEmergencyCallRequesting, client, emergencyCall} = this.props;
        if (emergencyCall.filter !== value)
            getEmergencyCallRequesting(emergencyCall.cursor, value, client.token);

    };

    handleSearch = (value) => {
        const {searchEmergencyCallRequesting, client, emergencyCall} = this.props;
        if((emergencyCall.search !== value.search) && value.search !== '')
            searchEmergencyCallRequesting(value.search, client.token);
    };

    handleGetCsv = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/emergencycalls/export`, "_self")
    };

    render() {
        const {emergencyCall : {emergencyCalls, search, emergencyCall}, state:{states}} = this.props;
        return (
            <div className="styles-container">
                <SearchComponent
                    type={'Emergencia'}
                    placeholder={'Consultar en servicio'}
                    handleSearch={this.handleSearch.bind(this)}
                >
                    {search !== '' && (
                        emergencyCall.length > 0 ? <TableEmergency
                        emergencyCalls={emergencyCall}
                        />:<NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'Emergencia'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        states={states}
                        getData={this.handleGetData.bind(this)}
                    />
                    {emergencyCalls.length > 0 ? (
                        <TableEmergency
                        handleShowModal={this.handleShowModal.bind(this)}
                        emergencyCalls={emergencyCalls}
                        />
                    ): <NotFound/> }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
//    filter: state.emergencyCalls.filter,
//    emergencyCalls: state.emergencyCalls.emergencyCalls,
    emergencyCall: state.emergencyCall,
    state: state.state
});

const connected = connect(mapStateToProps, {
   // emergencyCallsGet,
    getEmergencyCallRequesting,
    statesEmergencyCallRequesting,
    searchEmergencyCallRequesting
})(EmergencyComponent);

const formed = reduxForm({
    form: 'EmergencyComponent'
})(connected);

export default formed