import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    statesSuppliesGetError,
    statesSuppliesGetSuccess,
    suppliesGetError,
    suppliesGetSuccess,
    suppliesSearchError,
    suppliesSearchSuccess,
    supplyDeleteError,
    supplyDeleteSuccess,
    supplyPostError,
    supplyPostSuccess,
    supplyPutError,
    supplyPutSuccess
} from "./actions";
import {
    STATES_SUPPLIES_GET_REQUESTING,
    SUPPLIES_GET_REQUESTING,
    SUPPLIES_SEARCH_REQUESTING,
    SUPPLY_DELETE_REQUESTING,
    SUPPLY_POST_REQUESTING,
    SUPPLY_PUT_REQUESTING
} from "./constants";

const supplyUrl = `${process.env.REACT_APP_API_URL}/api/supplies`;

const suppliesGetApi = (token, filter, paginate) => {
    const url = `${supplyUrl}/filter/${filter}?page=${paginate}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* suppliesGetFlow(action) {
    try {
        const {token, filter, paginate} = action;
        const supplies = yield call(suppliesGetApi, token, filter, paginate);
        yield put(suppliesGetSuccess(supplies));
    } catch (error) {
        yield put(suppliesGetError(error));
    }
}

const supplyPostApi = (token, supply) => {
    let body = new FormData();
    body.append('nombre', supply.nombre || '');
    body.append('referencia', supply.referencia || '');
    body.append('descripcion', supply.descripcion || '');
    body.append('precio', supply.precio || '');
    body.append('precio_gerente_lider', supply.precio_gerente_lider || '');
    body.append('precio_descuento', supply.precio_descuento || '');
    body.append('tiempo_envio', supply.tiempo_envio || '');
    body.append('precio_envio', supply.precio_envio || '');
    body.append('inventario_disponible', supply.inventario_disponible || '');
    body.append('limite_inventario', supply.limite_inventario || '');
    body.append('medidas', supply.medidas || '');
    body.append('unidad_medida', supply.unidad_medida || '');
    body.append('marca', supply.marca || '');
    body.append('proveedor', supply.proveedor.hasOwnProperty('id') ? supply.proveedor.id : supply.proveedor || '');
    if (supply.foto) {
        body.append('foto', supply.foto[0].file || '');
    }

    return fetch(`${supplyUrl}/${'post'}/${'administrator'}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* supplyPostFlow(action) {
    try {
        const {token, supply} = action;
        const supplyPost = yield call(supplyPostApi, token, supply);
        yield put(supplyPostSuccess(supplyPost));
    } catch (error) {
        yield put(supplyPostError(error));
    }
}

const supplyPutApi = (token, supply) => {
    let body = new FormData();
    body.append('_method', 'put');
    body.append('nombre', supply.nombre || '');
    body.append('referencia', supply.referencia || '');
    body.append('descripcion', supply.descripcion || '');
    body.append('precio', supply.precio || '');
    body.append('precio_gerente_lider', supply.precio_gerente_lider || '');
    body.append('precio_descuento', supply.precio_descuento || '');
    body.append('tiempo_envio', supply.tiempo_envio || '');
    body.append('precio_envio', supply.precio_envio || '');
    body.append('inventario_disponible', supply.inventario_disponible || '');
    body.append('limite_inventario', supply.limite_inventario || '');
    body.append('medidas', supply.medidas || '');
    body.append('unidad_medida', supply.unidad_medida || '');
    body.append('marca', supply.marca || '');
    body.append('proveedor', supply.proveedor.hasOwnProperty('id') ? supply.proveedor.id : supply.proveedor || '');

    if (supply.foto !== undefined && supply.foto.length > 0) {
        body.append('foto', supply.foto[0].file || supply.foto);
    }

    return fetch(`${supplyUrl}/${supply.id}/${'administrator'}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token,
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* supplyPutFlow(action) {
    try {
        const {token, supply} = action;
        const supplyPut = yield call(supplyPutApi, token, supply);
        yield put(supplyPutSuccess(supplyPut));
    } catch (error) {
        yield put(supplyPutError(error));
    }
}

const supplyDeleteApi = (token, supply) => {
    return fetch(`${supplyUrl}/${supply.id}`, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer' + token,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* supplyDeleteFlow(action) {
    try {
        const {token, supply} = action;
        const supplyDelete = yield call(supplyDeleteApi, token, supply);
        yield put(supplyDeleteSuccess(supplyDelete));
    } catch (error) {
        yield put(supplyDeleteError(error));
    }
}

const suppliesSearchApi = (token, search) => {
    let body = {
        search: search,
    };
    return fetch(`${supplyUrl}${'Search'}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* suppliesSearchFlow(action) {
    try {
        const {token, search} = action;
        const supplies = yield call(suppliesSearchApi, token, search);
        yield put(suppliesSearchSuccess(supplies));
    } catch (error) {
        yield put(suppliesSearchError(error));
    }
}


const statesSuppliesGetApi = (token) => {
    return fetch(`${supplyUrl}States`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token,
        },
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesSuppliesGetFlow(action) {
    try {
        const {token} = action;
        const states = yield call(statesSuppliesGetApi, token);
        yield put(statesSuppliesGetSuccess(states));
    } catch (error) {
        yield put(statesSuppliesGetError(error));
    }
}

function* supplyWatcher() {
    yield all([
        takeEvery(SUPPLIES_GET_REQUESTING, suppliesGetFlow),
        takeEvery(SUPPLY_POST_REQUESTING, supplyPostFlow),
        takeEvery(SUPPLY_PUT_REQUESTING, supplyPutFlow),
        takeEvery(SUPPLY_DELETE_REQUESTING, supplyDeleteFlow),
        takeEvery(SUPPLIES_SEARCH_REQUESTING, suppliesSearchFlow),
        takeEvery(STATES_SUPPLIES_GET_REQUESTING, statesSuppliesGetFlow),
    ])
}

export default supplyWatcher