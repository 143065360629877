import React from 'react';

export default class FilterComponent extends React.Component {

    render() {
        const {states, handleFilter, getData, activeModal, handleGetCsv, type} = this.props;
        return (
            <div className="Btn-general-filter">
                <div className="div-btn-filter"/>
                <div className={activeModal ? 'container-add-direction' : 'container-add-direction-no-add'}>
                    <div className="div-btn-filter">
                        <select name="" onChange={(e) => handleFilter(e.target.value, type)}>
                            <option value="Todos">Todos</option>
                            {states !== undefined && states.length > 0 && states.map((state, index) => (
                                <option key={index} value={state.nombre}>{state.nombre}</option>
                            ))}
                        </select>
                    </div>
                    {activeModal && (
                        <div className="div-btn-add">
                            <button onClick={this.props.handleShowModal.bind(this, type)}
                                    className="Btn-general-add">+
                                Agregar {this.props.type}<i
                                    className="icon-ok styles-icons"/></button>
                        </div>
                    )}
                    <div className="div-btn-add">
                        <button className="Btn-general-add" onClick={() => handleGetCsv(type)}>
                            Descargar csv
                            <i className="icon-folder-open-empty styles-icons"/>
                        </button>
                    </div>
                    <div className="div-btn-direction">
                        <button className="Btn-general-direction" onClick={() => getData(-1, type)}><i
                            className="icon-left-big styles-icons"/>Atrás
                        </button>
                        <button className="Btn-general-direction" onClick={() => getData(1, type)}>Adelante<i
                            className="icon-right-big styles-icons"/></button>
                    </div>
                </div>
            </div>
        )
    }
}
