import React from 'react';
import {reduxForm, Field} from "redux-form";
import connect from "react-redux/es/connect/connect";
import {updateRateMaintenanceRequesting} from "../../../../redux/rate/actions";
import {ErrorComponent} from "../../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";

class RowPriceService extends React.Component {


    constructor(props) {
        super(props);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {rateMaintenance} = this.props;

        if (nextProps.rateMaintenance.error !== '' && nextProps.rateMaintenance.error !== rateMaintenance.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.rateMaintenance.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if (nextProps.rateMaintenance.successChange && !rateMaintenance.successChange) {
            toastr.success('Valor cambiado', 'Se han cambiado los valores de mantenimiento.');
        }
    }

    handleAction = (values) => {
        const {client, handleEditable, updateRateMaintenanceRequesting, rateMaintenance, formConfig} = this.props;
        updateRateMaintenanceRequesting(rateMaintenance, client.token, {...values, ...formConfig.values});
        handleEditable('priceService', false);
    };

    render() {
        const {handleEditable, handleSubmit, editable} = this.props;

        return (
            <tr>
                <td className="table-config-width">Precio servicio</td>
                {editable && (
                    <div>
                        <form onSubmit={handleSubmit(this.handleAction)}>
                            <td>
                                <div className="container-config">
                                    <Field value={''}
                                           disabled={!editable}
                                           type="text"
                                           component={'input'}
                                           placeholder="Digite el valor"
                                           name={'precio_servicio'}
                                    />
                                </div>
                            </td>
                            <td>
                                <button
                                    type={'submit'}
                                    className="Btn-item2-table"> Guardar<i
                                    className="icon-pencil styles-icons"/>
                                </button>
                            </td>
                        </form>
                    </div>
                )}
                {!editable && (
                    <div>
                        <td>
                            <div className="container-config">
                                <Field value={''}
                                       disabled={!editable}
                                       type="text"
                                       component={'input'}
                                       placeholder="Digite el valor"
                                       name={'precio_servicio'}
                                />
                            </div>
                        </td>
                        <td>
                            <button onClick={() => handleEditable('priceService', true)}
                                    className="Btn-item-table">Editar<i
                                className="icon-pencil styles-icons"/>
                            </button>
                        </td>
                    </div>
                )}
            </tr>
        );
    }
}

const formed = reduxForm({
    form: 'RowPriceService',
    enableReinitialize: true
})(RowPriceService);

const mapStateToProps = state => ({
    client: state.client,
    rateMaintenance: state.rate,
    initialValues: state.rate.rateMaintenance,
    formConfig: state.form.filterConfig
});

const connected = connect(mapStateToProps, {
    updateRateMaintenanceRequesting
})(formed);

export default connected;