import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {getCategoryRequesting} from "../../redux/category/actions";

class CategoryComponent extends React.Component{

    componentWillMount(){
        const {
            client: {token},
            getCategoryRequesting,
        } = this.props;
        getCategoryRequesting(token);
    }

    render(){
        const {
            category: {categories},
            InitialCategory
        } = this.props;
        return(
            <Field name="categoria" type="select" className="select-category" component="select">
                {InitialCategory ? <option value={InitialCategory.id}>{InitialCategory.nombre}</option> :
                    <option>...Seleccione</option>}
                {categories.length > 0 && categories.map((category, index)=>{
                    if (InitialCategory===undefined)
                        return <option key={index} value={category.nombre}>{category.nombre}</option>
                    if (category.nombre !== InitialCategory.nombre)
                        return <option key={index} value={category.nombre}>{category.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    category: state.category,
});

const connected = connect(mapStateToProps,{
    getCategoryRequesting
})(CategoryComponent);

export default connected;