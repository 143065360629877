import React from 'react';
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {toastr} from "react-redux-toastr";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {capacityPutRequesting, capacityFocusClean} from "../../../redux/product/actions";

class ModalEditCapacity extends React.Component {

    constructor(props) {
        super(props);
        this.buttonLadda = React.createRef();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {
            product: {error, capacitySuccess}
        } = this.props;

        let button = null;

        if (this.buttonLadda.current !== null)
            button = Ladda.create(this.buttonLadda.current);

        if (nextProps.product.capacityRequesting)
            button.start();

        if (nextProps.product.error !== '' && nextProps.product.error !== error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Recuerda', toastOptions);
            button.stop();
        }

        if (nextProps.product.capacitySuccess !== capacitySuccess) {
            toastr.success('Capacidad actualizada', 'La capacidad fue actualizada correctamente.');
            button.stop();
            this.props.hidden();
        }
    }

    handleAction = (values) => {
        const {client: {token}, capacityPutRequesting} = this.props;
        capacityPutRequesting(token, values);
    };

    componentDidMount() {
        const {capacityFocusClean} = this.props;
        capacityFocusClean();
    }

    render() {
        const {
            handleSubmit,
            product: {capacityRequesting},
        } = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Editar capacidad</h1>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Capacidad:</label>
                            <Field name={'capacidad'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Precio usuario:</label>
                            <Field name={'precio_usuario'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Precio gerente lider:</label>
                            <Field name={'precio_gerente_lider'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="button-container">
                            <div className="ladda-button btn-container" data-style="expand-left">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal" disabled={capacityRequesting} ref={this.buttonLadda}>
                                    <span style={{color: 'white'}}>Editar</span>
                                    <i className="icon-ok styles-icons" style={{color: 'white'}}/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'EditCapacity',
})(ModalEditCapacity);

const mapStateToProps = state => ({
    client: state.client,
    product: state.product,
    initialValues: state.product.capacity,
});

const connected = connect(mapStateToProps, {
    capacityPutRequesting,
    capacityFocusClean
})(formed);

export default connected