import {ALL_CITIES_GET_ERROR, ALL_CITIES_GET_REQUESTING, ALL_CITIES_GET_SUCCESS} from "./constants";

export const allCitiesGetRequesting = (token) => ({
    type: ALL_CITIES_GET_REQUESTING,
    token,
});

export const allCitiesGetSuccess = (allCities) => ({
    type: ALL_CITIES_GET_SUCCESS,
    allCities,
});

export const allCitiesGetError = (error) => ({
    type: ALL_CITIES_GET_ERROR,
    error,
});

