import {call, put, takeLatest, all} from "redux-saga/effects";
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {EMERGENCY_CALLS_GET, GET_EMERGENCY_CALL_REQUESTING, SEARCH_EMERGENCY_CALL_REQUESTING,} from "./constants";
import {
    emergencyCallsGetSuccess,
    emergencyCallsGetError,
    getEmergencyCallSuccess,
    getEmergencyCallError,
    searchEmergencyCallSuccess,
    searchEmergencyCallError
} from "./actions";

const header = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('@ingetem:admin')
};

const emergencyCallsURL = `${process.env.REACT_APP_API_URL}/api/emergencyCalls`;

function emergencyCallsGetApi(filter, paginate) {
    const url = `${emergencyCallsURL}/filter/${filter}?page=${paginate}`;
    return fetch(url, {
        method: 'GET',
        headers: header,
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0) {
                return json.data.data;
            } else {
                throw "No hay mas datos";
            }
        })
        .catch((error) => {
            throw error
        })
}

function* emergencyCallsGetFLow(action) {
    try {
        const {filter, paginate} = action;
        const getEmergencyCalls = yield call(emergencyCallsGetApi, filter, paginate);
        yield put(emergencyCallsGetSuccess(getEmergencyCalls, paginate));
    } catch (error) {
        yield put(emergencyCallsGetError(error));
    }
}

const getEmergencyCallUrl = `${process.env.REACT_APP_API_URL}/api/emergencycalls/filter`;
const searchEmergencyCallUrl = `${process.env.REACT_APP_API_URL}/api/emergencycalls/search`;

const getEmergencyCall = (cursor, filter, token) => {

    return fetch(`${getEmergencyCallUrl}/${filter}?page=${cursor}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 400)
                throw  json.data.data;
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getEmergencyCallFlow(action) {
    try {
        const {cursor, filter, token} = action;
        const emergencyCalls = yield call(getEmergencyCall, cursor, filter, token);
        yield put(getEmergencyCallSuccess(emergencyCalls));
    } catch (error) {
        yield put(getEmergencyCallError(error));
    }
}

const searchEmergencyCallAPI = (search, token) => {
    let body = {
        search: search
    };

    return fetch(searchEmergencyCallUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* searchEmergencyCallFlow(action) {
    try {
        const {search, token} = action;
        const emergencyCall = yield call(searchEmergencyCallAPI, search, token);
        yield put(searchEmergencyCallSuccess(emergencyCall));
    } catch (error) {
        yield put(searchEmergencyCallError(error));
    }
}

function* emergencyCallsWatcher() {
    yield all([
        takeLatest(EMERGENCY_CALLS_GET, emergencyCallsGetFLow),
        takeLatest(GET_EMERGENCY_CALL_REQUESTING, getEmergencyCallFlow),
        takeLatest(SEARCH_EMERGENCY_CALL_REQUESTING, searchEmergencyCallFlow)
    ])
}

export default emergencyCallsWatcher;