import React from 'react';
import FormattedCurrency from 'react-formatted-currency';

export class TableEmergency extends React.Component {


    render() {
        const {emergencyCalls} = this.props;
        return (
            <div className="Conteiner-table">
                <table className="table-emergency">
                    <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>Fecha del servicio</th>
                        <th>Pais</th>
                        <th>Ciudad</th>
                        <th>Dirección</th>
                        <th>Observación de la dirección</th>
                        <th>Descripción del servicio</th>
                        <th>Calificación</th>
                        <th>Categoria</th>
                        <th>Proyecto</th>
                        <th>Nombre del cliente</th>
                        <th>Telefono del cliente</th>
                        <th>Correo electronico del cliente</th>
                        <th>Nombre del gerente lider</th>
                        <th>Telefono del gerente lider</th>
                        <th>Comentario del gerente lider</th>
                        <th>Puntaje gerente lider</th>
                        <th>Fecha que se acepto el servicio</th>
                        <th>Fecha de cierre del servicio</th>
                        <th>Total de visita</th>
                        <th>Total para Ingetem</th>
                        <th>Total para el gerente lider</th>
                        <th>Metodo de pago</th>
                        <th>Estado</th>
                    </tr>
                    </thead>
                    <tbody>
                    {emergencyCalls.map((emergencyCall, index) => {

                        let className = '';
                        if (emergencyCall && emergencyCall.estado.nombre === 'En Proceso') {
                            let str = emergencyCall.estado.nombre.split(" ");
                            className = `state_${str[0]}_${str[1]}`;
                        }
                        return <tr key={index}>
                            <td>{emergencyCall.id}</td>
                            <td>{emergencyCall.fecha_servicio}</td>
                            <td>{emergencyCall.pais.nombre}</td>
                            <td>{emergencyCall.ciudad.nombre}</td>
                            <td>{emergencyCall.direccion}</td>
                            <td>{emergencyCall.observacion_direccion}</td>
                            <td>{emergencyCall.descripcion}</td>
                            <td>{emergencyCall.calificacion}</td>
                            <td>{emergencyCall.categoria.nombre}</td>
                            <td>{emergencyCall.proyecto.nombre}</td>
                            <td>{emergencyCall.usuario.nombre}</td>
                            <td>{emergencyCall.usuario.telefono}</td>
                            <td>{emergencyCall.usuario.correo_electronico}</td>
                            <td>{emergencyCall.gerente_lider.nombre}</td>
                            <td>{emergencyCall.gerente_lider.telefono}</td>
                            <td>{emergencyCall.comentario_gerente_lider}</td>
                            <td>{emergencyCall.calificacion}</td>
                            <td>{emergencyCall.fecha_aceptacion}</td>
                            <td>{emergencyCall.fecha_cierre}</td>
                            <td>
                                <FormattedCurrency
                                    amount={emergencyCall.total_visita}
                                    currency={emergencyCall.pais.moneda}
                                    symbol={emergencyCall.pais.simbolo_moneda}
                                    locale={emergencyCall.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>
                                <FormattedCurrency
                                    amount={emergencyCall.total_visita_roca}
                                    currency={emergencyCall.pais.moneda}
                                    symbol={emergencyCall.pais.simbolo_moneda}
                                    locale={emergencyCall.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>
                                <FormattedCurrency
                                    amount={emergencyCall.total_visita_leader_manager}
                                    currency={emergencyCall.pais.moneda}
                                    symbol={emergencyCall.pais.simbolo_moneda}
                                    locale={emergencyCall.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>{emergencyCall.metodo_pago.nombre}</td>
                            <td>
                                <p className={emergencyCall.estado.nombre !== 'En Proceso' ? 'state_'
                                    + emergencyCall.estado.nombre : className}>{emergencyCall.estado.nombre}
                                </p>
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}