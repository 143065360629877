import React from 'react';
import {FilePond} from "react-filepond";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {setFocusLeaderManager} from "../../../redux/leader-manager/actions";
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {createDocumentLeaderManagerRequesting} from "../../../redux/documents/actions";

const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {

    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action ">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={6}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        />
    );
};

class modalAddDocument extends React.Component {



    handleAction = (values) => {
        const {client, createDocumentLeaderManagerRequesting, leaderManager: {focusLeaderManager} } = this.props;
        createDocumentLeaderManagerRequesting(client.token, focusLeaderManager, values);
    };


    componentWillUpdate(nextProps) {

        const {document} = this.props;

        if(nextProps.document.error !== '' && nextProps.document.error !== document.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.document.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if(nextProps.document.success && !document.success){
            toastr.success('Documento adicionado', 'El Gerente Líder tiene un nuevo documento agregado.' );
            this.props.hidden();
        }
    }

    render() {

        const {
            handleSubmit
        } = this.props;


        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <h1>Agregar documentos al Gerente Líder</h1>
                    <form onSubmit={handleSubmit(this.handleAction)}>

                        <div className="item-modal">
                            <label>Documentos</label>
                            <Field name={'leader_manager_documents'} component={FileInput}/>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i
                                    className="icon-cancel styles-icons"/>
                                </button>

                                <button className="ladda-button Btn-modal" data-style="expand-left"
                                       // ref={this.buttonPhoto}
                                        data-color="green" type={'submit'}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                        <label
                                            style={{color: 'white'}}>Agregar</label>
                                        <i className="icon-ok styles-icons" style={{color: 'white'}}/>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client,
    leaderManager: state.leaderManager,
    document: state.document

});

const connected = connect(mapStateToProps, {
    createDocumentLeaderManagerRequesting,
    setFocusLeaderManager
})(modalAddDocument);

const formed = reduxForm({
    form: 'documentsLeaderManagerModal'
})(connected);


export default formed