import React from 'react';

export class ModalService extends React.Component {


    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>

                    <h1 className="arreglojulian">Agregar Nuevo producto</h1>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <input type="text" className="input input-name"/>
                    </div>

                    <div className="item-modal">
                        <label>Foto: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Descripción: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Precio real: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Precio descuento: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Limite Maximo: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Nombre: </label>
                        <input type="text" className="input input-name"/>
                    </div>

                    <div className="item-modal">
                        <label>Foto: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Descripción: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Precio real: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Precio descuento: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Limite Maximo: </label>
                        <input type="text" className="input input-city"/>
                    </div>
                    <div className="item-modal">
                        <label>Categoria 1: </label>
                        <select name="rol" className="select-rol">
                            <option value="">Categoria 2:</option>
                            <option value="">Categoria 3:</option>
                            <option value="">Categoria 4:</option>
                        </select>
                    </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal">Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Crear producto<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
