import React from 'react';
import {ErrorComponent} from "../../../commons/errors/errors";
import {Field, reduxForm} from "redux-form";
import {toastr} from "react-redux-toastr";
import connect from "react-redux/es/connect/connect";
import {supplyPutRequesting} from "../../../redux/supply/actions";
import PhotoInput from '../../file-inputs/photo';
import UnitMeasureComponent from '../../unitMeasure';
import BrandComponent from '../../brand';
import ProviderComponent from "../../provider";

class ModalEditSupplies extends React.Component {

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {
            supply: {error, successPut},
        } = this.props;

        if (nextProps.supply.error !== '' && nextProps.supply.error !== error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.supply.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }
        if (nextProps.supply.successPut !== successPut) {
            toastr.success('Insumo Actualizado', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            supplyPutRequesting,
        } = this.props;
        supplyPutRequesting(token, values);
    };

    render() {
        const {handleSubmit, initialValues} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel" onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Actualizar insumo</h1>
                    </div>
                    <form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="item-modal">
                            <label>Nombre:</label>
                            <Field name={'nombre'} component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Referencia:</label>
                            <Field name={'referencia'} component={'input'}/>
                        </div>
                        <label>Foto:</label>
                        <Field name={'foto'} component={PhotoInput}/>

                        <div className="item-modal">
                            <label>Descripcion:</label>
                            <Field name={'descripcion'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio:</label>
                            <Field name={'precio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio Gerente Lider:</label>
                            <Field name={'precio_gerente_lider'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio Descuento:</label>
                            <Field name={'precio_descuento'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Dias maximo de entrega:</label>
                            <Field name={'tiempo_envio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio envio:</label>
                            <Field name={'precio_envio'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Cantidad Inventario:</label>
                            <Field name={'inventario_disponible'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>El suministro se deshabilitara cuando el inventario llegue a:</label>
                            <Field name={'limite_inventario'} type={'number'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Medidas:</label>
                            <Field name={'medidas'} component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Unidad de medida:</label>
                            <UnitMeasureComponent
                                InitialUnitMeasure={initialValues.unidad_medida}
                                name={'unidad_medida'}/>
                        </div>

                        <div className="item-modal">
                            <label>Marca:</label>
                            <BrandComponent
                                InitialMarca={initialValues.marca}
                                name={'marca'}/>
                        </div>
                        <div className="item-modal">
                            <label>Proveedor:</label>
                            <ProviderComponent name={'proveedor'} InitialProvider={initialValues.proveedor}/>
                        </div>
                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                                    Cancelar
                                    <i className="icon-cancel styles-icons"/>
                                </button>
                                <button className="Btn-modal">
                                    Aprobar
                                    <i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'editSupply'
})(ModalEditSupplies);

const mapStateToProps = state => ({
    client: state.client,
    supply: state.supply,
    initialValues: state.supply.supply,
});

const connected = connect(mapStateToProps, {
    supplyPutRequesting,
})(formed);

export default connected
