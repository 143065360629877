import {GET_SUBCATEGORY_ERROR, GET_SUBCATEGORY_REQUESTING, GET_SUBCATEGORY_SUCCESS} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    subcategories: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBCATEGORY_REQUESTING:
            return{
                ...state,
                requesting: true
            };
        case GET_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                subcategories: action.subcategories
            };
        case GET_SUBCATEGORY_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;