import React from 'react';
import Lightbox from 'react-images';
import FormattedCurrency from 'react-formatted-currency';
import {Multiselect} from "react-widgets";

export class TableProduct extends React.Component {

    state = {
        lightboxIsOpen: false,
        photos: [],
        currentImage: 0,
    };

    handleChangePhotos = (product) => {
        let photos = [];
        if (product.fotos.data.length > 0) {
            product.fotos.data.map(photo => {
                photos.push({src: photo.src})
            });
        }

        this.setState({
            lightboxIsOpen: true,
            photos: photos,
            photosCurrent: product.fotos,
        })
    };

    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    handleEliminate = () => {
        const {handleDeleteImage} = this.props;
        handleDeleteImage(this.state.photosCurrent.data[this.state.currentImage].id);
        this.closeLightbox();
    };

    handleAttachShippingType = (product, shippingType) => {
        const {handleAttachShipping} = this.props;
        handleAttachShipping(product, shippingType);
    };

    handlesDetachShippingType = (product, shippingType) => {
        const {handleDetachShipping} = this.props;
        handleDetachShipping(product, shippingType);
    };

    render() {
        const {products, handleChangeState, shippingTypes, handleDeleteDocument} = this.props;
        return (
            <div className="Conteiner-table">
                <Lightbox
                    images={this.state.photos}
                    isOpen={this.state.lightboxIsOpen}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    onClose={this.closeLightbox}
                    imageCountSeparator={' / '}
                    currentImage={this.state.currentImage}
                    backdropClosesModal={true}
                    customControls={[
                        <i className="icon-trash"
                           style={{color: 'white', fontSize: 28, marginLeft: 25, cursor: 'pointer'}}
                           onClick={() => this.handleEliminate()}
                        />]}
                />
                <table className="table-product">
                    <thead>
                    <tr>
                        <th>Codigo</th>
                        <th>Nombre</th>
                        <th>Referencia</th>
                        <th>Descripción</th>
                        <th>Medidas</th>
                        {/*<th>Precio</th>*/}
                        {/*<th>Precio gerente lider</th>*/}
                        <th>Valor de envío</th>
                        <th>Dias de entrega</th>
                        {/*<th>Precio descuento</th>*/}
                        <th>Inventario disponible</th>
                        <th>Limite de inventario</th>
                        <th>Marca</th>
                        <th>Categoria</th>
                        <th>Projecto</th>
                        {/*<th>Capacidades</th>*/}
                        {/*<th>Precios usuario por capacidad</th>*/}
                        {/*<th>Precios gerentes lideres por capacidad</th>*/}
                        <th>Capacidades</th>
                        <th>Fotos</th>
                        <th>Documentos</th>
                        <th>Agregar capacidad</th>
                        <th>Estado</th>
                        <th>Accion</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.length > 0 && products.map((product, index) => (
                        <tr key={index}>
                            <td>{product.id}</td>
                            <td>{product.nombre}</td>
                            <td>{product.referencia}</td>
                            <td>{product.descripcion}</td>
                            <td>{product.medidas}</td>
                            {/*<td>*/}
                                {/*<FormattedCurrency*/}
                                    {/*amount={product.precio}*/}
                                    {/*currency={product.proveedor.datos_pais.moneda}*/}
                                    {/*symbol={product.proveedor.datos_pais.simbolo_moneda}*/}
                                    {/*locale={product.proveedor.datos_pais.ubicacion}*/}
                                    {/*parts={['code', 'number', 'symbol']}*/}
                                    {/*render={({symbol, number}) => {*/}
                                        {/*return `${symbol} ${number}`*/}
                                    {/*}}*/}
                                {/*/>*/}
                            {/*</td>*/}
                            {/*<td>*/}
                                {/*<FormattedCurrency*/}
                                    {/*amount={product.precio_gerente_lider}*/}
                                    {/*currency={product.proveedor.datos_pais.moneda}*/}
                                    {/*symbol={product.proveedor.datos_pais.simbolo_moneda}*/}
                                    {/*locale={product.proveedor.datos_pais.ubicacion}*/}
                                    {/*parts={['code', 'number', 'symbol']}*/}
                                    {/*render={({symbol, number}) => {*/}
                                        {/*return `${symbol} ${number}`*/}
                                    {/*}}*/}
                                {/*/>*/}
                            {/*</td>*/}
                            <td>
                                <FormattedCurrency
                                    amount={product.precio_envio}
                                    currency={product.proveedor.datos_pais.moneda}
                                    symbol={product.proveedor.datos_pais.simbolo_moneda}
                                    locale={product.proveedor.datos_pais.ubicacion}
                                    parts={['code', 'number', 'symbol']}
                                    render={({symbol, number}) => {
                                        return `${symbol} ${number}`
                                    }}
                                />
                            </td>
                            <td>{product.tiempo_envio} días maximo</td>
                            {/*<td>*/}
                                {/*<FormattedCurrency*/}
                                    {/*amount={product.precio_descuento}*/}
                                    {/*currency={product.proveedor.datos_pais.moneda}*/}
                                    {/*symbol={product.proveedor.datos_pais.simbolo_moneda}*/}
                                    {/*locale={product.proveedor.datos_pais.ubicacion}*/}
                                    {/*parts={['code', 'number', 'symbol']}*/}
                                    {/*render={({symbol, number}) => {*/}
                                        {/*return `${symbol} ${number}`*/}
                                    {/*}}*/}
                                {/*/>*/}
                            {/*</td>*/}
                            <td>{product.inventario_disponible}</td>
                            <td>{product.limite_inventario}</td>
                            <td>{product.marca}</td>
                            <td>{product.categoria}</td>
                            <td>{product.proyecto}</td>
                            {/*<td>*/}
                                {/*{product.capacidades.data.length > 0 && product.capacidades.data.map((capacity, index) => (*/}
                                    {/*<div style={{display: 'flex'}} key={index}>*/}
                                        {/*<li style={{width: '100%', textAlign: 'left', cursor: 'pointer'}}*/}
                                            {/*onClick={() => this.props.focusCapacity(capacity)}>*/}
                                            {/*{capacity.capacidad}*/}
                                        {/*</li>*/}
                                        {/*<i style={{cursor: 'pointer'}} className="icon-cancel" onClick={() => this.props.deleteCapacity(capacity)}/>*/}
                                    {/*</div>*/}
                                {/*))}*/}
                                {/*{product.capacidades.data.length === 0 && (*/}
                                    {/*<p>Sin capacidades</p>*/}
                                {/*)}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                                {/*{product.capacidades.data.length > 0 && product.capacidades.data.map((capacity, index) => (*/}
                                    {/*<li style={{textAlign: 'left'}} key={index}>*/}
                                        {/*<FormattedCurrency*/}
                                            {/*amount={capacity.precio_usuario}*/}
                                            {/*currency={product.proveedor.datos_pais.moneda}*/}
                                            {/*symbol={product.proveedor.datos_pais.simbolo_moneda}*/}
                                            {/*locale={product.proveedor.datos_pais.ubicacion}*/}
                                            {/*parts={['code', 'number', 'symbol']}*/}
                                            {/*render={({symbol, number}) => {*/}
                                                {/*return `${symbol} ${number}`*/}
                                            {/*}}*/}
                                        {/*/>*/}
                                    {/*</li>*/}
                                {/*))}*/}
                                {/*{product.capacidades.data.length === 0 && (*/}
                                    {/*<p>Sin capacidades</p>*/}
                                {/*)}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                                {/*{product.capacidades.data.length > 0 && product.capacidades.data.map((capacity, index) => (*/}
                                    {/*<li style={{textAlign: 'left'}} key={index}>*/}
                                        {/*<FormattedCurrency*/}
                                            {/*amount={capacity.precio_gerente_lider}*/}
                                            {/*currency={product.proveedor.datos_pais.moneda}*/}
                                            {/*symbol={product.proveedor.datos_pais.simbolo_moneda}*/}
                                            {/*locale={product.proveedor.datos_pais.ubicacion}*/}
                                            {/*parts={['code', 'number', 'symbol']}*/}
                                            {/*render={({symbol, number}) => {*/}
                                                {/*return `${symbol} ${number}`*/}
                                            {/*}}*/}
                                        {/*/>*/}
                                    {/*</li>*/}
                                {/*))}*/}
                                {/*{product.capacidades.data.length === 0 && (*/}
                                    {/*<p>Sin capacidades</p>*/}
                                {/*)}*/}
                            {/*</td>*/}
                            <td>
                                {product.capacidades.data.length > 0 && (
                                    <button className="Btn-item-table"
                                            onClick={() => this.props.viewCapacities(product)}>
                                        Ver capacidades
                                        <i className="icon-pencil styles-icons"/>
                                    </button>
                                )}
                                {product.capacidades.data.length === 0 && (
                                    <p>Sin capacidades</p>
                                )}
                            </td>
                            <td>
                                {product.fotos.data.length > 0 && (
                                    <button className="Btn-item-table"
                                            onClick={() => this.handleChangePhotos(product)}>
                                        Ver fotos
                                        <i className="icon-pencil styles-icons"/>
                                    </button>
                                )}
                                {product.fotos.data.length === 0 && (
                                    <p>Sin fotos</p>
                                )}
                            </td>

                            <td>
                                {product.documentos.data.length > 0 && product.documentos.data.map((document, index) => (
                                    <a key={index} target="_blank" href={document.src} title={document.nombre}>
                                        <button className="Btn-item-table">
                                            {document.nombre}
                                        </button>
                                    </a>
                                ))}
                                {product.documentos.data.length === 0 && (
                                    <p>Sin documentos</p>
                                )}
                            </td>

                            <td>
                                <button className={'Btn-item-table'}
                                        onClick={() => this.props.addCapacity(product)}>
                                    Agregar
                                    <i className={'icon-pencil styles-icons'}/>
                                </button>
                            </td>

                            <td>
                                <input className="style-checkbox" type="checkbox" readOnly={'readOnly'}
                                       onClick={() => handleChangeState(product.id)}
                                       checked={product.estado === 'Habilitado'}/>
                                <p className={product.estado === 'Habilitado'
                                    ? "style-checkbox-off"
                                    : "style-checkbox-on"}>
                                    {product.estado}
                                </p>
                            </td>

                            <td>
                                <button className="Btn-item-table"
                                        style={{backgroundColor: 'orange', borderColor: 'orange'}}
                                        onClick={this.props.handleShowModal.bind(this, 'editar producto', product.id, product)}>Editar
                                    <i className="icon-pencil styles-icons"/></button>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}