import React from 'react';
import FilterConfigEmergencyCallComponent from "../../filter/filter-config-emergency-call";
import TableEmergencyValue from "../table/table-emergency-value";

export class ConfigurationComponentEmergency
    extends React.Component {

    state = {
        isAddBrand: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isAddBrand: modal === 'agregar marca',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isAddBrand: false,
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

// *******************************

    render() {
        return (
            <div className="styles-container">
                <div className="conteiner-page-sector1">
                    <div className="styles-title-page-h1">
                        <h1>Configuracíon de llamado de emergencia</h1>
                        <FilterConfigEmergencyCallComponent
                            type={'Gerente lider'}
                            handleShowModal={this.handleShowModal.bind(this)}
                        />
                    </div>
                    <TableEmergencyValue/>
                </div>
            </div>
        )
    }
}
