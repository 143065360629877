import React from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
);

const PhotoInput = ({input, name, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            allowFileTypeValidation={true}
            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action ">Busca aquí</span>'}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            imagePreviewHeight={100}
            imageCropAspectRatio={'1:1'}
            imageResizeTargetWidth={200}
            imageResizeTargetHeight={200}
            styleLoadIndicatorPosition={'center bottom'}
            styleProgressIndicatorPosition={'center bottom'}
            styleButtonRemoveItemPosition={'center bottom'}
            styleButtonProcessItemPosition={'center bottom'}
            {...input}
            {...rest}
        />
    )
};

export default PhotoInput;