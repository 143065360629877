import React from 'react';
import {ErrorComponent} from "../../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import {Field, reduxForm} from 'redux-form';
import {updateBrandRequesting} from "../../../redux/brand/actions";
import connect from "react-redux/es/connect/connect";
//***********import upload files****************
import {FilePond, File, registerPlugin} from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import iconftoBrand from "../../../assets/img/logo-brand.jpg";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
//***********import upload files****************

const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {
    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={1}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

class ModalEditBrands extends React.Component {

    state={files1:[]};

    componentWillUpdate(nextProps){
        const {brand} = this.props;

        if(nextProps.brand.error !== '' && nextProps.brand.error !== brand.error){
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.brand.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }

        if(nextProps.brand.success && !brand.success){
            toastr.success('Marca actualizada', 'Se ha actualizado la marca correctamente' );
            this.props.hidden();
        }
    };

    handleAction = (values) => {
        const {client, updateBrandRequesting, brand : {focusBrand}} = this.props;
        updateBrandRequesting(values, client.token, focusBrand);
    };

    render() {

        const {handleSubmit, initialValues} = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Cambiar Marca</h1>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                    <div className="item-modal">
                        <label>Nombre de la marca: </label>
                        <Field name={'nombre'} type="text" className="input input-city" component={'input'}/>
                    </div>
                        <div style={{margin: '10px 0'}}>
                            <img src={initialValues.logo} alt="" style={{width: 140, height: 140, borderRadius: 12}}/>
                        </div>
                        <div className="item-modal">
                            <label>Cambiar logo: </label>
                            <Field name={'logo'} component={FileInput}/>
                        </div>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-item-edit-tabla">Actualizar marca<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
   form: 'updateBrandModal'
})(ModalEditBrands);

const mapStateToProps = state => ({
    client: state.client,
    brand: state.brand,
    initialValues: state.brand.brandDataFocus
});

const connected = connect(mapStateToProps, {
    updateBrandRequesting
})(formed);

export default connected;
