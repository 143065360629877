import React from 'react';
import ModalClient from "../modals/edit-client";

export class TableClient
    extends React.Component {


    state = {
        isClient: false,
    };

    handleShowModal = (modal, id, user) => {
        const {focus} = this.props;
        focus(id, user);
        this.setState({
            isClient: modal === 'cliente',
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isClient: false,
// *******************************
        })
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };


    render() {
        const {users, handleStateChangeUser, handleDeleteDevice} = this.props;
        return (
            <div className="Conteiner-table">
                {this.state.isClient && (
                    <ModalClient
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <table className="table-client">
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Cedula</th>
                        <th>Ciudad</th>
                        <th>Dirección</th>
                        <th>Celular</th>
                        <th>Correo</th>
                        <th>Numero de servicios solicitados</th>
                        <th>País</th>
                        <th>Token</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.length > 0 && users.map((user, index) => (
                        <tr key={index}>
                            <td>{user.nombre}</td>
                            <td>{user.identificacion}</td>
                            <td>{user.ciudad && user.ciudad.nombre ? user.ciudad.nombre : "No registra"}</td>
                            <td>{user.direccion}</td>
                            <td>{user.telefono}</td>
                            <td>{user.correo_electronico}</td>
                            <td>{user.cantidad_servicios}</td>
                            <td>{user.pais.data.nombre}</td>
                            <td>{user.token ?
                                <button className="Btn-item-table"
                                        onClick={() => handleDeleteDevice(user.id)}>Eliminar token<i
                                    className="icon-cancel styles-icons"/></button>
                                :
                                <p style={{color: 'gray'}}>
                                    Sin token activo</p>
                            }

                            </td>
                            <td><input className="style-checkbox" type="checkbox"
                                       onClick={() => handleStateChangeUser(user.id)}
                                       checked={user.estado.nombre === 'Habilitado'}
                            />
                                <p className={user.estado.nombre !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>
                                    {user.estado.nombre}</p>
                            </td>
                            <td>
                                <button className="Btn-item-table"
                                        onClick={this.handleShowModal.bind(this, 'cliente', user.id, user)}>Editar<i
                                    className="icon-pencil styles-icons"/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
