import {GET_CATEGORY_ERROR, GET_CATEGORY_REQUESTING, GET_CATEGORY_SUCCESS} from "./constants";


const initialState = {
    requesting: false,
    success: false,
    error: '',
    categories: []
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_CATEGORY_REQUESTING:
            return{
                ...state,
                requesting: true,
            };
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                categories: action.categories
            };
        case GET_CATEGORY_ERROR:
            return {
                ...state,
                requesting: false,
                success: true,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;