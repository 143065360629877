import {
    CHANGE_STATE_BRAND_ERROR,
    CHANGE_STATE_BRAND_REQUESTING, CHANGE_STATE_BRAND_SUCCESS,
    CREATE_BRAND_ERROR,
    CREATE_BRAND_REQUESTING,
    CREATE_BRAND_SUCCESS,
    GET_BRAND_ERROR,
    GET_BRAND_REQUESTING,
    GET_BRAND_SUCCESS, SET_FOCUS_BRAND, UPDATE_BRAND_ERROR, UPDATE_BRAND_REQUESTING, UPDATE_BRAND_SUCCESS
} from "./constants";


export const getBrandRequesting = (token) => ({
    type: GET_BRAND_REQUESTING,
    token
});

export const getBrandSuccess = (brands) => ({
    type: GET_BRAND_SUCCESS,
    brands
});

export const getBrandError = (error) => ({
    type: GET_BRAND_ERROR,
    error
});

export const createBrandRequesting = (brand, token) => ({
    type: CREATE_BRAND_REQUESTING,
    brand,
    token
});

export const createBrandSuccess = (brandCreated) => ({
    type: CREATE_BRAND_SUCCESS,
    brandCreated
});

export const createBrandError = (error) => ({
    type: CREATE_BRAND_ERROR,
    error
});

export const setFocusBrand = (id = 0, brand = {}) => ({
    type: SET_FOCUS_BRAND,
    id,
    brand
});

export const updateBrandRequesting = (brand, token, id) => ({
    type: UPDATE_BRAND_REQUESTING,
    brand,
    token,
    id
});

export const updateBrandSuccess = (brandUpdate) => ({
    type: UPDATE_BRAND_SUCCESS,
    brandUpdate
});

export const updateBrandError = (error) => ({
    type: UPDATE_BRAND_ERROR,
    error
});

export const changeStateBrandRequesting = (brandId, token) => ({
    type: CHANGE_STATE_BRAND_REQUESTING,
    brandId,
    token
});

export const changeStateBrandSuccess = (brand) => ({
    type: CHANGE_STATE_BRAND_SUCCESS,
    brand
});

export const changeStateBrandError = (error) => ({
    type: CHANGE_STATE_BRAND_ERROR,
    error
});