import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from "redux-saga/effects";
import {
    changeStateBrandError,
    changeStateBrandSuccess,
    createBrandError,
    createBrandSuccess,
    getBrandError,
    getBrandSuccess,
    updateBrandError,
    updateBrandSuccess
} from "./actions";
import {
    CHANGE_STATE_BRAND_REQUESTING,
    CREATE_BRAND_REQUESTING,
    GET_BRAND_REQUESTING,
    UPDATE_BRAND_REQUESTING
} from "./constants";

const brandsUrl = `${process.env.REACT_APP_API_URL}/api/brands`;
const stateBrandUrl = `${process.env.REACT_APP_API_URL}/api/states/brand/`;

const getBrandAPI = (token) => {
    return fetch(brandsUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.code === 400)
                throw  json.data.data;
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getBrandFlow(action) {
    try {
        const {token} = action;
        const brands = yield call(getBrandAPI, token);
        yield put(getBrandSuccess(brands));
    } catch (error) {
        yield put(getBrandError(error));
    }
}

const brandCreateAPI = (brand, token) => {
    let body = new FormData();
    body.append('name', brand.name);
    if (brand.logo)
        body.append('logo', brand.logo[0].file || '');

    return fetch(brandsUrl, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })

};

function* createBrandFlow(action) {
    try {
        const {brand, token} = action;
        const brandCreated = yield call(brandCreateAPI, brand, token);
        yield put(createBrandSuccess(brandCreated));
    } catch (error) {
        yield put(createBrandError(error));
    }
}

const updateBrandAPI = (brand, token, id) => {
    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('name', brand.nombre || '');
    if (brand.logo[0].file !== undefined)
        body.append('logo', brand.logo[0].file);

    return fetch(`${brandsUrl}/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateBrandFlow(action) {
    try {
        const {brand, token, id} = action;
        const brandUpdate = yield call(updateBrandAPI, brand, token, id);
        yield put(updateBrandSuccess(brandUpdate));
    } catch (error) {
        yield put(updateBrandError(error));
    }
}

const stateBrandAPI = (brandId, token) => {

    return fetch(stateBrandUrl + brandId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* stateBrandFlow(action) {
    try {
        const {brandId, token} = action;
        const brand = yield call(stateBrandAPI, brandId, token);
        yield put(changeStateBrandSuccess(brand));
    } catch (error) {
        yield put(changeStateBrandError(error));
    }
}

function* brandWatcher() {
    yield all([
        takeEvery(GET_BRAND_REQUESTING, getBrandFlow),
        takeEvery(CREATE_BRAND_REQUESTING, createBrandFlow),
        takeEvery(UPDATE_BRAND_REQUESTING, updateBrandFlow),
        takeEvery(CHANGE_STATE_BRAND_REQUESTING, stateBrandFlow)
    ])
}

export default brandWatcher;
