import React from 'react';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import SearchComponent from "../search/search";
import {TableOders} from "./table/table-oders";
import ModalOrder from "./modals/add-orders";
import ModalEditOrders from "./modals/edit-orders";
import FilterComponent from "../filter/filter";
import {NotFound} from "../../commons/errors/notFound";
import {
    ordersGetRequesting,
    orderSearchRequesting,
    setFocusOrder,
    orderChangeStateRequesting,
    confirmedWireTransferOrderPutRequesting
} from "../../redux/order/actions";
import {statesOrderRequesting} from "../../commons/states/actions";
import ModalDetailOrder from "./modals/modal-detail-orders";
import ModalInfoOrder from "./modals/modal-nfo-orders"
import {ModalConfirmedWireTransfer} from "../service/modals/confirmed-wire-transfer";
import {toastr} from "react-redux-toastr";

class OrderComponent extends React.Component {

    state = {
        isOrder: false,
        isEditOrder: false,
        addState: false,
        infoOrder: false,
        modalTransfer: false,
    };
    handleShowModal = (modal, id = 0, order = {}) => {
        const {setFocusOrder} = this.props;
        if (modal !== 'Ordenes')
            setFocusOrder(id, order);
        this.setState({
            isOrder: modal === 'nueva orden',
            isEditOrder: modal === 'Editar Orden',
            addState: modal === 'Agregar estado',
            infoOrder: modal === 'Informacion orden',
            modalTransfer: modal === 'transfer',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isOrder: false,
            isEditOrder: false,
            addState: false,
            infoOrder: false,
            modalTransfer: false,
        })
    };

    handleChangeState = (id) => {
        const {orderChangeStateRequesting, client} = this.props;
        orderChangeStateRequesting(id, client.token);
    };

    handleSearch = (value) => {
        const {orderSearchRequesting, order} = this.props;
        if ((order.search !== value.search) && value.search !== '')
            orderSearchRequesting(value.search);
    };

    handleFilter = (value) => {
        const {ordersGetRequesting, client, order: {filter}} = this.props;
        if (filter !== value)
            ordersGetRequesting(1, value, client.token);
    };

    handleGetData = (paginate) => {
        const {ordersGetRequesting, client, order} = this.props;
        if (paginate === 1 && order.hasMore)
            ordersGetRequesting(order.paginate + paginate, order.filter, client.token);
        if (paginate === -1 && order.hasLess) {
            ordersGetRequesting(order.paginate + paginate, order.filter, client.token);
        }
    };

    handleGetCsv = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/orders`, "_self")
    };

    componentWillMount() {
        const {
            client,
            ordersGetRequesting,
            order: {paginate, filter},
            statesOrderRequesting
        } = this.props;
        ordersGetRequesting(paginate, filter, client.token);
        statesOrderRequesting(client.token);
    };

    // *******************************
    handleConfirmedWireTransfer = () => {
        const {
            client: {token},
            order: {focusOrder},
            confirmedWireTransferOrderPutRequesting
        } = this.props;
        confirmedWireTransferOrderPutRequesting(token, focusOrder);
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {order: {confirmedWireSuccess}} = this.props;

        if (nextProps.order.confirmedWireSuccess && !confirmedWireSuccess) {
            this.handleHiddenModal();
            toastr.success('Pago confirmado', 'Se ha actualizado la orden.');
        }
    }

    render() {
        const {
            order: {order, search, orders, confirmedWireRequesting},
            state: {statesOrder},
        } = this.props;
        return (
            <div className="styles-container">
                <SearchComponent
                    type={'Ordenes'}
                    placeholder={'Consultar en Ordenes'}
                    handleSearch={this.handleSearch.bind(this)}
                >{search !== '' && (
                    order.length > 0 ? <TableOders
                        handleShowModal={this.handleShowModal.bind(this)}
                        orders={order}
                        changeState={this.handleChangeState.bind(this)}
                    /> : <NotFound/>
                )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        // activeModal={true}
                        type={'nueva orden'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        states={statesOrder}
                        handleFilter={this.handleFilter.bind(this)}
                        getData={this.handleGetData.bind(this)}
                    />
                    {orders.length > 0 ? (
                        <TableOders
                            orders={orders}
                            handleShowModal={this.handleShowModal.bind(this)}
                            changeState={this.handleChangeState.bind(this)}
                        />) : <NotFound/>}
                    {this.state.isOrder && (
                        <ModalOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}/>
                    )}
                    {this.state.isEditOrder && (
                        <ModalEditOrders
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                    {this.state.addState && (
                        <ModalDetailOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            states={statesOrder}
                        />
                    )}
                    {this.state.infoOrder && (
                        <ModalInfoOrder
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}
                    {this.state.modalTransfer && (
                        <ModalConfirmedWireTransfer
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            confirmed={this.handleConfirmedWireTransfer.bind(this)}
                            requesting={confirmedWireRequesting}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    order: state.order,
    state: state.state
});

const connected = connect(mapStateToProps, {
    ordersGetRequesting,
    orderChangeStateRequesting,
    orderSearchRequesting,
    setFocusOrder,
    statesOrderRequesting,
    confirmedWireTransferOrderPutRequesting,
})(OrderComponent);

const formed = reduxForm({
    form: 'OrderComponent'
})(connected);

export default formed;