import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {applyMiddleware, createStore, compose} from 'redux';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {IndexSagas} from './index-sagas';
import ReduxToastr from 'react-redux-toastr';
import IndexReducer from './index-reducer';
import Routes from './commons/routes/routes';
import LoginBack from "./components/login/login";
import PrivateRoute from "./components/privateRoutes/private";
import ServiceDesignComponent from "./components/service/Design";
import ServiceInstallationComponent from "./components/service/Installation";
import ServiceMaintenanceComponent from "./components/service/Maintenance";
import ProductComponent from "./components/providers-product";
import EmergencyComponent from "./components/emergency";
import ClientComponent from "./components/4-Client";
import OrderComponent from "./components/orders";
import LeaderManagerComponent from "./components/5-Leader-manager";
import SupplyComponent from './components/supplies';
import {ConfigurationComponentDesign} from "./components/7-Configuration/pages/page-design";
import {ConfigurationComponentMantenance} from "./components/7-Configuration/pages/page-maintenance";
import {ConfigurationComponentInstallation} from "./components/7-Configuration/pages/page-installation";
import ConfigurationComponentBrands from "./components/7-Configuration/pages/page-brands";
import {ConfigurationComponentEmergency} from "./components/7-Configuration/pages/page-emergency";
import {ConfigurationComponentSupply} from "./components/7-Configuration/pages/page-supply";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-widgets/dist/css/react-widgets.css';

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(IndexSagas);

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <ReduxToastr
                            timeOut={10000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            transitionIn="bounceInDown"
                            transitionOut="bounceOutUp"
                            progressBar
                            closeOnToastrClick
                        />
                        <Routes/>
                        <Switch>
                            <Route exact path="/" component={LoginBack}/>
                            <PrivateRoute path={'/servicio-diseño'} component={ServiceDesignComponent}/>
                            <PrivateRoute path={'/servicio-instalacion'} component={ServiceInstallationComponent}/>
                            <PrivateRoute path={'/servicio-mantenimiento'} component={ServiceMaintenanceComponent}/>
                            <PrivateRoute path={'/proveedores'} component={ProductComponent}/>
                            <PrivateRoute path={'/productos'} component={ProductComponent}/>
                            <PrivateRoute path={'/ordenes'} component={OrderComponent}/>
                            <PrivateRoute path={'/suministros'} component={SupplyComponent}/>
                            <PrivateRoute path={'/llamadas-emergencia'} component={EmergencyComponent}/>
                            <PrivateRoute path={'/clientes'} component={ClientComponent}/>
                            <PrivateRoute path={'/gerentes-lideres'} component={LeaderManagerComponent}/>
                            <Route path={'/configuracion/diseño'} exact component={ConfigurationComponentDesign}/>
                            <Route path={'/configuracion/mantenimiento'} exact
                                   component={ConfigurationComponentMantenance}/>
                            <Route path={'/configuracion/instalacion'} exact
                                   component={ConfigurationComponentInstallation}/>
                            <Route path={'/configuracion/marcas'} exact component={ConfigurationComponentBrands}/>
                            <Route path={'/configuracion/emergencia'} exact
                                   component={ConfigurationComponentEmergency}/>
                            <Route path={'/configuracion/suministro'} exact component={ConfigurationComponentSupply}/>
                            {/*<ConfigurationComponent/>*/}
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;


