export const GET_BRAND_REQUESTING = 'GET_BRAND_REQUESTING';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';

export const CREATE_BRAND_REQUESTING = 'CREATE_BRAND_REQUESTING';
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS';
export const CREATE_BRAND_ERROR = 'CREATE_BRAND_ERROR';

export const SET_FOCUS_BRAND = 'SET_FOCUS_BRAND';

export const UPDATE_BRAND_REQUESTING = 'UPDATE_BRAND_REQUESTING';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const CHANGE_STATE_BRAND_REQUESTING = 'CHANGE_STATE_BRAND_REQUESTING';
export const CHANGE_STATE_BRAND_SUCCESS = 'CHANGE_STATE_BRAND_SUCCESS';
export const CHANGE_STATE_BRAND_ERROR = 'CHANGE_STATE_BRAND_ERROR';