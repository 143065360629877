import {handleApiErrors} from "../../commons/errors/apiErrors";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {
    createDocumentLeaderManagerError,
    createDocumentLeaderManagerSuccess,
    createDocumentProviderError,
    createDocumentProviderSuccess,
    deleteDocumentProviderError,
    deleteDocumentProviderSuccess,
} from "./actions";
import {
    CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING,
    CREATE_DOCUMENT_PROVIDER_REQUESTING,
    DELETE_DOCUMENT_PROVIDER_REQUESTING,
} from "./constants";
import {updateProduct,updateDocumentProduct} from "../product/actions";
import {updateDocument, updateProvider} from "../provider/actions";
import {updateDocumentLeaderManager, updateLeaderManager} from "../leader-manager/actions";



const providerDeleteDocumentURL = `${process.env.REACT_APP_API_URL}/api/documents/`;
const providerCreateDocumentURL = `${process.env.REACT_APP_API_URL}/api/providers`;
const leaderManagerCreateDocumentURL = `${process.env.REACT_APP_API_URL}/api/leadermanagers`;

const providerDeleteDocumentAPI = (documentId, token) => {

    return fetch(providerDeleteDocumentURL + documentId, {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.code === 200)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* providerDeleteDocumentFlow(action) {
    try {
        const {documentId, token} = action;
        const message = yield call(providerDeleteDocumentAPI, documentId, token);
        yield put(deleteDocumentProviderSuccess(message));
        yield put(updateProvider(documentId));
        yield put(updateLeaderManager(documentId));
    }catch (error) {
        yield put(deleteDocumentProviderError(error));
    }
}

const createDocumentAPI = (token, providerId, values) => {

    let body = new FormData();
    if (values.provider_documents) {
        values.provider_documents.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('lenght', values.provider_documents.length);
    }

    return fetch(`${providerCreateDocumentURL}/documents/${providerId}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createDocumentFlow(action) {
    try {
        const {token, providerId, values} = action;
        const provider = yield call(createDocumentAPI, token, providerId, values);
        yield put(createDocumentProviderSuccess(provider));
        yield put(updateDocument(provider))
    }catch (error) {
        yield put(createDocumentProviderError(error));
    }
}


const createDocumentLeaderManagerAPI = (token, leaderManagerId, values) => {
    let body = new FormData();
    if (values.leader_manager_documents) {
        values.leader_manager_documents.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('lenght', values.leader_manager_documents.length);
    }

    return fetch(`${leaderManagerCreateDocumentURL}/documents/${leaderManagerId}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};



function* createDocumentLeaderManagerFlow(action) {
    try {
        const {token, leaderManagerId, values} = action;
        const leaderManager = yield call(createDocumentLeaderManagerAPI, token, leaderManagerId, values);
        yield put(createDocumentLeaderManagerSuccess(leaderManager));
        yield put(updateDocumentLeaderManager(leaderManager))
    }catch (error) {
        yield put(createDocumentLeaderManagerError(error));
    }
}

function* documentWatcher() {
    yield all([
        takeEvery(DELETE_DOCUMENT_PROVIDER_REQUESTING, providerDeleteDocumentFlow),
        takeEvery(CREATE_DOCUMENT_PROVIDER_REQUESTING, createDocumentFlow),
        takeEvery(CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING, createDocumentLeaderManagerFlow),
    ])
}

export default documentWatcher;