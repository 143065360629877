import {
    CREATE_DOCUMENT_LEADER_MANAGER_ERROR,
    CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING, CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS,
    CREATE_DOCUMENT_PROVIDER_ERROR,
    CREATE_DOCUMENT_PROVIDER_REQUESTING,
    CREATE_DOCUMENT_PROVIDER_SUCCESS,
    DELETE_DOCUMENT_PROVIDER_ERROR,
    DELETE_DOCUMENT_PROVIDER_REQUESTING,
    DELETE_DOCUMENT_PROVIDER_SUCCESS,
} from "./constants";

export const createDocumentProviderRequesting = (token, providerId, values) => ({
    type: CREATE_DOCUMENT_PROVIDER_REQUESTING,
    token,
    providerId,
    values
});

export const createDocumentProviderSuccess = (provider) => ({
    type: CREATE_DOCUMENT_PROVIDER_SUCCESS,
    provider
});

export const createDocumentProviderError = (error) => ({
    type: CREATE_DOCUMENT_PROVIDER_ERROR,
    error
});

export const deleteDocumentProviderRequesting = (documentId, token) => ({
    type: DELETE_DOCUMENT_PROVIDER_REQUESTING,
    documentId,
    token
});

export const deleteDocumentProviderSuccess = (message) => ({
    type: DELETE_DOCUMENT_PROVIDER_SUCCESS,
    message
});

export const deleteDocumentProviderError = () => ({
    type: DELETE_DOCUMENT_PROVIDER_ERROR
});


export const createDocumentLeaderManagerRequesting = (token, leaderManagerId, values) => ({
    type: CREATE_DOCUMENT_LEADER_MANAGER_REQUESTING,
    token,
    leaderManagerId,
    values
});

export const createDocumentLeaderManagerSuccess = (leaderManager) => ({
    type: CREATE_DOCUMENT_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const createDocumentLeaderManagerError = (error) => ({
    type: CREATE_DOCUMENT_LEADER_MANAGER_ERROR,
    error
});