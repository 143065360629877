import {
    LOGOUT_REQUESTING,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    VERIFY_TOKEN,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR
} from './constants'

const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    errors: '',
    response: '',
    logged: false
};

const reducer = function logoutReducer(state = initialState, action) {
    switch (action.type) {
        case LOGOUT_REQUESTING:
            return {
                requesting: true,
                successful: false,
            };
        case LOGOUT_SUCCESS:
            return {
                requesting: false,
                successful: true,
                logged: false
            };

        case LOGOUT_ERROR:
            return {
                errors: action.error,
                requesting: false,
                successful: false,
                logged: false
            };
        case VERIFY_TOKEN:
            return {
                requesting: true,
                response: action.response,
            };
        case VERIFY_TOKEN_SUCCESS:
            return {
                logged: true,
                response: action.response,
            };
        case VERIFY_TOKEN_ERROR:
            return {
                logged: false
            };
        default:
            return state
    }
};

export default reducer