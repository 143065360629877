import {SHIPPING_TYPE_GET_ERROR, SHIPPING_TYPE_GET_REQUESTING, SHIPPING_TYPE_GET_SUCCESS} from "./constants";


const initialState = {
  requesting: false,
  success: false,
  error: '',
  message: '',
  shippingTypes: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type){
        case SHIPPING_TYPE_GET_REQUESTING:
            return{
                ...state,
                requesting: true,
                success: false,
                shippingTypes: [],
            };
        case SHIPPING_TYPE_GET_SUCCESS:
            return{
                ...state,
                requesting: false,
                success: true,
                shippingTypes: action.shippingTypes,
            };
        case SHIPPING_TYPE_GET_ERROR:
            return{
                ...state,
                requesting: false,
                error: action.error,
            };
        default :
            return state;
    }
};
export default reducer;