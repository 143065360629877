//constantes para crear datos de productos
export const PRODUCT_CREATE_REQUESTING = 'PRODUCT_CREATE_REQUESTING';
export const PRODUCT_CREATE_SUCCESS    = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_ERROR      = 'PRODUCT_CREATE_ERROR';

//constantes para actualizar datos de productos
export const PRODUCT_UPDATE_REQUESTING = 'PRODUCT_UPDATE_REQUESTING';
export const PRODUCT_UPDATE_SUCCESS    = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR      = 'PRODUCT_UPDATE_ERROR';
export const SET_FOCUS_PRODUCT         = 'SET_FOCUS_PRODUCT';

//constantes para obtener datos de productos
export const GET_PRODUCT_REQUESTING = 'GET_PRODUCT_REQUESTING';
export const GET_PRODUCT_SUCCESS    = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_ERROR      = 'GET_PRODUCT_ERROR ';

//constantes para actualizar estado de producto
export const PRODUCT_CHANGE_STATE_REQUESTING = 'PRODUCT_CHANGE_STATE_REQUESTING';
export const PRODUCT_CHANGE_STATE_SUCCESS    = 'PRODUCT_CHANGE_STATE_SUCCESS';
export const PRODUCT_CHANGE_STATE_ERROR      = 'PRODUCT_CHANGE_STATE_ERROR';

export const PRODUCT_SEARCH_REQUESTING = 'PRODUCT_SEARCH_REQUESTING';
export const PRODUCT_SEARCH_SUCCESS    = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_ERROR      = 'PRODUCT_SEARCH_ERROR';

export const PRODUCTS_ALL_GET_REQUESTING = 'PRODUCTS_ALL_GET_REQUESTING';
export const PRODUCTS_ALL_GET_SUCCESS = 'PRODUCTS_ALL_GET_SUCCESS';
export const PRODUCTS_ALL_GET_ERROR = 'PRODUCTS_ALL_GET_ERROR';

export const IMAGE_DELETE_REQUESTING = 'IMAGE_DELETE_REQUESTING';
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS';
export const IMAGE_DELETE_ERROR = 'IMAGE_DELETE_ERROR';

export const SHIPPING_TYPE_ATTACH_REQUESTING = 'SHIPPING_TYPE_ATTACH_REQUESTING';
export const SHIPPING_TYPE_ATTACH_SUCCESS = 'SHIPPING_TYPE_ATTACH_SUCCESS';
export const SHIPPING_TYPE_ATTACH_ERROR = 'SHIPPING_TYPE_ATTACH_ERROR';

export const SHIPPING_TYPE_DETACH_REQUESTING = 'SHIPPING_TYPE_DETACH_REQUESTING';
export const SHIPPING_TYPE_DETACH_SUCCESS = 'SHIPPING_TYPE_DETACH_SUCCESS';
export const SHIPPING_TYPE_DETACH_ERROR = 'SHIPPING_TYPE_DETACH_ERROR';

export const CAPACITY_POST_REQUESTING = 'CAPACITY_POST_REQUESTING';
export const CAPACITY_POST_SUCCESS = 'CAPACITY_POST_SUCCESS';
export const CAPACITY_POST_ERROR = 'CAPACITY_POST_ERROR';

export const CAPACITY_FOCUS = 'CAPACITY_FOCUS';
export const CAPACITY_FOCUS_CLEAN = 'CAPACITY_FOCUS_CLEAN';

export const CAPACITY_PUT_REQUESTING = 'CAPACITY_PUT_REQUESTING';
export const CAPACITY_PUT_SUCCESS = 'CAPACITY_PUT_SUCCESS';
export const CAPACITY_PUT_ERROR = 'CAPACITY_PUT_ERROR';

export const CAPACITY_DELETE_REQUESTING = 'CAPACITY_DELETE_REQUESTING';
export const CAPACITY_DELETE_SUCCESS = 'CAPACITY_DELETE_SUCCESS';
export const CAPACITY_DELETE_ERROR = 'CAPACITY_DELETE_ERROR';
