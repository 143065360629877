import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects';
import {
    getRateEmergencyCallError,
    getRateEmergencyCallSuccess,
    getRateError,
    getRateInstallationError,
    getRateInstallationSuccess,
    getRateMaintenanceError,
    getRateMaintenanceSuccess,
    getRateSuccess,
    getRateSupplyError,
    getRateSupplySuccess,
    updateRateEmergencyCallError,
    updateRateEmergencyCallSuccess,
    updateRateError,
    updateRateInstallationError,
    updateRateInstallationSuccess,
    updateRateMaintenanceError,
    updateRateMaintenanceSuccess,
    updateRateSuccess, updateRateSupplyError, updateRateSupplySuccess
} from "./actions";
import {
    GET_RATE_EMERGENCY_CALL_REQUESTING,
    GET_RATE_INSTALLATION_REQUESTING,
    GET_RATE_MAINTENANCE_REQUESTING,
    GET_RATE_REQUESTING,
    GET_RATE_SUPPLY_REQUESTING,
    UPDATE_RATE_EMERGENCY_CALL_REQUESTING,
    UPDATE_RATE_INSTALLATION_REQUESTING,
    UPDATE_RATE_MAINTENANCE_REQUESTING,
    UPDATE_RATE_REQUESTING, UPDATE_RATE_SUPPLY_REQUESTING
} from "./constants";


const RateUrl = `${process.env.REACT_APP_API_URL}/api/ratesDesign`;
const RateMaintenanceUrl = `${process.env.REACT_APP_API_URL}/api/ratesMaintenance`;
const RateInstallationUrl = `${process.env.REACT_APP_API_URL}/api/ratesInstallation`;
const RateEmergencyCallUrl = `${process.env.REACT_APP_API_URL}/api/rateEmergencyCalls`;
const RateSupplyUrl = `${process.env.REACT_APP_API_URL}/api/rateSupplies`;

const getRateAPI = (token, values) => {

    return fetch(`${RateUrl}/${values.country_id}/${values.category_id}/${values.project_id}`,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRateFlow(action) {
    try {
        const {token, values} = action;
        const rates = yield call(getRateAPI, token, values);
        yield put(getRateSuccess(rates));
    }catch (error) {
        yield put(getRateError(error));
    }
}

const updateRateAPI = (rate, token, values) => {
    let body ={
        'value': values.valor,
        'fixed_price' : values.precio_fijo,
        'inside': values.interior,
        'outside': values.exterior,
        'percentage_roca': values.porcentaje_roca,
        'hour_square_meter': values.hora_metro_cuadrado,
    };

    return fetch(`${RateUrl}/${values.country_id}/${values.category_id}/${values.project_id}`, {
        method: 'PUT',
        headers: {
            'Content-type' : 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateRateFlow(action) {
    try {
        const {rate, token, values} = action;
        const rateUpdate = yield call(updateRateAPI, rate, token, values);
        yield put(updateRateSuccess(rateUpdate))
    }catch (error) {
        yield put(updateRateError(error))
    }
}

const getRateMaintenanceAPI = (token, values) => {
    return fetch(`${RateMaintenanceUrl}/${values.country_id}/${values.category_id}/${values.project_id}`,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRateMaintenanceFlow(action) {
    try {
        const {token, values} = action;
        const rateMaintenance = yield call(getRateMaintenanceAPI, token, values);
        yield put(getRateMaintenanceSuccess(rateMaintenance));
    }catch (error) {
        yield put(getRateMaintenanceError(error));
    }
}

const updateRateMaintenanceAPI = (rateMaintenance, token, values) => {
    let body ={
        'percentage_visit_roca': values.porcentaje_visita_roca,
        'percentage_visit_leader_manager': values.porcentaje_visita_gerente_lider,
        'percentage_roca': values.porcentaje_roca,
        'percentage_leader_manager': values.porcentaje_gerente_lider,
        'price_visit': values.precio_visita,
    };

    return fetch(`${RateMaintenanceUrl}/${values.country_id}/${values.category_id}/${values.project_id}`, {
        method: 'PUT',
        headers: {
            'Content-type' : 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateRateMaintenanceFlow(action) {
    try {
        const {rateMaintenance, token, values} = action;
        const rateMaintenanceUpdate = yield call(updateRateMaintenanceAPI, rateMaintenance, token, values);
        yield put(updateRateMaintenanceSuccess(rateMaintenanceUpdate))
    }catch (error) {
        yield put(updateRateMaintenanceError(error))
    }
}

const getRateInstallationAPI = (token, values) => {
    return fetch(`${RateInstallationUrl}/${values.country_id}/${values.category_id}/${values.project_id}`,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRateInstallationFlow(action) {
    try {
        const {token, values} = action;
        const rateInstallation = yield call(getRateInstallationAPI, token, values);
        yield put(getRateInstallationSuccess(rateInstallation));
    }catch (error) {
        yield put(getRateInstallationError(error));
    }
}

const updateRateInstallationAPI = (rateInstallation, token, values) => {
    let body ={
        'percentage_visit_roca': values.porcentaje_visita_roca,
        'percentage_visit_leader_manager': values.porcentaje_visita_gerente_lider,
        'percentage_roca': values.porcentaje_roca,
        'percentage_leader_manager': values.porcentaje_gerente_lider,
        'price_visit': values.precio_visita,
    };

    return fetch(`${RateInstallationUrl}/${values.country_id}/${values.category_id}/${values.project_id}`, {
        method: 'PUT',
        headers: {
            'Content-type' : 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateRateInstallationFlow(action) {
    try {
        const {rateInstallation, token, values} = action;
        const rateInstallationUpdate = yield call(updateRateInstallationAPI, rateInstallation, token, values);
        yield put(updateRateInstallationSuccess(rateInstallationUpdate))
    }catch (error) {
        yield put(updateRateInstallationError(error))
    }
}

const getRateEmergencyCallAPI = (token, values) => {

    return fetch(`${RateEmergencyCallUrl}/${values.country_id}/${values.category_id}/${values.project_id}`,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRateEmergencyCallFlow(action) {
    try {
        const {token, values} = action;
        const rateEmergencyCalls = yield call(getRateEmergencyCallAPI, token, values);
        yield put(getRateEmergencyCallSuccess(rateEmergencyCalls));
    }catch (error) {
        yield put(getRateEmergencyCallError(error));
    }
}

const updateRateEmergencyCallAPI = (rateEmergencyCall, token, values) => {
    let body ={
        'percentage_visit_roca': values.porcentaje_visita_roca,
        'percentage_visit_leader_manager': values.porcentaje_visita_gerente_lider,
        'price_visit': values.precio_visita,
    };

    return fetch(`${RateEmergencyCallUrl}/${values.country_id}/${values.category_id}/${values.project_id}`, {
        method: 'PUT',
        headers: {
            'Content-type' : 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateRateEmergencyCallFlow(action) {
    try {
        const {rateEmergencyCall, token, values} = action;
        const rateEmergencyCallUpdate = yield call(updateRateEmergencyCallAPI, rateEmergencyCall, token, values);
        yield put(updateRateEmergencyCallSuccess(rateEmergencyCallUpdate))
    }catch (error) {
        yield put(updateRateEmergencyCallError(error))
    }
}

const getRateSupplyAPI = (token, values) => {

    return fetch(`${RateSupplyUrl}/${values.country_id}/${values.category_id}`,{
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if(json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getRateSupplyFlow(action) {
    try {
        const {token, values} = action;
        const rateSupplies = yield call(getRateSupplyAPI, token, values);
        yield put(getRateSupplySuccess(rateSupplies));
    }catch (error) {
        yield put(getRateSupplyError(error));
    }
}

const updateRateSupplyAPI = (rateSupply, token, values) => {
    let body ={
        'percentage_roca': values.porcentaje_roca,
    };

    return fetch(`${RateSupplyUrl}/${values.country_id}/${values.category_id}`, {
        method: 'PUT',
        headers: {
            'Content-type' : 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateRateSupplyFlow(action) {
    try {
        const {rateSupply, token, values} = action;
        const rateSupplyUpdate = yield call(updateRateSupplyAPI, rateSupply, token, values);
        yield put(updateRateSupplySuccess(rateSupplyUpdate))
    }catch (error) {
        yield put(updateRateSupplyError(error))
    }
}

function* rateWatcher() {
    yield all([
        takeEvery(GET_RATE_REQUESTING, getRateFlow),
        takeEvery(UPDATE_RATE_REQUESTING, updateRateFlow),
        takeEvery(GET_RATE_MAINTENANCE_REQUESTING, getRateMaintenanceFlow),
        takeEvery(UPDATE_RATE_MAINTENANCE_REQUESTING, updateRateMaintenanceFlow),
        takeEvery(GET_RATE_INSTALLATION_REQUESTING, getRateInstallationFlow),
        takeEvery(UPDATE_RATE_INSTALLATION_REQUESTING, updateRateInstallationFlow),
        takeEvery(GET_RATE_EMERGENCY_CALL_REQUESTING, getRateEmergencyCallFlow),
        takeEvery(UPDATE_RATE_EMERGENCY_CALL_REQUESTING, updateRateEmergencyCallFlow),
        takeEvery(GET_RATE_SUPPLY_REQUESTING, getRateSupplyFlow),
        takeEvery(UPDATE_RATE_SUPPLY_REQUESTING, updateRateSupplyFlow)
    ])
}

export default rateWatcher;