export const SUPPLIES_GET_REQUESTING = 'SUPPLIES_GET_REQUESTING';
export const SUPPLIES_GET_SUCCESS = 'SUPPLIES_GET_SUCCESS';
export const SUPPLIES_GET_ERROR = 'SUPPLIES_GET_ERROR';

export const SUPPLY_POST_REQUESTING = 'SUPPLY_POST_REQUESTING';
export const SUPPLY_POST_SUCCESS = 'SUPPLY_POST_SUCCESS';
export const SUPPLY_POST_ERROR = 'SUPPLY_POST_ERROR';

export const SUPPLY_FOCUS = 'SUPPLY_FOCUS';
export const SUPPLY_FOCUS_CLEAN = 'SUPPLY_FOCUS_CLEAN';

export const SUPPLY_PUT_REQUESTING = 'SUPPLY_PUT_REQUESTING';
export const SUPPLY_PUT_SUCCESS = 'SUPPLY_PUT_SUCCESS';
export const SUPPLY_PUT_ERROR = 'SUPPLY_PUT_ERROR';

export const SUPPLY_DELETE_REQUESTING = 'SUPPLY_DELETE_REQUESTING';
export const SUPPLY_DELETE_SUCCESS = 'SUPPLY_DELETE_SUCCESS';
export const SUPPLY_DELETE_ERROR = 'SUPPLY_DELETE_ERROR';

export const SUPPLIES_SEARCH_REQUESTING = 'SUPPLIES_SEARCH_REQUESTING';
export const SUPPLIES_SEARCH_SUCCESS = 'SUPPLIES_SEARCH_SUCCESS';
export const SUPPLIES_SEARCH_ERROR = 'SUPPLIES_SEARCH_ERROR';

export const STATES_SUPPLIES_GET_REQUESTING = 'STATES_SUPPLIES_GET_REQUESTING';
export const STATES_SUPPLIES_GET_SUCCESS = 'STATES_SUPPLIES_GET_SUCCESS';
export const STATES_SUPPLIES_GET_ERROR = 'STATES_SUPPLIES_GET_ERROR';