import React from 'react';
import {Field, reduxForm} from 'redux-form';
import connect from "react-redux/es/connect/connect";
import {toastr} from "react-redux-toastr";
import * as Ladda from "ladda";
import {ErrorComponent} from "../../../commons/errors/errors";
import {updateProductsRequesting} from "../../../redux/product/actions";
import {getAllProviderRequesting} from "../../../redux/provider/actions";
import {getCategoryRequesting} from "../../../redux/category/actions";
import {getBrandRequesting} from "../../../redux/brand/actions";
import {paymentMethodGetRequesting} from "../../../redux/paymentMethod/actions";
import {shippingTypeGetRequesting} from "../../../redux/shippingType/actions";
import {FilePond} from 'react-filepond';


const FileInput = ({input, name, id, meta: {touched, error}, ...rest}) => {

    return (
        <FilePond
            value={input.value}
            labelIdle={'Arrastra y suelta tus archivos o <span class="filepond--label-action ">Busca aquí</span>'}
            allowMultiple={true}
            maxFiles={6}
            onupdatefiles={fileItems => input.onChange(fileItems)}
            {...input}
            {...rest}
        >
        </FilePond>
    );
};

class ModalEditProduct extends React.Component {

    componentWillUpdate(nextProps) {
        const {product} = this.props;

        if (nextProps.product.error !== '' && nextProps.product.error !== product.error) {
            let toastOptions = {
                component: (
                    <ErrorComponent message={nextProps.product.error}/>
                )
            };
            toastr.error('Error', toastOptions);
        }
        if (nextProps.product.update && !product.update) {
            toastr.success('Producto Actualizado', 'Los datos han sido actualizados correctamente');
            this.props.hidden();
        }
    }

    componentWillMount() {
        const {client: {token}, getAllProviderRequesting, getCategoryRequesting, getBrandRequesting, paymentMethodGetRequesting, shippingTypeGetRequesting} = this.props;
        ;
        getAllProviderRequesting(token);
        getCategoryRequesting(token);
        getBrandRequesting(token);
        paymentMethodGetRequesting(token);
        shippingTypeGetRequesting(token);
    }

    handleAction = (values) => {
        const {client: {token}, updateProductsRequesting, product: {focusProduct}} = this.props;
        updateProductsRequesting(values, token, focusProduct);
    };

    render() {
        const {
            handleSubmit,
            initialValues,
            providers,
            categories,
            brands
        } = this.props;

        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    {initialValues.fotos.data.length > 0 && initialValues.fotos.data.map(photo => (
                        <img src={photo.src} alt={photo.id} style={{width: 60, height: 60}}/>
                    ))}
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Editar producto</h1>
                    </div>
                    <div className="text-modal">
                        <h2>Información de producto</h2>
                    </div>
                    <form onSubmit={handleSubmit(this.handleAction)}>
                        <div className="item-modal">
                            <label>Nombre: </label>
                            <Field name='nombre' className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Referencia: </label>
                            <Field name={'referencia'} className='input input_city' component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Capacidad: </label>
                            <Field name={'capacidad'} className='input input_city' component={'input'}/>
                        </div>
                        <label>Foto: </label>
                        <Field name={'foto'} component={FileInput}/>

                        <label>Ficha tecnica (PDF): </label>
                        <Field name={'documento'} component={FileInput}/>


                        <div className="item-modal">
                            <label>Descripción: </label>
                            <Field name={'descripcion'} className='input input_city' component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Medidas: </label>
                            <Field name={'medidas'} type={'text'} className='input input_city' component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio: </label>
                            <Field name={'precio'} type={'number'} className='input input_city' component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio de descuento: </label>
                            <Field name={'precio_descuento'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio de gerente lider: </label>
                            <Field name={'precio_gerente_lider'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Precio envio: </label>
                            <Field name={'precio_envio'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Tiempo de entrega: </label>
                            <Field name={'tiempo_envio'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        {/* <div className="item-modal">
                            <label>Instalacion: </label>
                            <Field name={'instalacion'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>*/}



                        <div className="item-modal">
                            <label>Limite: </label>
                            <Field name={'limite_inventario'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>

                        <div className="item-modal">
                            <label>Inventario: </label>
                            <Field name={'inventario_disponible'} type={'number'} className='input input_city'
                                   component={'input'}/>
                        </div>
                        <div className="item-modal">
                            <label>Marca: </label>
                            <Field component={'select'} name="marca" className="select-rol">
                                <option value={initialValues.marca.id}>{initialValues.marca}</option>
                                {brands.length > 0 && brands.map((brand, index) => {
                                    if (brand.id !== initialValues.marca.id)
                                        return <option key={index} value={brand.id}>{brand.nombre}</option>
                                })}
                            </Field>
                        </div>
                        {/* <div className="item-modal">
                            <label>Tipo de envio: </label>
                            <Field component={'select'} name="tipo_envio" className="select-rol">
                                <option value={initialValues.tipo_envio.id}>{initialValues.tipo_envio.nombre}</option>
                                {shippingTypes.length > 0 && shippingTypes.map((shippingType, index)=>{
                                    if (shippingType.id !== initialValues.tipo_envio.id)
                                        return <option key={index} value={shippingType.id}>{shippingType.nombre}</option>
                                })}
                            </Field>
                        </div>*/}
                        {/* <div className="item-modal">
                            <label>Metodo de pago: </label>
                            <Field component={'select'} name="metodo_pago" className="select-rol">
                                <option value={initialValues.metodo_pago.id}>{initialValues.metodo_pago.nombre}</option>
                                {paymentMethods.length > 0 && paymentMethods.map((paymentMethod, index)=>{
                                    if (paymentMethod.id !== initialValues.metodo_pago.id)
                                        return <option key={index} value={paymentMethod.id}>{paymentMethod.nombre}</option>
                                })}
                            </Field>
                        </div>*/}
                        <div className="item-modal">
                            <label>Proveedor: </label>
                            <Field component={'select'} name="proveedor" className="select-rol">
                                <option value={initialValues.proveedor.id}>{initialValues.proveedor.nombre}</option>
                                {providers.length > 0 && providers.map((provider, index) => {
                                    if (provider.id !== initialValues.proveedor.id)
                                        return <option key={index} value={provider.id}>{provider.nombre}</option>
                                })}
                            </Field>
                        </div>
                        <div className="item-modal">
                            <label>Categoria: </label>
                            <Field component={'select'} name="categoria" className="select-rol">
                                <option value={initialValues.categoria}>{initialValues.categoria}</option>
                                {categories.length > 0 && categories.map((category, index) => {
                                    if (category.nombre !== initialValues.categoria)
                                        return <option key={index} value={category.nombre}>{category.nombre}</option>
                                })}
                            </Field>
                        </div>

                        <div className="button-container">
                            <div className="btn-container">
                                <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i
                                    className="icon-cancel styles-icons"/>
                                </button>

                                <button className="Btn-modal">Editar producto<i className="icon-ok styles-icons"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const formed = reduxForm({
    form: 'ProductEditModal'
})(ModalEditProduct);

const mapStateToProps = state => ({
    client: state.client,
    product: state.product,
    initialValues: state.product.productDataFocus,
    providers: state.provider.providers,
    categories: state.category.categories,
    brands: state.brand.brands,
    shippingTypes: state.shippingType.shippingTypes,
    paymentMethods: state.paymentMethod.paymentMethods,
});

const connected = connect(mapStateToProps, {
    updateProductsRequesting,
    getAllProviderRequesting,
    getCategoryRequesting,
    getBrandRequesting,
    paymentMethodGetRequesting,
    shippingTypeGetRequesting,
})(formed);

export default connected