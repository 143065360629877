import {
    STATES_GET_REQUESTING,
    STATES_GET_SUCCESS,
    STATES_GET_ERROR,
    STATES_EMERGENCY_CALL_REQUESTING,
    STATES_EMERGENCY_CALL_SUCCESS,
    STATES_EMERGENCY_CALL_ERROR,
    STATES_USER_REQUESTING,
    STATES_USER_SUCCESS,
    STATES_USER_ERROR,
    STATES_PROVIDER_REQUESTING,
    STATES_PROVIDER_SUCCESS,
    STATES_PROVIDER_ERROR,
    STATES_PRODUCT_ERROR,
    STATES_PRODUCT_REQUESTING,
    STATES_PRODUCT_SUCCESS,
    STATES_ORDER_ERROR,
    STATES_ORDER_REQUESTING,
    STATES_ORDER_SUCCESS,
} from "./constans";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    states: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case STATES_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
            };
        case STATES_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                states: action.states,
                error: '',
            };
        case STATES_GET_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
            //David
        case STATES_EMERGENCY_CALL_REQUESTING:
            return {
              ...state,
              requesting: true,
            };
        case STATES_EMERGENCY_CALL_SUCCESS:
            return {
              ...state,
              success: true,
              requesting: false,
              states: action.states
            };
        case STATES_EMERGENCY_CALL_ERROR:
            return {
              ...state,
              error: action.error,
              success: false,
              requesting: false
            };
        case STATES_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesUser : []
            };
        case STATES_USER_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesUser: action.states
            };
        case STATES_USER_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesUser : []
            };
        case STATES_PROVIDER_REQUESTING:
            return {
              ...state,
              requesting: true
            };
        case STATES_PROVIDER_SUCCESS:
            return {
              ...state,
              success: true,
              requesting: false,
              states: action.states
            };
        case STATES_PROVIDER_ERROR:
            return {
              ...state,
              error: action.error,
              success: false,
              requesting: false
            };
        case STATES_PRODUCT_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesProduct : []
            };
        case STATES_PRODUCT_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesProduct: action.states
            };
        case STATES_PRODUCT_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesProduct : []
            };
        case STATES_ORDER_REQUESTING:
            return {
                ...state,
                requesting: true,
                statesOrder : []
            };
        case STATES_ORDER_SUCCESS:
            return {
                ...state,
                success: true,
                requesting: false,
                statesOrder: action.states
            };
        case STATES_ORDER_ERROR:
            return {
                ...state,
                error: action.error,
                success: false,
                requesting: false,
                statesOrder : []
            };
        default :
            return state;
    }
};

export default reducer;