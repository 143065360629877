import React from 'react';
import {connect} from "react-redux";
import MenuHome from "../../components/menu-home/menuHome";
import {statesGetRequesting} from "../states/actions";
import {countriesGetRequesting} from "../../redux/country/actions";
import {allCitiesGetRequesting} from "../../redux/city/actions";
import {checkAuth} from "../client/actions";

class RoutesComponent extends React.Component {

    componentWillMount() {
        this.props.checkAuth();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.client.isLoad){
            this.props.statesGetRequesting(this.props.client.token);
             this.props.countriesGetRequesting(this.props.client.token);
             this.props.allCitiesGetRequesting(this.props.client.token);
        }
    }

    render() {
        return (
            <div>
                <MenuHome/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client
});

const connected = connect(mapStateToProps, {
    checkAuth,
    statesGetRequesting,
    countriesGetRequesting,
    allCitiesGetRequesting,
})(RoutesComponent);

export default connected;