import {
    CHANGE_STATE_PROVIDER_ERROR,
    CHANGE_STATE_PROVIDER_REQUESTING,
    CHANGE_STATE_PROVIDER_SUCCESS,
    CREATE_PROVIDER_ERROR,
    CREATE_PROVIDER_REQUESTING,
    CREATE_PROVIDER_SUCCESS,
    GET_PROVIDER_ERROR,
    GET_PROVIDER_REQUESTING,
    GET_PROVIDER_SUCCESS,
    SEARCH_PROVIDER_ERROR,
    SEARCH_PROVIDER_REQUESTING,
    SEARCH_PROVIDER_SUCCESS,
    SET_FOCUS_PROVIDER, UPDATE_DOCUMENT, UPDATE_PROVIDER,
    UPDATE_PROVIDER_ERROR,
    UPDATE_PROVIDER_REQUESTING,
    UPDATE_PROVIDER_SUCCESS,
    GET_ALL_PROVIDER_ERROR,
    GET_ALL_PROVIDER_REQUESTING,
    GET_ALL_PROVIDER_SUCCESS

} from "./constants";


const initialState = {

    requesting: false,
    success: false,
    error: '',
    cursor: 1,
    filter: 'Todos',
    hasMore: true,
    hasLess: false,
    providers: [],
    provider: [],
    providerId: 0,
    search: '',
    focusProvider: 0,
    providerDataFocus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_PROVIDER_REQUESTING:
            return{
                ...state,
                providers:[],
            };
        case GET_ALL_PROVIDER_SUCCESS:
            return {
                ...state,
                providers: action.providers,
            };
        case GET_ALL_PROVIDER_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case GET_PROVIDER_REQUESTING:
            return{
                ...state,
                cursor: action.cursor,
                filter: action.filter,
            };
        case GET_PROVIDER_SUCCESS:
            return {
              ...state,
              providers: action.providers,
              hasLess: state.cursor + 1 > 1,
              hasMore: true
            };
        case GET_PROVIDER_ERROR:
            return{
              ...state,
              error: action.errors,
              hasMore: false,
              providers: []
            };
        case CHANGE_STATE_PROVIDER_REQUESTING:
            return {
              ...state,
                requesting: true,
                providerId: action.providerId
            };
        case CHANGE_STATE_PROVIDER_SUCCESS:
            return {
              ...state,
              providers: state.providers.map(providerItem => (
              providerItem.id === action.provider.id ? action.provider : providerItem
              )),
              provider: state.provider.length > 0 && state.provider.map(providerItem => (
              providerItem.id === action.provider.id ? action.provider : providerItem
              ))
            };
        case CHANGE_STATE_PROVIDER_ERROR:
            return {
              ...state,
              requesting: false
            };
        case SEARCH_PROVIDER_REQUESTING:
            return {
              ...state,
              search: action.search
            };
        case SEARCH_PROVIDER_SUCCESS:
            return {
              ...state,
              provider: action.provider
            };
        case SEARCH_PROVIDER_ERROR:
            return {
              ...state,
              provider: []
            };
        case CREATE_PROVIDER_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case CREATE_PROVIDER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              providers: [action.providerCreated, ...state.providers]
            };
        case CREATE_PROVIDER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case SET_FOCUS_PROVIDER:
            return {
              ...state,
              focusProvider: action.id,
              providerDataFocus: action.provider
            };
        case UPDATE_PROVIDER_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case UPDATE_PROVIDER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              provider: state.provider.map((provider) => provider.id === state.focusProvider
                                            ? action.providerUpdate : provider),
              providers: state.providers.map((provider) => provider.id === state.focusProvider
                                            ? action.providerUpdate : provider)
            };
        case UPDATE_PROVIDER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case UPDATE_PROVIDER :
            return {
                ...state,
                providers: state.providers.map(providerItem => {
                    providerItem.documentos.data.map((document, index) => {
                        if (document.id === action.documentDeleted) {
                            return providerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return providerItem;
                }),
                provider: state.provider.map(providerItem => {
                    providerItem.documentos.data.map((document, index) => {
                        if (document.id === action.documentDeleted) {
                            return providerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return providerItem;
                })
            };
        case UPDATE_DOCUMENT:
            return{
                ...state,
                requesting: false,
                success: true,
                error: '',
                provider: state.provider.map((provider) => action.provider.id === provider.id ?
                                        action.provider : provider),
                providers: state.providers.map((provider) => action.provider.id === provider.id ?
                                                action.provider : provider)
            };
        default:
            return state;
    }
};

export default reducer;