import React, {Component} from 'react';
import {Document, Page} from 'react-pdf';

export class TableProviders extends React.Component {



    render() {
        const {providers, handleChangeState, handleShowModal, handleDeleteDocument} = this.props;
        return (


            <div className="Conteiner-table">
                <table className="table-providers">
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Sede</th>
                        <th>Nit del Proveedor</th>
                        <th>Pais</th>
                        <th>Ciudad</th>
                        <th>Dirección</th>
                        <th>Logo</th>
                        <th>Correo electronico</th>
                        <th>Telefono</th>
                        <th>Celular</th>
                        <th>% de Comisión</th>
                        <th>Documentos</th>
                        <th>Estado</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {providers.map((provider, index) => (
                        <tr key={index}>
                            <td>{provider.nombre}</td>
                            <td>{provider.sede}</td>
                            <td>{provider.identificacion}</td>
                            <td>{provider.pais}</td>
                            <td>{provider.ciudad}</td>
                            <td>{provider.direccion}</td>
                            <td>{<img src={provider.foto} alt="" style={{width: 90, height: 90}}/>}</td>
                            <td>{provider.correo}</td>
                            <td>{provider.telefono}</td>
                            <td>{provider.celular}</td>
                            <td>{provider.comision} %</td>
                            <td>{
                                <div>
                                    {provider.documentos.data.length > 0 &&
                                    provider.documentos.data.map((document, index) => (
                                        <div key={index} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <a href={document.src} target={'_blank'}>{document.nombre.length >
                                            30 ? document.nombre.substring(0, 30) + '...' : document.nombre}</a>
                                            <i
                                                style={{cursor: 'pointer'}}
                                                onClick={() => handleDeleteDocument(document.id)}
                                                className="icon-trash styles-icons2"/>
                                        </div>
                                    ))}
                                    <button className="Btn-general-edit-tabla"
                                            onClick={this.props.handleShowModal.bind(this, 'Crear documento',
                                                provider.id, provider)}>Agregar<i
                                    /></button>
                                </div>}
                            </td>
                            <td><input className="style-checkbox" type="checkbox"
                                       onClick={() => handleChangeState(provider.id)}
                                       checked={provider.estado === 'Habilitado'}/>
                                <p className={provider.estado !== 'Habilitado' ? "style-checkbox-on" : "style-checkbox-off"}>
                                    {provider.estado}</p>
                            </td>
                            <td>
                                <button className="Btn-buscar"
                                        onClick={() => handleShowModal('editar', provider.id, provider)}>Editar <i
                                    className="icon-pencil styles-icons"/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}
