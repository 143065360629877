export const GET_LEADER_MANAGER_REQUESTING = 'GET_LEADER_MANAGER_REQUESTING';
export const GET_LEADER_MANAGER_SUCCESS = 'GET_LEADER_MANAGER_SUCCESS';
export const GET_LEADER_MANAGER_ERROR = 'GET_LEADER_MANAGER_ERROR';

export const CHANGE_STATE_LEADER_MANAGER_REQUESTING = 'CHANGE_STATE_LEADER_MANAGER_REQUESTING';
export const CHANGE_STATE_LEADER_MANAGER_SUCCESS = 'CHANGE_STATE_LEADER_MANAGER_SUCCESS';
export const CHANGE_STATE_LEADER_MANAGER_ERROR = 'CHANGE_STATE_LEADER_MANAGER_ERROR';

export const SEARCH_LEADER_MANAGER_REQUESTING = 'SEARCH_LEADER_MANAGER_REQUESTING';
export const SEARCH_LEADER_MANAGER_SUCCESS = 'SEARCH_LEADER_MANAGER_SUCCESS';
export const SEARCH_LEADER_MANAGER_ERROR = 'SEARCH_LEADER_MANAGER_ERROR';

export const CREATE_LEADER_MANAGER_REQUESTING = 'CREATE_LEADER_MANAGER_REQUESTING';
export const CREATE_LEADER_MANAGER_SUCCESS = 'CREATE_LEADER_MANAGER_SUCCESS';
export const CREATE_LEADER_MANAGER_ERROR = 'CREATE_LEADER_MANAGER_ERROR';

export const SET_FOCUS_LEADER_MANAGER = 'SET_FOCUS_LEADER_MANAGER';

export const UPDATE_LEADER_MANAGER_REQUESTING = 'UPDATE_LEADER_MANAGER_REQUESTING';
export const UPDATE_LEADER_MANAGER_SUCCESS = 'UPDATE_LEADER_MANAGER_SUCCESS';
export const UPDATE_LEADER_MANAGER_ERROR = 'UPDATE_LEADER_MANAGER_ERROR';

export const GET_CATEGORY_LEADER_MANAGER_REQUESTING = 'GET_CATEGORY_LEADER_MANAGER_REQUESTING';
export const GET_CATEGORY_LEADER_MANAGER_SUCCESS = 'GET_CATEGORY_LEADER_MANAGER_SUCCESS';
export const GET_CATEGORY_LEADER_MANAGER_ERROR = 'GET_CATEGORY_LEADER_MANAGER_ERROR';

export const DELETE_CATEGORY_LEADER_MANAGER_REQUESTING = 'DELETE_CATEGORY_LEADER_MANAGER_REQUESTING';
export const DELETE_CATEGORY_LEADER_MANAGER_SUCCESS = 'DELETE_CATEGORY_LEADER_MANAGER_SUCCESS';
export const DELETE_CATEGORY_LEADER_MANAGER_ERROR = 'DELETE_CATEGORY_LEADER_MANAGER_ERROR';

export const UPDATE_LEADER_MANAGER = 'UPDATE_LEADER_MANAGER';
export const UPDATE_DOCUMENT_LEADER_MANAGER = 'UPDATE_DOCUMENT_LEADER_MANAGER';

export const GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING = 'GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING';
export const GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS = 'GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS';
export const GET_SUBCATEGORY_LEADER_MANAGER_ERROR = 'GET_SUBCATEGORY_LEADER_MANAGER_ERROR';

export const DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING = 'DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING';
export const DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS = 'DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS';
export const DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR = 'DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR';

export const DELETE_DEVICE_LEADER_MANAGER_REQUESTING = 'DELETE_DEVICE_LEADER_MANAGER_REQUESTING';
export const DELETE_DEVICE_LEADER_MANAGER_SUCCESS = 'DELETE_DEVICE_LEADER_MANAGER_SUCCESS';
export const DELETE_DEVICE_LEADER_MANAGER_ERROR = 'DELETE_DEVICE_LEADER_MANAGER_ERROR';
