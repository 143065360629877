import React from 'react';
import TableBrands from "../table/table-brands";
import ModalAddBrands from "../modals/add-brands";
import connect from "react-redux/es/connect/connect";
import {getBrandRequesting,changeStateBrandRequesting} from "../../../redux/brand/actions";
import {NotFound} from "../../../commons/errors/notFound";


class ConfigurationComponentBrands
    extends React.Component {

    state = {
        isAddBrand: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isAddBrand: modal === 'agregar marca',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isAddBrand: false,
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

// *******************************

    componentWillMount() {

        const {getBrandRequesting, client} = this.props;
        getBrandRequesting(client.token);
    }

    handleChangeState = (id) => {
        const {changeStateBrandRequesting, client} = this.props;
        changeStateBrandRequesting(id, client.token)
    };

    render() {

        const {brand: {brands}} = this.props;
        return (
            <div className="styles-container">
                {this.state.isAddBrand && (
                    <ModalAddBrands
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <div className="conteiner-page-sector1">
                    <div className="styles-title-page-h1">
                        <h1>Creación de marcas</h1>
                        <button className="Btn-general-direction"
                                onClick={this.handleShowModal.bind(this, 'agregar marca')}>
                            Crear una marca
                            <i className="icon-ok styles-icons"/>
                        </button>
                    </div>
                    {brands.length > 0 ? (
                        <TableBrands
                        brands={brands}
                        handleChangeState={this.handleChangeState.bind(this)}
                        />
                    ): <NotFound/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    brand: state.brand
});

const connected = connect(mapStateToProps, {
    getBrandRequesting,
    changeStateBrandRequesting
})(ConfigurationComponentBrands);

export default connected;