import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {allCitiesGetSuccess, allCitiesGetError} from "./actions";
import {ALL_CITIES_GET_REQUESTING} from "./constants";

const getCityUrl = `${process.env.REACT_APP_API_URL}/api/cities`;

const AllCitiesGetApi = (token) => {
    return fetch(getCityUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if(json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* allCitiesGetFlow(action) {
    try {
        const {token} = action;
        const allCities = yield call(AllCitiesGetApi, token);
        yield put(allCitiesGetSuccess(allCities));
    } catch (error) {
        yield put(allCitiesGetError(error));
    }
}

function* cityWatcher() {
    yield all([
        takeEvery(ALL_CITIES_GET_REQUESTING, allCitiesGetFlow),
    ])
}

export default cityWatcher;