import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from 'redux-saga/effects'
import {getCategoryError, getCategorySuccess} from "./actions";
import {GET_CATEGORY_REQUESTING} from "./constants";

const getCategoryUrl = `${process.env.REACT_APP_API_URL}/api/categories`;


const getCategoryAPI = (token) => {

    return fetch(getCategoryUrl, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json =>{
            if(json.code === 422){
                let message = '';
                for(let key in json.data){
                    message += json.data[key] + '\n\n';
                }
                throw message;
            }
            if (json.code === 400)
                throw json.data;
            if(json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* getCategoryFlow(action) {
    try{
        const {token} = action;
        const categories = yield call(getCategoryAPI, token);
        yield put(getCategorySuccess(categories));
    }catch (error) {
        yield put(getCategoryError(error))
    }
}

function* categoryWatcher() {
    yield all([
        takeEvery(GET_CATEGORY_REQUESTING, getCategoryFlow)
    ])
}

export default categoryWatcher;