import React from 'react';
import iconftoBrand from "../../../assets/img/logo-brand.jpg";
import {ModalRemove} from "../../providers-product/modals/remove-date";
import ModalEditBrand from "../modals/edit-brands";
import {setFocusBrand} from "../../../redux/brand/actions";
import connect from "react-redux/es/connect/connect";

class TableBrands
    extends React.Component {

    state = {
        isAddBrand: false,
        isEditBrand: false,
        isModalRemove: false
    };
    handleShowModal = (modal, id = 0, brand = {}) => {
        const {setFocusBrand} = this.props;
        if (modal === 'editar')
            setFocusBrand(id, brand);
        this.setState({
            isAddBrand: modal === 'agregar marca',
            isEditBrand: modal === 'editar',
            isModalRemove: modal === 'remover marca'

        })
    };
    handleHiddenModal = () => {
        this.setState({

            isAddBrand: false,
            isEditBrand: false,
            isModalRemove: false
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

// *******************************
    render() {

        const {brands, handleChangeState} = this.props;
        return (
            <div className="Conteiner-table">
                {this.state.isEditBrand && (
                    <ModalEditBrand
                        stop={this.stopPropagation.bind(this)}
                        hidden={this.handleHiddenModal.bind(this)}/>
                )}
                <table className="table-brands">
                    <thead>
                    <tr>
                        <th>Nombre de marca</th>
                        <th>Foto de logo</th>
                        <th>Acción</th>
                    </tr>
                    </thead>
                    <tbody>
                    {brands.map((brand, index) => (
                        <tr key={index}>
                            <td>{brand.nombre}</td>
                            <td>{<img src={brand.logo} alt="" style={{width: 90, height: 90}}/>}</td>
                            <td className="style-btn-brand">
                                <button style={{width: '47%'}} className="Btn-item-table"
                                        onClick={this.handleShowModal.bind(this, 'editar', brand.id, brand)}>Editar<i
                                    className="icon-pencil styles-icons"/>
                                </button>
                                {/* <button className="Btn-item-eliminar-tabla"
                                        style={brand.estado === 'Inhabilitado' ? {backgroundColor: 'red'} : {
                                            backgroundColor: 'green',
                                            borderColor : 'green'
                                        }}
                                        onClick={() => handleChangeState(brand.id)}>{brand.estado === 'Inhabilitado' ? 'Desactivar' : 'Activar'}</button> */}
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    brand: state.brand,
    client: state.client,
});

const connected = connect(mapStateToProps, {
    setFocusBrand
})(TableBrands);

export default connected