import {
    STATES_SUPPLIES_GET_ERROR,
    STATES_SUPPLIES_GET_REQUESTING, STATES_SUPPLIES_GET_SUCCESS,
    SUPPLIES_GET_ERROR,
    SUPPLIES_GET_REQUESTING,
    SUPPLIES_GET_SUCCESS, SUPPLIES_SEARCH_ERROR, SUPPLIES_SEARCH_REQUESTING, SUPPLIES_SEARCH_SUCCESS,
    SUPPLY_DELETE_ERROR,
    SUPPLY_DELETE_REQUESTING,
    SUPPLY_DELETE_SUCCESS,
    SUPPLY_FOCUS,
    SUPPLY_FOCUS_CLEAN,
    SUPPLY_POST_ERROR,
    SUPPLY_POST_REQUESTING,
    SUPPLY_POST_SUCCESS,
    SUPPLY_PUT_ERROR,
    SUPPLY_PUT_REQUESTING,
    SUPPLY_PUT_SUCCESS,
} from "./constants";

export const suppliesGetRequesting = (token, filter, paginate) => ({
    type: SUPPLIES_GET_REQUESTING,
    token, filter, paginate,
});

export const suppliesGetSuccess = (supplies) => ({
    type: SUPPLIES_GET_SUCCESS,
    supplies,
});

export const suppliesGetError = (error) => ({
    type: SUPPLIES_GET_ERROR,
    error,
});

export const supplyPostRequesting = (token, supply) => ({
    type: SUPPLY_POST_REQUESTING,
    token, supply
});

export const supplyPostSuccess = (supply) => ({
    type: SUPPLY_POST_SUCCESS,
    supply,
});

export const supplyPostError = (error) => ({
    type: SUPPLY_POST_ERROR,
    error,
});

export const supplyFocus = (supply) => ({
    type: SUPPLY_FOCUS,
    supply,
});

export const supplyFocusClean = () => ({
    type: SUPPLY_FOCUS_CLEAN,
});

export const supplyPutRequesting = (token, supply) => ({
    type: SUPPLY_PUT_REQUESTING,
    token, supply,
});

export const supplyPutSuccess = (supply) => ({
    type: SUPPLY_PUT_SUCCESS,
    supply,
});

export const supplyPutError = (error) => ({
    type: SUPPLY_PUT_ERROR,
    error,
});

export const supplyDeleteRequesting = (token, supply) => ({
    type: SUPPLY_DELETE_REQUESTING,
    token, supply,
});

export const supplyDeleteSuccess = (supply) => ({
    type: SUPPLY_DELETE_SUCCESS,
    supply
});

export const supplyDeleteError = (error) => ({
    type: SUPPLY_DELETE_ERROR,
    error,
});

export const suppliesSearchRequesting = (token, search) => ({
    type: SUPPLIES_SEARCH_REQUESTING,
    token, search,
});

export const suppliesSearchSuccess = (supplies) => ({
    type: SUPPLIES_SEARCH_SUCCESS,
    supplies,
});

export const suppliesSearchError = (error) => ({
    type: SUPPLIES_SEARCH_ERROR,
    error,
});

export const statesSuppliesGetRequesting = (token) => ({
    type: STATES_SUPPLIES_GET_REQUESTING,
    token,
});

export const statesSuppliesGetSuccess = (states) => ({
    type: STATES_SUPPLIES_GET_SUCCESS,
    states,
});

export const statesSuppliesGetError = (error) => ({
    type: STATES_SUPPLIES_GET_ERROR,
    error,
});