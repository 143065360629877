import {
    STATES_SUPPLIES_GET_ERROR,
    STATES_SUPPLIES_GET_REQUESTING,
    STATES_SUPPLIES_GET_SUCCESS,

    SUPPLIES_GET_ERROR,
    SUPPLIES_GET_REQUESTING,
    SUPPLIES_GET_SUCCESS,

    SUPPLIES_SEARCH_ERROR,
    SUPPLIES_SEARCH_REQUESTING,
    SUPPLIES_SEARCH_SUCCESS,

    SUPPLY_DELETE_ERROR,
    SUPPLY_DELETE_REQUESTING,
    SUPPLY_DELETE_SUCCESS,

    SUPPLY_FOCUS,
    SUPPLY_FOCUS_CLEAN,

    SUPPLY_POST_ERROR,
    SUPPLY_POST_REQUESTING,
    SUPPLY_POST_SUCCESS,

    SUPPLY_PUT_ERROR,
    SUPPLY_PUT_REQUESTING,
    SUPPLY_PUT_SUCCESS
} from "./constants";

const initialState = {
    requestingGet: false,
    successGet: false,
    requestingPost: false,
    successPost: false,
    requestingPut: false,
    successPut: false,
    requestingDelete: false,
    successDelete: false,
    requestingSearch: false,
    successSearch: false,
    requestingStates: false,
    successStates: false,

    error: '',

    filter: 'Todos',
    paginate: 1,
    hasMore: true,
    hasLess: false,

    supplies: [],
    supply: {},
    search: '',
    suppliesSearch: [],
    statesSupplies: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIES_GET_REQUESTING:
            return {
                ...state,
                requestingGet: true,
                successGet: false,
                filter: action.filter,
                paginate: action.paginate,
            };
        case SUPPLIES_GET_SUCCESS:
            return {
                ...state,
                requestingGet: false,
                successGet: true,
                hasLess: state.paginate !== 1,
                hasMore: true,
                supplies: action.supplies,
            };
        case SUPPLIES_GET_ERROR:
            return {
                ...state,
                requestingGet: false,
                error: action.error,
                hasMore: false,
                hasLess: state.paginate !== 1,
                supplies: [],
            };
        case SUPPLY_POST_REQUESTING:
            return {
                ...state,
                requestingPost: true,
                successPost: false,
            };
        case SUPPLY_POST_SUCCESS:
            return {
                ...state,
                requestingPost: false,
                successPost: true,
                supplies: [action.supply, ...state.supplies],
                suppliesSearch: state.suppliesSearch.length > 0
                    ? [action.supply, ...state.suppliesSearch]
                    : state.suppliesSearch,
            };
        case SUPPLY_POST_ERROR:
            return {
                ...state,
                requestingPost: false,
                error: action.error,
            };
        case SUPPLY_FOCUS:
            return {
                ...state,
                supply: action.supply,
            };
        case SUPPLY_FOCUS_CLEAN:
            return {
                ...state,
                supply: {},
            };
        case SUPPLY_PUT_REQUESTING:
            return {
                ...state,
                requestingPut: true,
                successPut: false,
            };
        case SUPPLY_PUT_SUCCESS:
            return {
                ...state,
                requestingPut: false,
                successPut: true,
                supplies: state.supplies.map(supply => supply.id === action.supply.id ? action.supply : supply),
                suppliesSearch: state.suppliesSearch.length > 0
                    ? state.suppliesSearch.map(supply => supply.id === action.supply.id ? action.supply : supply)
                    : state.suppliesSearch,
            };
        case SUPPLY_PUT_ERROR:
            return {
                ...state,
                requestingPut: false,
                error: action.error,
            };
        case SUPPLY_DELETE_REQUESTING:
            return {
                ...state,
                requestingDelete: true,
                successDelete: false,
            };
        case SUPPLY_DELETE_SUCCESS:
            return {
                ...state,
                requestingDelete: false,
                successDelete: true,
                supplies: state.supplies.map(supply => supply.id === action.supply.id ? action.supply : supply),
                suppliesSearch: state.suppliesSearch.length > 0
                    ? state.suppliesSearch.map(supply => supply.id === action.supply.id ? action.supply : supply)
                    : state.suppliesSearch,
            };
        case SUPPLY_DELETE_ERROR:
            return {
                ...state,
                requestingDelete: false,
                error: action.error,
            };
        case SUPPLIES_SEARCH_REQUESTING:
            return {
                ...state,
                requestingSearch: true,
                successSearch: false,
                search: action.search,
            };
        case SUPPLIES_SEARCH_SUCCESS:
            return {
                ...state,
                requestingSearch: false,
                successSearch: true,
                suppliesSearch: action.supplies,
            };
        case SUPPLIES_SEARCH_ERROR:
            return {
                ...state,
                requestingSearch: false,
                error: action.error,
            };
        case STATES_SUPPLIES_GET_REQUESTING:
            return {
                ...state,
                requestingStates: true,
                successStates: false,
            };
        case STATES_SUPPLIES_GET_SUCCESS:
            return {
                ...state,
                requestingStates: false,
                successStates: true,
                statesSupplies: action.states,
            };
        case STATES_SUPPLIES_GET_ERROR:
            return {
                ...state,
                requestingStates: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;