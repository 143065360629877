import React from 'react';
import TableDesignValue from "../table/table-design-value";
import FilterConfigComponent from "../../filter/filter-config-design";

export class ConfigurationComponentDesign
    extends React.Component {

    stopPropagation = (e) => {
        e.stopPropagation();
    };

// *******************************

    render() {
        return (
            <div className="styles-container">
                <div className="conteiner-page-sector1">
                    <div className="styles-title-page-h1">
                        <h1>Configuración servicio diseño</h1>
                        <FilterConfigComponent
                            type={'diseño'}
                        />
                    </div>
                    <TableDesignValue/>
                </div>
            </div>
        )
    }
}
