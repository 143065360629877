import {
    GET_OTHER_PROJECT_ERROR,
    GET_OTHER_PROJECT_REQUESTING, GET_OTHER_PROJECT_SUCCESS,
    GET_PROJECT_ERROR,
    GET_PROJECT_REQUESTING,
    GET_PROJECT_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    projects: [],
    otherProjects: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_REQUESTING:
            return{
              ...state,
              requesting: true
            };
        case GET_PROJECT_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              projects: action.projects
            };
        case GET_PROJECT_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error,
            };
        case  GET_OTHER_PROJECT_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case GET_OTHER_PROJECT_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                otherProjects: action.otherProjects
            };
        case GET_OTHER_PROJECT_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default reducer;
