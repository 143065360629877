import React from 'react';
import {connect} from 'react-redux';
import {Field} from "redux-form";
import {getBrandRequesting} from "../../redux/brand/actions";

class BrandComponent extends React.Component {

    componentWillMount() {
        const {
            client: {token},
            getBrandRequesting,
        } = this.props;
        getBrandRequesting(token);
    }

    render() {
        const {
            brand: {brands},
            InitialMarca
        } = this.props;
        return (
            <Field name="marca" type="select" className="select-brand" component="select">
                {InitialMarca ? <option value={InitialMarca.nombre}>{InitialMarca.nombre}</option> :
                    <option>...Seleccione</option>}
                {brands.length > 0 && brands.map((brand, index) => {
                    if (InitialMarca === undefined)
                        return <option key={index} value={brand.nombre}>{brand.nombre}</option>
                    else if (brand.id !== InitialMarca.id)
                        return <option key={index} value={brand.nombre}>{brand.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    brand: state.brand,
});

const connected = connect(mapStateToProps, {
    getBrandRequesting
})(BrandComponent);

export default connected;