import React from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {getAllProviderRequesting} from "../../redux/provider/actions";

class ProviderComponent extends React.Component {

    componentWillMount() {
        const {
            client: {token},
            getAllProviderRequesting,
        } = this.props;
        getAllProviderRequesting(token);
    }

    render() {
        const {
            provider: {providers},
            InitialProvider
        } = this.props;
        return (
            <Field name="proveedor" type="select" className="select-provider" component="select">
                {InitialProvider ? <option value={InitialProvider.id}>{InitialProvider.nombre}</option> :
                    <option>...Seleccione</option>}
                {providers.length > 0 && providers.map((provider, index) => {
                    if (InitialProvider === undefined)
                        return <option key={index} value={provider.id}>{provider.nombre}</option>
                    else if (provider.id !== InitialProvider.id)
                        return <option key={index} value={provider.id}>{provider.nombre}</option>
                })}
            </Field>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    provider: state.provider,
});

const connected = connect(mapStateToProps, {
    getAllProviderRequesting
})(ProviderComponent);

export default connected;