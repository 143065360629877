import {
    CONFIRMED_WIRE_TRANSFER_ORDER_PUT_ERROR,
    CONFIRMED_WIRE_TRANSFER_ORDER_PUT_REQUESTING, CONFIRMED_WIRE_TRANSFER_ORDER_PUT_SUCCESS,
    ORDER_CHANGE_STATE_ERROR,
    ORDER_CHANGE_STATE_REQUESTING,
    ORDER_CHANGE_STATE_SUCCESS,
    ORDER_SEARCH_ERROR,
    ORDER_SEARCH_REQUESTING,
    ORDER_SEARCH_SUCCESS,
    ORDER_SHIPPING_PUT_ERROR,
    ORDER_SHIPPING_PUT_REQUESTING,
    ORDER_SHIPPING_PUT_SUCCESS, ORDER_STATE_POST_ERROR,
    ORDER_STATE_POST_REQUESTING, ORDER_STATE_POST_SUCCESS,
    ORDERS_GET_ERROR,
    ORDERS_GET_REQUESTING,
    ORDERS_GET_SUCCESS,
    SET_FOCUS_ORDER,
    UPDATE_STATE_ORDER_ERROR,
    UPDATE_STATE_ORDER_REQUESTING,
    UPDATE_STATE_ORDER_SUCCESS
} from "./constants";


export const orderShippingPutRequesting = (values, token, id) => ({
    type: ORDER_SHIPPING_PUT_REQUESTING,
    values, token, id,
});

export const orderShippingPutSuccess = (order) => ({
    type: ORDER_SHIPPING_PUT_SUCCESS,
    order,
});

export const orderShippingPutError = (error) => ({
    type: ORDER_SHIPPING_PUT_ERROR,
    error,
});

export const orderStatePostRequesting = (token, order, state) => ({
    type: ORDER_STATE_POST_REQUESTING,
    token, order, state,
});

export const orderStatePostSuccess = (order) => ({
    type: ORDER_STATE_POST_SUCCESS,
    order,
});

export const orderStatePostError = (error) => ({
    type: ORDER_STATE_POST_ERROR,
    error,
});

export const setFocusOrder = (id, order = {})=>({
    type: SET_FOCUS_ORDER,
    id,
    order,
});

//acciones para obtener datos
export const ordersGetRequesting = (paginate, filter, token)=> ({
    type: ORDERS_GET_REQUESTING,
    paginate,
    filter,
    token
});
export const ordersGetSuccess = (orders) => ({
    type: ORDERS_GET_SUCCESS,
    orders
});
export const ordersGetError = (error) => ({
    type: ORDERS_GET_ERROR,
    error
});

//acciones para cambiar estado
export const orderChangeStateRequesting = (orderId, token) => ({
    type: ORDER_CHANGE_STATE_REQUESTING,
    orderId,
    token
});
export const orderChangeStateSuccess = (order) => ({
    type: ORDER_CHANGE_STATE_SUCCESS,
    order
});
export const orderChangeStateError = (error) => ({
    type: ORDER_CHANGE_STATE_ERROR,
    error
});

//acciones para consultar dato
export const orderSearchRequesting = (search, token) => ({
    type: ORDER_SEARCH_REQUESTING,
    search,
    token
});
export const orderSearchSuccess = (order) => ({
    type: ORDER_SEARCH_SUCCESS,
    order
});
export const orderSearchError = () => ({
    type: ORDER_SEARCH_ERROR,
});

//
export const updateStateOrderRequesting = (token,values) => ({
    type: UPDATE_STATE_ORDER_REQUESTING,
    token,
    values
});
export const updateStateOrderSuccess = (updateStateOrder) => ({
    type: UPDATE_STATE_ORDER_SUCCESS,
    updateStateOrder
});
export const updateStateOrderError = (error) => ({
    type: UPDATE_STATE_ORDER_ERROR,
    error
});

export const confirmedWireTransferOrderPutRequesting = (token, orderId) => ({
    type: CONFIRMED_WIRE_TRANSFER_ORDER_PUT_REQUESTING,
    token, orderId,
});

export const confirmedWireTransferOrderPutSuccess = (order) => ({
    type: CONFIRMED_WIRE_TRANSFER_ORDER_PUT_SUCCESS,
    order,
});

export const confirmedWireTransferOrderPutError = (error) => ({
    type: CONFIRMED_WIRE_TRANSFER_ORDER_PUT_ERROR,
    error,
});
