import {
    GET_USER_REQUESTING,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_USER_REQUESTING,
    CHANGE_STATE_USER_REQUESTING,
    CHANGE_STATE_USER_SUCCESS,
    CHANGE_STATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER_REQUESTING,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    SET_FOCUS_USER,
    DELETE_DEVICE_USER,
    DELETE_DEVICE_REQUESTING,
    DELETE_DEVICE_SUCCESS,
    DELETE_DEVICE_ERROR
} from './constants';

const initialState = {
    requesting: false,
    hasLess: false,
    cursor: 1,
    error: '',
    success: false,
    search: '',
    user: [],
    userId: 0,
    filter: 'Todos',
    hasMore: true,
    users: [],
    created: false,
    update: false,
    focusUser: 0,
    userDataFocus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_REQUESTING :
            return {
                ...state,
                cursor: action.cursor,
                filter: action.filter
            };
        case GET_USER_SUCCESS :
            return {
                ...state,
                users: action.users,
                hasLess: state.cursor + 1 > 1,
                hasMore: true

            };
        case GET_USER_ERROR :
            return {
                ...state,
                error: action.error,
                hasMore: false,
                users: []
            };
        case SEARCH_USER_REQUESTING:
            return {
                ...state,
                search: action.search,
            };
        case SEARCH_USER_SUCCESS:

            return {
                ...state,
                user: action.user
            };
        case SEARCH_USER_ERROR:
            return {
                ...state,
                user: []
            };
        case CHANGE_STATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                userId: action.userId
            };
        case CHANGE_STATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                )),
                user: state.user.length > 0 && state.user.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                ))
            };
        case CHANGE_STATE_USER_ERROR:
            return {
                ...state,
                requesting: false
            };
        case CREATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                created: false
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: [action.userCreated, ...state.users],
                requesting: false,
                success: true,
                created: true,
            };
        case CREATE_USER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                created: false,
                error: action.error
            };
        case UPDATE_USER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
                update: false
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                update: true,
                error: '',
                user: state.user.map((user) => user.id === action.userUpdate.id ? action.userUpdate : user),
                users: state.users.map((user) => user.id === action.userUpdate.id ? action.userUpdate : user)
            };
        case UPDATE_USER_ERROR:

            return {
                ...state,
                requesting: false,
                success: false,
                update: false,
                error: action.error
            };
        case SET_FOCUS_USER :
            return {
                ...state,
                focusUser: action.id,
                userDataFocus: action.user,
                success: false,
                update: false
            };
        case DELETE_DEVICE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case DELETE_DEVICE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
               users: state.users.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                )),
                user: state.user.length > 0 && state.user.map(userItem => (
                    userItem.id === action.user.id ? action.user : userItem
                ))
            };
        case DELETE_DEVICE_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        default :
            return state;
    }
}

export default reducer;
