import React from "react";
import { Field, reduxForm } from "redux-form";
import connect from "react-redux/es/connect/connect";
import { updateUserRequesting } from "../../../redux/user/actions";
import { getCityCountryRequesting } from "../../../commons/countries/actions";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";

class ModalClient extends React.Component {
  handleGetCities = (id) => {
    const {
      getCityCountryRequesting,
      client: { token },
    } = this.props;
    getCityCountryRequesting(id, token);
  };
  handleAction = (values) => {
    const {
      client,
      updateUserRequesting,
      user: { focusUser },
    } = this.props;

    // Validar el campo "telefono"
    if (values.telefono.length !== 12 && values.telefono.length !== 10) {
      toastr.error(
        "Error",
        "El número de celular debe tener 9 o 12 caracteres."
      );
      return;
    }
    updateUserRequesting(values, client.token, focusUser);
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    const {
      user: { error },
    } = this.props;

    if (nextProps.user.error !== "" && nextProps.user.error !== error) {
      let toastOptions = {
        component: <ErrorComponent message={nextProps.user.error} />,
      };
      toastr.error("Error", toastOptions);
    }
    if (nextProps.user.update) {
      toastr.success(
        "Cliente editado",
        "Cliente editado, ya puede ver los clientes"
      );
      this.props.hidden();
    }
  }

  render() {
    const {
      handleSubmit,
      initialValues,
      country: { countries, cities },
      typeIdentification: { typeIdentifications },
    } = this.props;

    return (
      <div className={"body-blur"} onClick={this.props.hidden.bind(this)}>
        <div className="edit-modal" onClick={this.props.stop.bind(this)}>
          <div className="button-container-close">
            <button
              className="Btn-modal-cancel "
              onClick={this.props.hidden.bind(this)}
            >
              <i className="icon-cancel" />
            </button>
          </div>
          <h1 className="arreglojulian">Editar cliente</h1>
          <form onSubmit={handleSubmit(this.handleAction)}>
            <div className="item-modal">
              <label>Nombre: </label>
              <Field
                name={"nombre"}
                type="text"
                className="input input-name"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Tipo de identificación: </label>
              <Field
                component={"select"}
                name="tipo_identificacion"
                className="select-rol"
              >
                <option value={initialValues.tipo_identificacion.id}>
                  {initialValues.tipo_identificacion.nombre}
                </option>
                {typeIdentifications.map((type, index) => {
                  if (type.nombre !== initialValues.tipo_identificacion.nombre)
                    return (
                      <option value={type.id} key={index}>
                        {type.nombre}
                      </option>
                    );
                })}
              </Field>
            </div>
            <div className="item-modal">
              <label>Cédula: </label>
              <Field
                name={"identificacion"}
                type="text"
                className="input input-name"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>País: </label>
              <Field
                component={"select"}
                name="pais"
                className="select-rol"
                onChange={(e) => this.handleGetCities(e.target.value)}
              >
                <option value={initialValues.pais.data.id}>
                  {initialValues.pais.data.nombre}
                </option>
                {countries.map((country, index) => {
                  if (country.nombre !== initialValues.pais.data.nombre)
                    return (
                      <option value={country.id} key={index}>
                        {country.nombre}
                      </option>
                    );
                })}
              </Field>
            </div>
            <div className="item-modal">
              <label>Ciudad: </label>
              <Field component={"select"} name="ciudad" className="select-rol">
                <option value={initialValues.ciudad.id}>
                  {initialValues.ciudad.nombre}
                </option>
                {countries.map((city, index) => {
                  if (
                    city.ciudades.data[0].nombre !== initialValues.ciudad.nombre
                  )
                    return (
                      <option value={city.ciudades.data[0].id} key={index}>
                        {city.ciudades.data[0].nombre}
                      </option>
                    );
                })}
              </Field>
            </div>
            <div className="item-modal">
              <label>Dirección: </label>
              <Field
                name={"direccion"}
                type="text"
                className="input input-name"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Celular: </label>
              <Field
                name={"telefono"}
                type="text"
                className="input input-name"
                component={"input"}
              />
            </div>
            <div className="item-modal">
              <label>Correo: </label>
              <Field
                name={"correo_electronico"}
                type="text"
                className="input input-name"
                component={"input"}
                onFocus={(event) => {
                  event.target.setAttribute("autocomplete", "off");
                }}
              />
            </div>
            <div className="item-modal">
              <label>Contraseña:</label>
              <Field
                name={"contrasena"}
                type="password"
                component={"input"}
                onFocus={(event) => {
                  event.target.setAttribute("autocomplete", "off");
                }}
              />
            </div>
            <div className="button-container">
              <div className="btn-container">
                <button
                  className="Btn-modal"
                  onClick={this.props.hidden.bind(this)}
                >
                  Cancelar
                  <i className="icon-cancel styles-icons" />
                </button>
                <button className="Btn-modal" type={"submit"}>
                  Editar cliente
                  <i className="icon-ok styles-icons" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const formed = reduxForm({
  form: "editUser",
})(ModalClient);

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  country: state.country,
  typeIdentification: state.typeIdentification,
  initialValues: state.user.userDataFocus,
});

const connected = connect(mapStateToProps, {
  updateUserRequesting,
  getCityCountryRequesting,
})(formed);

export default connected;
