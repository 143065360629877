import React from 'react';
import {connect} from 'react-redux';
import SearchComponent from "../search/search";
import FilterComponent from "../filter/filter";
import ModalAddSupplies from "./modals/add-supplies";
import ModalEditSupplies from "./modals/edit-supplies"
import {TableSupplies} from "./tables/table-supplies";
import {NotFound} from "../../commons/errors/notFound";
import {
    suppliesGetRequesting,
    supplyPostRequesting,
    supplyFocus,
    supplyPutRequesting,
    supplyDeleteRequesting,
    suppliesSearchRequesting,
    statesSuppliesGetRequesting,
} from "../../redux/supply/actions";

class SupplyComponent extends React.Component {

    state = {
        isAdd: false,
        isEdit: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isAdd: modal === 'nuevo suministro',
            isEdit: modal === 'edit',
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isAdd: false,
            isEdit: false,
        })
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    handleFocus = (supply) => {
        const {
            supplyFocus,
        } = this.props;
        supplyFocus(supply);
    };

    componentWillMount() {
        const {
            client: {token},
            supply: {filter, paginate},
            suppliesGetRequesting,
            statesSuppliesGetRequesting
        } = this.props;
        suppliesGetRequesting(token, filter, paginate);
        statesSuppliesGetRequesting(token);
    }

    handleFilter = (state) => {
        const {
            client: {token},
            suppliesGetRequesting,
            supply: {filter},
        } = this.props;
        if (state !== filter)
            suppliesGetRequesting(token, state, 1);
    };

    handleGetData = (number) => {
        const {
            client: {token},
            suppliesGetRequesting,
            supply: {filter, paginate, hasMore, hasLess},
        } = this.props;
        if (number === 1 && hasMore)
            suppliesGetRequesting(token, filter, paginate + number);
        if (number === -1 && hasLess)
            suppliesGetRequesting(token, filter, paginate + number);
    };

    handleGetCsv = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/exports/supplies`, "_self")
    };

    handleChangeState = (supply) => {
        const {
            client: {token},
            supplyDeleteRequesting
        } = this.props;
        supplyDeleteRequesting(token, supply)
    };

    handleSearch = (value) => {
        const {
            client: {token},
            suppliesSearchRequesting,
            supply: {search}
        } = this.props;
        if (search !== value.search)
            suppliesSearchRequesting(token, value.search);
    };

    render() {
        const {
            supply: {supplies, supply, search, suppliesSearch, statesSupplies},
        } = this.props;
        const {isAdd, isEdit} = this.state;
        return (
            <div className="styles-container">
                <SearchComponent
                    type={'Suministros'}
                    placeholder={'Consultar'}
                    handleSearch={this.handleSearch.bind(this)}>
                    {search !== '' && (
                        suppliesSearch.length > 0
                            ? <TableSupplies handleShowModal={this.handleShowModal.bind(this)}
                                             handleChangeState={this.handleChangeState.bind(this)}
                                             handleFocus={this.handleFocus.bind(this)}
                                             supplies={suppliesSearch}
                            />
                            : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        activeModal={true}
                        type={'nuevo suministro'}
                        states={statesSupplies}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        getData={this.handleGetData.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                    />
                    {supplies.length > 0
                        ? <TableSupplies handleShowModal={this.handleShowModal.bind(this)}
                                         handleChangeState={this.handleChangeState.bind(this)}
                                         handleFocus={this.handleFocus.bind(this)}
                                         supplies={supplies}/>
                        : <NotFound/>
                    }

                    {isAdd && (
                        <ModalAddSupplies stop={this.stopPropagation.bind(this)}
                                          hidden={this.handleHiddenModal.bind(this)}
                        />
                    )}

                    {isEdit && (
                        <ModalEditSupplies stop={this.stopPropagation.bind(this)}
                                           hidden={this.handleHiddenModal.bind(this)}
                                           supply={supply}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    client: state.client,
    supply: state.supply,
});

const connected = connect(mapStateToProps, {
    suppliesGetRequesting,
    supplyPostRequesting,
    supplyFocus,
    supplyPutRequesting,
    supplyDeleteRequesting,
    suppliesSearchRequesting,
    statesSuppliesGetRequesting,
})(SupplyComponent);

export default connected