import {UNIT_MEASURES_GET_ERROR, UNIT_MEASURES_GET_REQUESTING, UNIT_MEASURES_GET_SUCCESS} from "./constants";

export const unitMeasuresGetRequesting = (token) => ({
    type: UNIT_MEASURES_GET_REQUESTING,
    token,
});

export const unitMeasuresGetSuccess = (unitMeasures) => ({
    type: UNIT_MEASURES_GET_SUCCESS,
    unitMeasures,
});

export const unitMeasuresGetError = (error) => ({
    type: UNIT_MEASURES_GET_ERROR,
    error,
});