import {handleApiErrors} from "../../commons/errors/apiErrors";
import {call, put, all, takeEvery} from "redux-saga/effects";
import {
    changeStateLeaderManagerError,
    changeStateLeaderManagerSuccess,
    createLeaderManagerError,
    createLeaderManagerSuccess,
    deleteCategoryLeaderManagerError,
    deleteCategoryLeaderManagerSuccess, deleteDeviceLeaderManagerError,
    deleteDeviceLeaderManagerSuccess,
    deleteSubcategoryLeaderManagerError,
    deleteSubcategoryLeaderManagerSuccess,
    getCategoryLeaderManagerError,
    getCategoryLeaderManagerSuccess,
    getLeaderManagerError,
    getLeaderManagerSuccess,
    getSubcategoryLeaderManagerError,
    getSubcategoryLeaderManagerSuccess,
    searchLeaderManagerError,
    searchLeaderManagerSuccess,
    updateLeaderManagerError,
    updateLeaderManagerSuccess
} from "./actions";
import {
    CHANGE_STATE_LEADER_MANAGER_REQUESTING,
    CREATE_LEADER_MANAGER_REQUESTING,
    DELETE_CATEGORY_LEADER_MANAGER_REQUESTING,
    DELETE_DEVICE_LEADER_MANAGER_REQUESTING,
    DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    GET_CATEGORY_LEADER_MANAGER_REQUESTING,
    GET_LEADER_MANAGER_REQUESTING,
    GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    SEARCH_LEADER_MANAGER_REQUESTING,
    UPDATE_LEADER_MANAGER_REQUESTING
} from "./constants";
import {toastr} from "react-redux-toastr";

const getLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/filter`;
const stateLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/states/leadermanagers/`;
const searchLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/search`;
const createLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers`;
const updateLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers`;
const getCategoryLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/category`;
const deleteCategoryLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/category`;
const getSubcategoryLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/subCategory`;
const deleteSubcategoryLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/leadermanagers/subCategory`;
const deleteDeviceLeaderManagerUrl = `${process.env.REACT_APP_API_URL}/api/delete/token/leader`;

const getLeaderManagerAPI = (cursor, filter, token) => {

    return fetch(`${getLeaderManagerUrl}/${filter}?page=${cursor}`, {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.code === 400)
                throw  json.data.data;
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getLeaderManagerFlow(action) {
    try {
        const {cursor, filter, token} = action;
        const leaderManagers = yield call(getLeaderManagerAPI, cursor, filter, token);
        yield put(getLeaderManagerSuccess(leaderManagers))
    } catch (error) {
        yield put(getLeaderManagerError(error))
    }
}

const stateLeaderManagerAPI = (leaderManagerId, token) => {

    return fetch(stateLeaderManagerUrl + leaderManagerId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* stateLeaderManagerFlow(action) {
    try {
        const {leaderManagerId, token} = action;
        const leaderManager = yield call(stateLeaderManagerAPI, leaderManagerId, token);
        yield put(changeStateLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(changeStateLeaderManagerError(error))
    }
}

const searchLeaderManagerAPI = (search, token) => {

    let body = {
        search: search
    };

    return fetch(searchLeaderManagerUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })

};

function* searchLeaderManagerFlow(action) {
    try {
        const {search, token} = action;
        const leaderManager = yield call(searchLeaderManagerAPI, search, token);
        yield put(searchLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(searchLeaderManagerError(error));
    }
}

const createLeaderManagerAPI = (leaderManager, token) => {

    let body = new FormData();
    body.append('name', leaderManager.name);
    body.append('identification_number', leaderManager.identification_number);
    body.append('password', leaderManager.password);
    body.append('address', leaderManager.address);
    body.append('phone', leaderManager.phone);
    body.append('email', leaderManager.email);
    if (leaderManager.photo)
        body.append('photo', leaderManager.photo[0].file || '');
    body.append('city_id', leaderManager.city_id);
    body.append('country_id', leaderManager.country_id);
    body.append('contact_name', leaderManager.contact_name);
    body.append('contact_number', leaderManager.contact_number);
    if (leaderManager.leaderManager_documents) {
        leaderManager.leaderManager_documents.map((fileItem, index) => body.append(`document_${index}`, fileItem.file));
        body.append('lenght', leaderManager.leaderManager_documents.length);
    }

    return fetch(createLeaderManagerUrl, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createLeaderManagerFlow(action) {
    try {
        const {leaderManager, token} = action;
        const leaderManagerCreated = yield call(createLeaderManagerAPI, leaderManager, token);
        yield put(createLeaderManagerSuccess(leaderManagerCreated));
    } catch (error) {
        yield put(createLeaderManagerError(error));
    }
}

const updateLeaderManagerAPI = (leaderManager, token) => {
    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('name', leaderManager.usuario.data[0].nombre || '');
    body.append('identification_number', leaderManager.usuario.data[0].identificacion || '');
    body.append('address', leaderManager.usuario.data[0].direccion || '');
    body.append('phone', leaderManager.usuario.data[0].telefono || '');
    body.append('email', leaderManager.usuario.data[0].correo_electronico || '');
    if (leaderManager.foto !== undefined) {
        body.append('photo', leaderManager.foto[0].file || '');
    }
    body.append('city_id', leaderManager.usuario.data[0].ciudad.id || '');
    body.append('country_id', leaderManager.usuario.data[0].pais.data.id || '');
    body.append('contact_name', leaderManager.nombre_contacto || '');
    body.append('contact_number', leaderManager.telefono_contacto || '');
    return fetch(`${updateLeaderManagerUrl}/${leaderManager.id}/${leaderManager.usuario.data[0].id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateLeaderManagerFlow(action) {
    try {
        const {leaderManager, token, id_user, id_leader_manager} = action;
        const leaderManagerUpdate = yield call(updateLeaderManagerAPI, leaderManager, token, id_user, id_leader_manager);
        yield put(updateLeaderManagerSuccess(leaderManagerUpdate));
    } catch (error) {
        yield put(updateLeaderManagerError(error));
    }
}

const getCategoryLeaderManagerAPI = (token, id_leader_manager, id_category) => {


    return fetch(`${getCategoryLeaderManagerUrl}/${id_leader_manager}/${id_category}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getCategoryLeaderManagerFlow(action) {
    try {
        const {token, id_leader_manager, id_category} = action;
        const leaderManager = yield call(getCategoryLeaderManagerAPI, token, id_leader_manager, id_category);
        yield put(getCategoryLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(getCategoryLeaderManagerError(error));
    }
}

const deleteCategoryLeaderManagerAPI = (token, id_leader_manager, id_category) => {

    return fetch(`${deleteCategoryLeaderManagerUrl}/${id_leader_manager}/${id_category}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* deleteCategoryLeaderManagerFlow(action) {
    try {
        const {token, id_leader_manager, id_category} = action;
        const leaderManager = yield call(deleteCategoryLeaderManagerAPI, token, id_leader_manager, id_category);
        yield put(deleteCategoryLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(deleteCategoryLeaderManagerError(error));
    }
}

const getSubcategoryLeaderManagerAPI = (token, id_leader_manager, id_subcategory) => {


    return fetch(`${getSubcategoryLeaderManagerUrl}/${id_leader_manager}/${id_subcategory}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* getSubcategoriesLeaderManagerFlow(action) {
    try {
        const {token, id_leader_manager, id_subcategory} = action;
        const leaderManager = yield call(getSubcategoryLeaderManagerAPI, token, id_leader_manager, id_subcategory);
        yield put(getSubcategoryLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(getSubcategoryLeaderManagerError(error));
    }
}

const deleteSubcategoryLeaderManagerAPI = (token, id_leader_manager, id_subcategory) => {

    return fetch(`${deleteSubcategoryLeaderManagerUrl}/${id_leader_manager}/${id_subcategory}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* deleteSubcategoryLeaderManagerFlow(action) {
    try {
        const {token, id_leader_manager, id_subcategory} = action;
        const leaderManager = yield call(deleteSubcategoryLeaderManagerAPI, token, id_leader_manager, id_subcategory);
        yield put(deleteSubcategoryLeaderManagerSuccess(leaderManager));
    } catch (error) {
        yield put(deleteSubcategoryLeaderManagerError(error));
    }
}

const deleteDeviceLeaderManagerAPI = (id) => {

    return fetch(`${deleteDeviceLeaderManagerUrl}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* deleteDeviceUserFlow(action) {
    try {
        const {id} = action;
        const leaderManager = yield call(deleteDeviceLeaderManagerAPI, id);
        yield put(deleteDeviceLeaderManagerSuccess(leaderManager));
        toastr.success('Listo', 'Token Eliminado');
    } catch (error) {
        yield put(deleteDeviceLeaderManagerError(error));
    }
}

function* leaderManagerWatcher() {
    yield all([
        takeEvery(GET_LEADER_MANAGER_REQUESTING, getLeaderManagerFlow),
        takeEvery(CHANGE_STATE_LEADER_MANAGER_REQUESTING, stateLeaderManagerFlow),
        takeEvery(SEARCH_LEADER_MANAGER_REQUESTING, searchLeaderManagerFlow),
        takeEvery(CREATE_LEADER_MANAGER_REQUESTING, createLeaderManagerFlow),
        takeEvery(UPDATE_LEADER_MANAGER_REQUESTING, updateLeaderManagerFlow),
        takeEvery(GET_CATEGORY_LEADER_MANAGER_REQUESTING, getCategoryLeaderManagerFlow),
        takeEvery(DELETE_CATEGORY_LEADER_MANAGER_REQUESTING, deleteCategoryLeaderManagerFlow),
        takeEvery(GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING, getSubcategoriesLeaderManagerFlow),
        takeEvery(DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING, deleteSubcategoryLeaderManagerFlow),
        takeEvery(DELETE_DEVICE_LEADER_MANAGER_REQUESTING, deleteDeviceUserFlow)
    ])
}


export default leaderManagerWatcher;
