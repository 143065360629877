import {
    CHANGE_STATE_LEADER_MANAGER_ERROR,
    CHANGE_STATE_LEADER_MANAGER_REQUESTING,
    CHANGE_STATE_LEADER_MANAGER_SUCCESS,
    CREATE_LEADER_MANAGER_ERROR,
    CREATE_LEADER_MANAGER_REQUESTING,
    CREATE_LEADER_MANAGER_SUCCESS,
    DELETE_CATEGORY_LEADER_MANAGER_ERROR,
    DELETE_CATEGORY_LEADER_MANAGER_REQUESTING,
    DELETE_CATEGORY_LEADER_MANAGER_SUCCESS, DELETE_DEVICE_LEADER_MANAGER_ERROR,
    DELETE_DEVICE_LEADER_MANAGER_REQUESTING, DELETE_DEVICE_LEADER_MANAGER_SUCCESS,
    DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR,
    DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    GET_CATEGORY_LEADER_MANAGER_ERROR,
    GET_CATEGORY_LEADER_MANAGER_REQUESTING,
    GET_CATEGORY_LEADER_MANAGER_SUCCESS,
    GET_LEADER_MANAGER_ERROR,
    GET_LEADER_MANAGER_REQUESTING,
    GET_LEADER_MANAGER_SUCCESS,
    GET_SUBCATEGORY_LEADER_MANAGER_ERROR,
    GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    SEARCH_LEADER_MANAGER_ERROR,
    SEARCH_LEADER_MANAGER_REQUESTING,
    SEARCH_LEADER_MANAGER_SUCCESS,
    SET_FOCUS_LEADER_MANAGER,
    UPDATE_DOCUMENT_LEADER_MANAGER,
    UPDATE_LEADER_MANAGER,
    UPDATE_LEADER_MANAGER_ERROR,
    UPDATE_LEADER_MANAGER_REQUESTING,
    UPDATE_LEADER_MANAGER_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    cursor: 1,
    filter: 'Todos',
    leaderManagers : [],
    leaderManager: [],
    error: '',
    hasMore: true,
    hasLess: false,
    leaderManagerId: 0,
    search: '',
    focusLeaderManager: 0,
    leaderManagerDataFocus: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEADER_MANAGER_REQUESTING:
            return{
              ...state,
              cursor: action.cursor,
              filter: action.filter
            };
        case GET_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              leaderManagers: action.leaderManagers,
              hasLess: state.cursor + 1 > 1,
              hasMore: true
            };
        case GET_LEADER_MANAGER_ERROR:
            return {
              ...state,
              error: action.error,
              hasMore: false,
              leaderManagers: []
            };
        case CHANGE_STATE_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
              requesting: true,
              leaderManagerId: action.leaderManagerId
            };
        case CHANGE_STATE_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              requesting: false,
              leaderManagers: state.leaderManagers.map(leaderManagerItem => (
                  leaderManagerItem.id === action.leaderManager.id ? action.leaderManager : leaderManagerItem
              )),
              leaderManager: state.leaderManager.map(leaderManagerItem => (
                  leaderManagerItem.id === action.leaderManager.id ? action.leaderManager : leaderManagerItem
              ))
            };
        case CHANGE_STATE_LEADER_MANAGER_ERROR:
            return {
              ...state,
              requesting: false
            };
        case SEARCH_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
              search: action.search
            };
        case SEARCH_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              leaderManager: action.leaderManager
            };
        case SEARCH_LEADER_MANAGER_ERROR:
            return {
              ...state,
              leaderManager: []
            };
        case CREATE_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
              requesting: true,
              error: '',
              success: false
            };
        case CREATE_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              leaderManagers: [action.leaderManagerCreated, ...state.leaderManagers]
            };
        case CREATE_LEADER_MANAGER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case SET_FOCUS_LEADER_MANAGER:
            return {
              ...state,
              focusLeaderManager: action.id,
              leaderManagerDataFocus: action.leaderManager
            };
        case UPDATE_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: '',
            };
        case UPDATE_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === state.focusLeaderManager
                                ? action.leaderManagerUpdate : leaderManager),
              leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === state.focusLeaderManager
                                ? action.leaderManagerUpdate : leaderManager),

            };
        case UPDATE_LEADER_MANAGER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case GET_CATEGORY_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
              requesting: true,
              success: false,
              error: ''
            };
        case GET_CATEGORY_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
              leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
            };
        case GET_CATEGORY_LEADER_MANAGER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case DELETE_CATEGORY_LEADER_MANAGER_REQUESTING:
            return {
              ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case DELETE_CATEGORY_LEADER_MANAGER_SUCCESS:
            return {
              ...state,
              requesting: false,
              success: true,
              error: '',
              leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
              leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
            };
        case DELETE_CATEGORY_LEADER_MANAGER_ERROR:
            return {
              ...state,
              requesting: false,
              success: false,
              error: action.error
            };
        case UPDATE_LEADER_MANAGER:
            return {
              ...state,
                leaderManagers: state.leaderManagers.map(leaderManagerItem => {
                    leaderManagerItem.documentos.data.map((document, index) => {
                        if (document.id === action.documentDeleted) {
                            return leaderManagerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return leaderManagerItem;
                }),
                leaderManager: state.leaderManager.map(leaderManagerItem => {
                    leaderManagerItem.documentos.data.map((document, index) => {
                        if (document.id === action.documentDeleted) {
                            return leaderManagerItem.documentos.data.splice(index, 1);
                        }
                    });
                    return leaderManagerItem;
                })
            };
        case UPDATE_DOCUMENT_LEADER_MANAGER:
            return {
              ...state,
                requesting: false,
                success: true,
                error: '',
                leaderManager: state.leaderManager.map((leaderManager) => action.leaderManager.id === leaderManager.id ?
                    action.leaderManager : leaderManager),
                leaderManagers: state.leaderManagers.map((leaderManager) => action.leaderManager.id === leaderManager.id ?
                    action.leaderManager : leaderManager)
            };
        case GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
                leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
            };
        case GET_SUBCATEGORY_LEADER_MANAGER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                leaderManager: state.leaderManager.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
                leaderManagers: state.leaderManagers.map((leaderManager) => leaderManager.id === action.leaderManager.id
                    ? action.leaderManager : leaderManager),
            };
        case DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        case DELETE_DEVICE_LEADER_MANAGER_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ''
            };
        case DELETE_DEVICE_LEADER_MANAGER_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                error: '',
                leaderManagers: state.leaderManagers.map(leaderManagerItem => (
                    leaderManagerItem.id === action.leaderManager.id ? action.leaderManager : leaderManagerItem
                )),
                leaderManager: state.leaderManager.map(leaderManagerItem => (
                    leaderManagerItem.id === action.leaderManager.id ? action.leaderManager : leaderManagerItem
                ))
            };
        case DELETE_DEVICE_LEADER_MANAGER_ERROR:
            return {
                ...state,
                requesting: false,
                success: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;
