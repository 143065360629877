import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    GET_USER_REQUESTING,
    SEARCH_USER_REQUESTING,
    CHANGE_STATE_USER_REQUESTING,
    CREATE_USER_REQUESTING,
    UPDATE_USER_REQUESTING, DELETE_DEVICE_REQUESTING
} from "./constants";

import {
    searchUserSuccess,
    searchUserError,
    getUserSuccess,
    getUserError,
    changeStateUserSuccess,
    changeStateUserError,
    createUserError,
    createUserSuccess,
    updateUserSuccess,
    updateUserError, deleteDeviceSuccess, deleteDeviceError
} from "./actions";
import {toastr} from "react-redux-toastr";

const usersURL = `${process.env.REACT_APP_API_URL}/api/users`;
const searchUserUrl = `${process.env.REACT_APP_API_URL}/api/users/search`;
const stateChangeUserUrl = `${process.env.REACT_APP_API_URL}/api/states/users/`;
const createUserUrl = `${process.env.REACT_APP_API_URL}/api/auth/app/registerUser`;
const updateUserUrl = `${process.env.REACT_APP_API_URL}/api/users`;
const deleteDeviceUserUrl = `${process.env.REACT_APP_API_URL}/api/delete/token`;

const usersGetApi = (cursor, filter, token) => {
    const url = `${usersURL}/filter/${filter}?page=${cursor}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* usersGetFLow(action) {
    try {
        const {cursor, filter, token} = action;
        const getUsers = yield call(usersGetApi, cursor, filter, token);
        yield put(getUserSuccess(getUsers));
    } catch (error) {
        yield put(getUserError(error));
    }
}

const searchUserAPI = (search, token) => {
    let body = {
        search: search
    };

    return fetch(searchUserUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            else
                throw []
        }).catch((error) => {
            throw error;
        })
};

function* searchUserFlow(action) {
    try {
        const {search, token} = action;
        const user = yield call(searchUserAPI, search, token);
        yield put(searchUserSuccess(user));
    } catch (error) {
        yield put(searchUserError(error));
    }
}

const stateUserAPI = (userId, token) => {

    return fetch(stateChangeUserUrl + userId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* stateUserFlow(action) {
    try {
        const {userId, token} = action;
        const stateUser = yield call(stateUserAPI, userId, token);
        yield put(changeStateUserSuccess(stateUser));
    } catch (error) {
        yield put(changeStateUserError(error));
    }
}

const createUserAPI = (user, token) => {
    let body = new FormData();
    if (user.name !== undefined)
        body.append('nombre', user.name);
    body.append('tipo_identificacion', user.type_identification_id);
    if (user.identification !== undefined)
        body.append('identificacion', user.identification);
    body.append('pais', user.country_id);
  /*   body.append('ciudad', user.city_id); */
    if (user.address !== undefined)
        body.append('direccion', user.address);
    if (user.phone !== undefined)
        body.append('telefono', user.phone);
    if (user.email !== undefined)
        body.append('correo_electronico', user.email);
    if (user.password !== undefined)
        body.append('contraseña', user.password);
        body.append('contraseña_confirmation', user.password);
    // if (course.course_photos) {
    //     course.course_photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
    //     body.append('lenght', course.course_photos.length);
    // }

    return fetch(createUserUrl, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* createUserFlow(action) {
    try {
        const {user, token} = action;
        const userCreated = yield call(createUserAPI, user, token);
        yield put(createUserSuccess(userCreated));
    } catch (error) {
        yield put(createUserError(error));
    }
}

const updateUserAPI = (user, token, id) => {

    let body = new FormData();
    body.append('_method', 'PUT');
    body.append('nombre', user.nombre);
    body.append('direccion', user.direccion);
    body.append('telefono', user.telefono);
    body.append('correo_electronico', user.correo_electronico);
    if (user.contrasena !== undefined)
        body.append('contrasena', user.contrasena);
    body.append('identificacion', user.identificacion);
    body.append('pais', user.pais.hasOwnProperty('data') ? user.pais.data.id : user.pais);
    body.append('ciudad', user.ciudad.id || user.ciudad);
    body.append('tipo_identificacion', user.tipo_identificacion.id || user.tipo_identificacion);
    // if (course.course_photos) {
    //     course.course_photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
    //     body.append('lenght', course.course_photos.length);
    // }


    return fetch(`${updateUserUrl}/put/updateData/${id}`, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {

            if (json.data.hasOwnProperty('id'))
                return json.data.data;
            if (json.code === 422)
                throw json.data;
            if (json.data.code === 400)
                throw {error: json.data.data};
            if (json.code === 200)
                return json.data.data;
        }).catch((error) => {
            throw error;
        })
};

function* updateUserFlow(action) {
    try {
        const {user, token, id} = action;
        const userUpdate = yield call(updateUserAPI, user, token, id);
        yield put(updateUserSuccess(userUpdate));
    } catch (error) {
        yield put(updateUserError(error));
    }
}

const deleteDeviceUserAPI = (id) => {

    return fetch(`${deleteDeviceUserUrl}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.hasOwnProperty('id'))
                return json.data.data;
            else
                throw[]
        }).catch((error) => {
            throw error;
        })
};

function* deleteDeviceUserFlow(action) {
    try {
        const {id} = action;
        const user = yield call(deleteDeviceUserAPI, id);
        yield put(deleteDeviceSuccess(user));
        toastr.success('Listo', 'Token Eliminado');
    } catch (error) {
        yield put(deleteDeviceError(error));
    }
}

function* usersWatcher() {
    yield all([
        takeEvery(GET_USER_REQUESTING, usersGetFLow),
        takeEvery(SEARCH_USER_REQUESTING, searchUserFlow),
        takeEvery(CHANGE_STATE_USER_REQUESTING, stateUserFlow),
        takeEvery(CREATE_USER_REQUESTING, createUserFlow),
        takeEvery(UPDATE_USER_REQUESTING, updateUserFlow),
        takeEvery(DELETE_DEVICE_REQUESTING, deleteDeviceUserFlow)
    ])
}

export default usersWatcher
