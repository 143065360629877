import React from 'react';

export class ModalDocumentsSteps extends React.Component {

    render() {
        const {documents} = this.props;
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="edit-modal" onClick={this.props.stop.bind(this)}>
                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}>
                            <i className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                        <h1>Documentos</h1>
                    </div>
                    <div className="item-1-modal-detail" style={{marginBottom: '10px'}}>
                        {documents.length > 0 && documents.map((document, index) => (
                            <a className="Btn-modal" key={index} href={document.src} target="_blank">
                                {document.nombre}
                            </a>
                        ))}
                    </div>
                    <div className="button-container">
                        <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>
                            Cerrar
                            <i className="icon-cancel styles-icons"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
