export const GET_RATE_REQUESTING = 'GET_RATE_REQUESTING';
export const GET_RATE_SUCCESS = 'GET_RATE_SUCCESS';
export const GET_RATE_ERROR = 'GET_RATE_ERROR';

export const UPDATE_RATE_REQUESTING = 'UPDATE_RATE_REQUESTING';
export const UPDATE_RATE_SUCCESS = 'UPDATE_RATE_SUCCESS';
export const UPDATE_RATE_ERROR = 'UPDATE_RATE_ERROR';

export const GET_RATE_MAINTENANCE_REQUESTING = 'GET_RATE_MAINTENANCE_REQUESTING';
export const GET_RATE_MAINTENANCE_SUCCESS = 'GET_RATE_MAINTENANCE_SUCCESS';
export const GET_RATE_MAINTENANCE_ERROR = 'GET_RATE_MAINTENANCE_ERROR';

export const UPDATE_RATE_MAINTENANCE_REQUESTING = 'UPDATE_RATE_MAINTENANCE_REQUESTING';
export const UPDATE_RATE_MAINTENANCE_SUCCESS = 'UPDATE_RATE_MAINTENANCE_SUCCESS';
export const UPDATE_RATE_MAINTENANCE_ERROR = 'UPDATE_RATE_MAINTENANCE_ERROR';

export const GET_RATE_INSTALLATION_REQUESTING = 'GET_RATE_INSTALLATION_REQUESTING';
export const GET_RATE_INSTALLATION_SUCCESS = 'GET_RATE_INSTALLATION_SUCCESS';
export const GET_RATE_INSTALLATION_ERROR = 'GET_RATE_INSTALLATION_ERROR';

export const UPDATE_RATE_INSTALLATION_REQUESTING = 'UPDATE_RATE_INSTALLATION_REQUESTING';
export const UPDATE_RATE_INSTALLATION_SUCCESS  = 'UPDATE_RATE_INSTALLATION_SUCCESS';
export const UPDATE_RATE_INSTALLATION_ERROR = 'UPDATE_RATE_INSTALLATION_ERROR';

export const GET_RATE_EMERGENCY_CALL_REQUESTING = 'GET_RATE_EMERGENCY_CALL_REQUESTING';
export const GET_RATE_EMERGENCY_CALL_SUCCESS = 'GET_RATE_EMERGENCY_CALL_SUCCESS';
export const GET_RATE_EMERGENCY_CALL_ERROR = 'GET_RATE_EMERGENCY_CALL_ERROR';

export const UPDATE_RATE_EMERGENCY_CALL_REQUESTING = 'UPDATE_RATE_EMERGENCY_CALL_REQUESTING';
export const UPDATE_RATE_EMERGENCY_CALL_SUCCESS = 'UPDATE_RATE_EMERGENCY_CALL_SUCCESS';
export const UPDATE_RATE_EMERGENCY_CALL_ERROR = 'UPDATE_RATE_EMERGENCY_CALL_ERROR';

export const GET_RATE_SUPPLY_REQUESTING = 'GET_RATE_SUPPLY_REQUESTING';
export const GET_RATE_SUPPLY_SUCCESS = 'GET_RATE_SUPPLY_SUCCESS';
export const GET_RATE_SUPPLY_ERROR = 'GET_RATE_SUPPLY_ERROR';

export const UPDATE_RATE_SUPPLY_REQUESTING = 'UPDATE_RATE_SUPPLY_REQUESTING';
export const UPDATE_RATE_SUPPLY_SUCCESS = 'UPDATE_RATE_SUPPLY_SUCCESS';
export const UPDATE_RATE_SUPPLY_ERROR = 'UPDATE_RATE_SUPPLY_ERROR';