import {
    CHANGE_STATE_LEADER_MANAGER_ERROR,
    CHANGE_STATE_LEADER_MANAGER_REQUESTING,
    CHANGE_STATE_LEADER_MANAGER_SUCCESS,
    CREATE_LEADER_MANAGER_ERROR,
    CREATE_LEADER_MANAGER_REQUESTING,
    CREATE_LEADER_MANAGER_SUCCESS,
    DELETE_CATEGORY_LEADER_MANAGER_ERROR,
    DELETE_CATEGORY_LEADER_MANAGER_REQUESTING,
    DELETE_CATEGORY_LEADER_MANAGER_SUCCESS, DELETE_DEVICE_LEADER_MANAGER_ERROR,
    DELETE_DEVICE_LEADER_MANAGER_REQUESTING, DELETE_DEVICE_LEADER_MANAGER_SUCCESS,
    DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR,
    DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    GET_CATEGORY_LEADER_MANAGER_ERROR,
    GET_CATEGORY_LEADER_MANAGER_REQUESTING,
    GET_CATEGORY_LEADER_MANAGER_SUCCESS,
    GET_LEADER_MANAGER_ERROR,
    GET_LEADER_MANAGER_REQUESTING,
    GET_LEADER_MANAGER_SUCCESS,
    GET_SUBCATEGORY_LEADER_MANAGER_ERROR,
    GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    SEARCH_LEADER_MANAGER_ERROR,
    SEARCH_LEADER_MANAGER_REQUESTING,
    SEARCH_LEADER_MANAGER_SUCCESS,
    SET_FOCUS_LEADER_MANAGER,
    UPDATE_DOCUMENT_LEADER_MANAGER,
    UPDATE_LEADER_MANAGER,
    UPDATE_LEADER_MANAGER_ERROR,
    UPDATE_LEADER_MANAGER_REQUESTING,
    UPDATE_LEADER_MANAGER_SUCCESS
} from "./constants";

export const getLeaderManagerRequesting = (cursor, filter, token) => ({
    type: GET_LEADER_MANAGER_REQUESTING,
    cursor,
    filter,
    token
});

export const getLeaderManagerSuccess = (leaderManagers) => ({
    type: GET_LEADER_MANAGER_SUCCESS,
    leaderManagers
});

export const getLeaderManagerError = (error) => ({
    type: GET_LEADER_MANAGER_ERROR,
    error
});

export const changeStateLeaderManagerRequesting = (leaderManagerId, token) => ({
    type: CHANGE_STATE_LEADER_MANAGER_REQUESTING,
    leaderManagerId,
    token
});

export const changeStateLeaderManagerSuccess = (leaderManager) => ({
    type: CHANGE_STATE_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const changeStateLeaderManagerError = (error) => ({
    type: CHANGE_STATE_LEADER_MANAGER_ERROR,
    error
});

export const searchLeaderManagerRequesting = (search, token) => ({
    type: SEARCH_LEADER_MANAGER_REQUESTING,
    search,
    token
});

export const searchLeaderManagerSuccess = (leaderManager) => ({
    type: SEARCH_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const searchLeaderManagerError = () => ({
    type: SEARCH_LEADER_MANAGER_ERROR
});

export const createLeaderManagerRequesting = (leaderManager, token) => ({
    type: CREATE_LEADER_MANAGER_REQUESTING,
    leaderManager,
    token
});

export const createLeaderManagerSuccess = (leaderManagerCreated) => ({
    type: CREATE_LEADER_MANAGER_SUCCESS,
    leaderManagerCreated
});

export const createLeaderManagerError = (error) => ({
    type: CREATE_LEADER_MANAGER_ERROR,
    error
});

export const setFocusLeaderManager = (id = 0, leaderManager = {}) => ({
    type: SET_FOCUS_LEADER_MANAGER,
    id,
    leaderManager
});

export const updateLeaderManagerRequesting = (leaderManager, token, id_user, id_leader_manager) => ({
    type: UPDATE_LEADER_MANAGER_REQUESTING,
    leaderManager,
    token,
    id_user,
    id_leader_manager
});

export const updateLeaderManagerSuccess = (leaderManagerUpdate) => ({
    type: UPDATE_LEADER_MANAGER_SUCCESS,
    leaderManagerUpdate
});

export const updateLeaderManagerError = (error) => ({
    type: UPDATE_LEADER_MANAGER_ERROR,
    error
});

export const getCategoryLeaderManagerRequesting = (token, id_leader_manager, id_category) => ({
    type: GET_CATEGORY_LEADER_MANAGER_REQUESTING,
    token,
    id_leader_manager,
    id_category
});

export const getCategoryLeaderManagerSuccess = (leaderManager) => ({
    type: GET_CATEGORY_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const getCategoryLeaderManagerError = (error) => ({
    type: GET_CATEGORY_LEADER_MANAGER_ERROR,
    error
});

export const deleteCategoryLeaderManagerRequesting = (token, id_leader_manager, id_category) => ({
    type: DELETE_CATEGORY_LEADER_MANAGER_REQUESTING,
    token,
    id_leader_manager,
    id_category
});

export const deleteCategoryLeaderManagerSuccess = (leaderManager) => ({
    type: DELETE_CATEGORY_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const deleteCategoryLeaderManagerError = (error) => ({
    type: DELETE_CATEGORY_LEADER_MANAGER_ERROR,
    error
});

export const updateDocumentLeaderManager = (leaderManager) => ({
    type: UPDATE_DOCUMENT_LEADER_MANAGER,
    leaderManager
});

export const updateLeaderManager = (documentDeleted) => ({
    type: UPDATE_LEADER_MANAGER,
    documentDeleted
});

export const getSubcategoryLeaderManagerRequesting = (token, id_leader_manager, id_subcategory) => ({
    type: GET_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    token,
    id_leader_manager,
    id_subcategory
});

export const getSubcategoryLeaderManagerSuccess = (leaderManager) => ({
    type: GET_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const getSubcategoryLeaderManagerError = (error) => ({
    type: GET_SUBCATEGORY_LEADER_MANAGER_ERROR,
    error
});

export const deleteSubcategoryLeaderManagerRequesting = (token, id_leader_manager, id_subcategory) => ({
    type: DELETE_SUBCATEGORY_LEADER_MANAGER_REQUESTING,
    token,
    id_leader_manager,
    id_subcategory
});

export const deleteSubcategoryLeaderManagerSuccess = (leaderManager) => ({
    type: DELETE_SUBCATEGORY_LEADER_MANAGER_SUCCESS,
    leaderManager
});

export const deleteSubcategoryLeaderManagerError = (error) => ({
    type: DELETE_SUBCATEGORY_LEADER_MANAGER_ERROR,
    error
});

export const deleteDeviceLeaderManagerRequesting = (id) => ({
    type: DELETE_DEVICE_LEADER_MANAGER_REQUESTING,
    id
});

export const deleteDeviceLeaderManagerSuccess = (leaderManager) => ({
   type: DELETE_DEVICE_LEADER_MANAGER_SUCCESS,
   leaderManager
});

export const deleteDeviceLeaderManagerError = (error) => ({
   type: DELETE_DEVICE_LEADER_MANAGER_ERROR,
   error
});
