import {
    GET_USER_REQUESTING,
    GET_USER_ERROR,
    GET_USER_SUCCESS,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_USER_REQUESTING,
    CHANGE_STATE_USER_REQUESTING,
    CHANGE_STATE_USER_SUCCESS,
    CHANGE_STATE_USER_ERROR,
    CREATE_USER_REQUESTING,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER_REQUESTING,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR, SET_FOCUS_USER, DELETE_DEVICE_REQUESTING, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_ERROR
} from './constants';

export const getUserRequesting = (cursor, filter, token) => ({
    type: GET_USER_REQUESTING,
    cursor,
    filter,
    token
});

export const getUserError = (error) => ({
    type: GET_USER_ERROR,
    error
});

export const getUserSuccess = (users) => ({
    type: GET_USER_SUCCESS,
    users
});
export const searchUserRequesting = (search, token) => ({
    type: SEARCH_USER_REQUESTING,
    search,
    token
});
export const searchUserSuccess = (user) => ({
    type: SEARCH_USER_SUCCESS,
    user
});
export const searchUserError = (error) => ({
    type: SEARCH_USER_ERROR,
    error
});
export const changeStateUserRequesting = (userId, token) => ({
    type: CHANGE_STATE_USER_REQUESTING,
    userId,
    token
});
export const changeStateUserSuccess = (user) => ({
    type: CHANGE_STATE_USER_SUCCESS,
    user
});
export const changeStateUserError = (error) => ({
    type: CHANGE_STATE_USER_ERROR,
    error
});
export const createUserRequesting = (user, token) => ({
    type: CREATE_USER_REQUESTING,
    user,
    token
});

export const createUserSuccess = (userCreated) => ({
    type: CREATE_USER_SUCCESS,
    userCreated
});

export const createUserError = (error) => ({
    type: CREATE_USER_ERROR,
    error
});
export const updateUserRequesting = (user, token, id) => ({
    type: UPDATE_USER_REQUESTING,
    user,
    token,
    id
});

export const updateUserSuccess = (userUpdate) => ({
    type: UPDATE_USER_SUCCESS,
    userUpdate
});

export const updateUserError = (error) => ({
    type: UPDATE_USER_ERROR,
    error
});

export const setFocusUser = (id,user) => ({
    type: SET_FOCUS_USER,
    id,
    user
});

export const deleteDeviceRequesting = (id) => ({
    type: DELETE_DEVICE_REQUESTING,
    id
});

export const deleteDeviceSuccess = (user) => ({
    type: DELETE_DEVICE_SUCCESS,
    user
});

export const deleteDeviceError = (error) => ({
   type: DELETE_DEVICE_ERROR,
   error
});
