import React from 'react';
import connect from "react-redux/es/connect/connect";
import {reduxForm} from "redux-form";
import RowPricePerHour from '../rows/rows-design/rowPricePerHour';
import RowPriceInside from '../rows/rows-design/rowPriceInside';
import RowPriceOutside from '../rows/rows-design/rowPriceOutside';
import RowPercentageRoca from '../rows/rows-design/rowPercentageRoca';
import RowPriceFixed from '../rows/rows-design/rowPriceFixed';
import RowHourSquareMeter from '../rows/rows-design/rowHourSquareMeter';

class TableDesignValue
    extends React.Component {

    state = {
        editable: false,
        editable2: false,
        // editable3: false,
        // editable4: false,
        // editable5: false,
        // editable6: false,
        editable7: false,
        editable8: false,
        editable9: false,
        editable10: false,
        value: '',
        value2: '',
        // value3: '',
        // value4: '',
        // value5: '',
        // value6: '',
        value7: '',
        value8: '',
        value9: '',
        value10: '',
    };

    handleChange = (key, value) => {
        this.setState({key: value})
        // if (Number.isInteger(value))
        //
    };

    handleEditable = (value, editable) => {
        this.setState({
           editable: value === 'pricePerHour' && editable,
           editable2:value === 'priceFixed' && editable,
           // editable3: value === 'priceConditioned' && editable,
           // editable4: value === 'priceResidential' && editable,
           // editable5: value === 'priceCommercial' && editable,
           // editable6: value === 'priceIndustrial' && editable,
           editable7: value === 'priceInside' && editable,
           editable8: value === 'priceOutside' && editable,
           editable9: value === 'percentageRoca' && editable,
           editable10: value === 'hourSquareMeter' && editable,
        });
    };

    render() {
        return (
            <div className="Conteiner-table">
                <table className="table-values" style={{width: '45.7%'}}>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>
                            <th style={{width: '400px'}}>Item</th>
                            <th>Acción</th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <RowPricePerHour editable={this.state.editable} handleEditable={this.handleEditable.bind(this)}/>
                    <RowPriceFixed editable={this.state.editable2} handleEditable={this.handleEditable.bind(this)}/>
                    <RowPriceInside editable={this.state.editable7} handleEditable={this.handleEditable.bind(this)}/>
                    <RowPriceOutside editable={this.state.editable8} handleEditable={this.handleEditable.bind(this)}/>
                    <RowPercentageRoca editable={this.state.editable9} handleEditable={this.handleEditable.bind(this)}/>
                    <RowHourSquareMeter editable={this.state.editable10} handleEditable={this.handleEditable.bind(this)}/>
                    </tbody>
                </table>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    client: state.client,
    rate: state.rate,
});

const connected = connect(mapStateToProps, {

})(TableDesignValue);

const formed = reduxForm({
    form: 'valueConfig'
})(connected);

export default formed
