import {call, put, all, takeEvery} from 'redux-saga/effects';
import {CHECK_AUTH} from "./constans";
import {checkAuthError, checkAuthSuccess} from "./actions";
import {handleApiErrors} from "../../commons/errors/apiErrors";

const verifyTokenURL = `${process.env.REACT_APP_API_URL}/api/verifyToken`;

const checkTokenJWT = () => {
    let token = localStorage.getItem('@ingetem:admin');
    return fetch(verifyTokenURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 400)
                throw json.data;
            if (json.code === 200) {
                return token
            }
        }).catch((error) => {
            throw error;
        })
};


function* checkAuthFlow() {
    try {
        const token = yield call(checkTokenJWT);
        yield put(checkAuthSuccess(token))
    } catch (error) {
        yield put(checkAuthError(error))
    }
}

function* checkAuthWatcher() {
    yield all([
        takeEvery(CHECK_AUTH, checkAuthFlow)
    ])
}

export default checkAuthWatcher;