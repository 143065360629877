import React from 'react';
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import SearchComponent from "../../search/search";
import FilterComponent from "../../filter/filter";
import {TableDesign} from "./../table/table-design";
import {ModalService} from "./../modals/add-service";
import {TableInstallation} from "./../table/table-installation";
import {TableMaintenance} from "./../table/table-maintenance";
import {
    servicesDesignGetRequesting,
    servicesInstallationGetRequesting,
    servicesMaintenanceGetRequesting,
    servicesSearchDesignRequesting,
    servicesSearchInstallationRequesting,
    servicesSearchMaintenanceGetRequesting,
    statesServiceGetRequesting,
    serviceFocus,
    confirmedWireTransferDesignPutRequesting,
    confirmedWireTransferInstallationPutRequesting,
    confirmedWireTransferMaintenancePutRequesting,
    resetStates,
    quotationFocus,
    confirmedWireTransferQuotationDesignPutRequesting,
    confirmedWireTransferQuotationInstallationPutRequesting,
    confirmedWireTransferQuotationMaintenancePutRequesting,
} from "../../../redux/service/actions";
import {NotFound} from "../../../commons/errors/notFound";
import {ModalStepsService} from "./../modals/modal-steps-service";
import {ModalConfirmedWireTransfer} from "./../modals/confirmed-wire-transfer";
import {toastr} from "react-redux-toastr";
import {ModalQuotation} from "./../modals/modal-quotation";

class ServiceMaintenanceComponent extends React.Component {

    state = {
        isService: false,
        showSteps: false,
        modalTransfer: false,
        modalQuotation: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isService: modal === 'diseño',
            showSteps: modal === 'steps',
            modalTransfer: modal === 'transfer',
            modalQuotation: modal === 'quotation',
        })
    };

    handleHiddenModal = () => {
        this.setState({
            isService: false,
            showSteps: false,
            modalTransfer: false,
            modalQuotation: false,
        });
        this.props.resetStates();
    };

    handleSearch = (value, type) => {
        const {
            servicesSearchDesignRequesting,
            servicesSearchInstallationRequesting,
            servicesSearchMaintenanceGetRequesting,
            client: {token},
            service: {stringSearchDesign, stringSearchInstallation, stringSearchMaintenance},
        } = this.props;
        switch (type) {
            // case 'diseño':
            //     if ((stringSearchDesign !== value.search) && value.search !== '')
            //         servicesSearchDesignRequesting(value.search, token);
            //     break;
            // case 'instalación':
            //     if ((stringSearchInstallation !== value.search) && value.search !== '')
            //         servicesSearchInstallationRequesting(value.search, token);
            //     break;
            case 'mantenimiento':
                if ((stringSearchMaintenance !== value.search) && value.search !== '')
                    servicesSearchMaintenanceGetRequesting(value.search, token);
                break;
            default:
                break;
        }
    };

    handleFocus = (service) => {
        const {serviceFocus} = this.props;
        serviceFocus(service);
    };

    handleFilter = (value, type) => {
        const {
            servicesDesignGetRequesting,
            servicesInstallationGetRequesting,
            servicesMaintenanceGetRequesting,
            client: {token},
            service: {filterDesign, filterInstallation, filterMaintenance},
        } = this.props;
        switch (type) {
            // case 'diseño':
            //     if ((filterDesign !== value) && value !== '')
            //         servicesDesignGetRequesting(1, value, token);
            //     break;
            // case 'instalación':
            //     if ((filterInstallation !== value) && value !== '')
            //         servicesInstallationGetRequesting(1, value, token);
            //     break;
            case 'mantenimiento':
                if ((filterMaintenance !== value) && value !== '')
                    servicesMaintenanceGetRequesting(1, value, token);
                break;
            default:
                break;
        }
    };

    handleGetData = (value, type) => {
        const {
            servicesDesignGetRequesting,
            servicesInstallationGetRequesting,
            servicesMaintenanceGetRequesting,
            client: {token},
            service: {
                paginateDesign, filterDesign, hasMoreDesign, hasLessDesign,
                paginateInstallation, filterInstallation, hasMoreInstallation, hasLessInstallation,
                paginateMaintenance, filterMaintenance, hasMoreMaintenance, hasLessMaintenance,
            },
        } = this.props;
        switch (type) {
            // case 'diseño':
            //     if (value === 1 && hasMoreDesign)
            //         servicesDesignGetRequesting(paginateDesign + value, filterDesign, token);
            //     if (value === -1 && hasLessDesign)
            //         servicesDesignGetRequesting(paginateDesign + value, filterDesign, token);
            //     break;
            // case 'instalación':
            //     if (value === 1 && hasMoreInstallation)
            //         servicesInstallationGetRequesting(paginateInstallation + value, filterInstallation, token);
            //     if (value === -1 && hasLessInstallation)
            //         servicesInstallationGetRequesting(paginateInstallation + value, filterInstallation, token);
            //     break;
            case 'mantenimiento':
                if (value === 1 && hasMoreMaintenance)
                    servicesMaintenanceGetRequesting(paginateMaintenance + value, filterMaintenance, token);
                if (value === -1 && hasLessMaintenance)
                    servicesMaintenanceGetRequesting(paginateMaintenance + value, filterMaintenance, token);
                break;
            default:
                break;
        }
    };

    handleGetCsv = (type) => {

        switch (type) {
            // case 'diseño':
            //     window.open(`${process.env.REACT_APP_API_URL}/api/services/get/export/design`, "_self");
            //     break;
            // case 'instalación':
            //     window.open(`${process.env.REACT_APP_API_URL}/api/services/get/export/installation`, "_self");
            //     break;
            case 'mantenimiento':
                window.open(`${process.env.REACT_APP_API_URL}/api/services/get/export/maintenance`, "_self");
                break;
            default:
                break;
        }
    };

    componentWillMount() {
        const {
            servicesDesignGetRequesting,
            servicesInstallationGetRequesting,
            servicesMaintenanceGetRequesting,
            statesServiceGetRequesting,
            client: {token},
            service: {paginateDesign, paginateInstallation, paginateMaintenance},
        } = this.props;
        // servicesDesignGetRequesting(paginateDesign, 'Todos', token);
        // servicesInstallationGetRequesting(paginateInstallation, 'Todos', token);
        servicesMaintenanceGetRequesting(paginateMaintenance, 'Todos', token);
        statesServiceGetRequesting(token);
    };

    handleConfirmedWireTransfer = () => {
        const {
            client: {token},
            confirmedWireTransferDesignPutRequesting,
            confirmedWireTransferInstallationPutRequesting,
            confirmedWireTransferMaintenancePutRequesting,
            service: {service, quotation},
            confirmedWireTransferQuotationDesignPutRequesting,
            confirmedWireTransferQuotationInstallationPutRequesting,
            confirmedWireTransferQuotationMaintenancePutRequesting,
        } = this.props;
        if (service.hasOwnProperty('id')) {
            switch (service.sub_categoria.nombre) {
                // case 'Diseño':
                //     confirmedWireTransferDesignPutRequesting(token, service.id);
                //     break;
                // case 'Instalación':
                //     confirmedWireTransferInstallationPutRequesting(token, service.id);
                //     break;
                case 'Mantenimiento':
                    confirmedWireTransferMaintenancePutRequesting(token, service.id);
                    break;
                default:
                    break;
            }
        }
        if (quotation.hasOwnProperty('id')) {
            switch (quotation.subcategoria) {
                // case 'Diseño':
                //     confirmedWireTransferQuotationDesignPutRequesting(token, quotation.id);
                //     break;
                // case 'Instalación':
                //     confirmedWireTransferQuotationInstallationPutRequesting(token, quotation.id);
                //     break;
                case 'Mantenimiento':
                    confirmedWireTransferQuotationMaintenancePutRequesting(token, quotation.id);
                    break;
                default:
                    break;
            }
        }
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {service: {successConfirmed}} = this.props;

        if (nextProps.service.successConfirmed && !successConfirmed) {
            this.handleHiddenModal();
            toastr.success('Pago confirmado', 'Se ha actualizado el servicio.');
        }
    }

    handleFocusQuotation = (quotation) => {
        this.props.quotationFocus(quotation);
    };

    render() {
        const {
            service: {
                servicesDesign, servicesInstallation, servicesMaintenance, servicesSearchDesign, servicesSearchInstallation,
                servicesSearchMaintenance, stringSearchDesign, stringSearchInstallation, stringSearchMaintenance, statesService,
                service, quotation, requestingConfirmed
            },
        } = this.props;
        return (
            <div className="styles-container">
                {this.state.showSteps && service.hasOwnProperty('pasos') && service.pasos.data.length > 0 && (
                    <ModalStepsService
                        steps={service.pasos.data}
                        service={service}
                        hidden={this.handleHiddenModal.bind(this)}
                        stop={this.stopPropagation.bind(this)}
                    />
                )}
                {/*Start Services Design*/}
                {/*<SearchComponent*/}
                {/*    type={'diseño'}*/}
                {/*    placeholder={'Consulta un servicio'}*/}
                {/*    handleSearch={this.handleSearch.bind(this)}>*/}
                {/*    {stringSearchDesign !== '' && (*/}
                {/*        servicesSearchDesign.length > 0*/}
                {/*            ? <TableDesign*/}
                {/*                services={servicesSearchDesign}*/}
                {/*                showModal={this.handleShowModal.bind(this)}*/}
                {/*                serviceFocus={this.handleFocus.bind(this)}*/}
                {/*                quotationFocus={this.handleFocusQuotation.bind(this)}*/}
                {/*            />*/}
                {/*            : <NotFound/>*/}
                {/*    )}*/}
                {/*</SearchComponent>*/}
                {/*<div className="conteiner-page-sector1">*/}
                {/*    <FilterComponent*/}
                {/*        type={'diseño'}*/}
                {/*        handleShowModal={this.handleShowModal.bind(this)}*/}
                {/*        handleFilter={this.handleFilter.bind(this)}*/}
                {/*        handleGetCsv={this.handleGetCsv.bind(this)}*/}
                {/*        getData={this.handleGetData.bind(this)}*/}
                {/*        states={statesService}*/}
                {/*        activeModal={false}*/}
                {/*    />*/}
                {/*    {}*/}
                {/*    {servicesDesign.length > 0*/}
                {/*        ? <TableDesign*/}
                {/*            services={servicesDesign}*/}
                {/*            showModal={this.handleShowModal.bind(this)}*/}
                {/*            serviceFocus={this.handleFocus.bind(this)}*/}
                {/*            quotationFocus={this.handleFocusQuotation.bind(this)}*/}
                {/*        />*/}
                {/*        : <NotFound/>*/}
                {/*    }*/}
                {/*    {this.state.isService && (*/}
                {/*        <ModalService*/}
                {/*            stop={this.stopPropagation.bind(this)}*/}
                {/*            hidden={this.handleHiddenModal.bind(this)}*/}
                {/*            disable={true}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*Start Services Installation*/}
                {/*<SearchComponent*/}
                {/*    type={'instalación'}*/}
                {/*    placeholder={'Consulta un servicio'}*/}
                {/*    handleSearch={this.handleSearch.bind(this)}>*/}
                {/*    {stringSearchInstallation !== '' && (*/}
                {/*        servicesSearchInstallation.length > 0*/}
                {/*            ? <TableInstallation*/}
                {/*                showModal={this.handleShowModal.bind(this)}*/}
                {/*                serviceFocus={this.handleFocus.bind(this)}*/}
                {/*                services={servicesSearchInstallation}*/}
                {/*                quotationFocus={this.handleFocusQuotation.bind(this)}*/}
                {/*            />*/}
                {/*            : <NotFound/>*/}
                {/*    )}*/}
                {/*</SearchComponent>*/}
                {/*<div className="conteiner-page-sector1">*/}
                {/*    <FilterComponent*/}
                {/*        type={'instalación'}*/}
                {/*        handleShowModal={this.handleShowModal.bind(this)}*/}
                {/*        handleFilter={this.handleFilter.bind(this)}*/}
                {/*        handleGetCsv={this.handleGetCsv.bind(this)}*/}
                {/*        getData={this.handleGetData.bind(this)}*/}
                {/*        states={statesService}*/}
                {/*        activeModal={false}*/}
                {/*    />*/}
                {/*    {servicesInstallation.length > 0*/}
                {/*        ? <TableInstallation*/}
                {/*            services={servicesInstallation}*/}
                {/*            showModal={this.handleShowModal.bind(this)}*/}
                {/*            serviceFocus={this.handleFocus.bind(this)}*/}
                {/*            quotationFocus={this.handleFocusQuotation.bind(this)}*/}
                {/*        />*/}
                {/*        : <NotFound/>*/}
                {/*    }*/}
                {/*    {this.state.isService && (*/}
                {/*        <ModalService*/}
                {/*            stop={this.stopPropagation.bind(this)}*/}
                {/*            hidden={this.handleHiddenModal.bind(this)}*/}
                {/*            disable={true}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*/!*Start Service Maintenance*!/*/}
                <SearchComponent
                    type={'mantenimiento'}
                    placeholder={'Consulta un servicio'}
                    handleSearch={this.handleSearch.bind(this)}>
                    {stringSearchMaintenance !== '' && (
                        servicesSearchMaintenance.length > 0
                            ? <TableMaintenance
                                services={servicesSearchMaintenance}
                                showModal={this.handleShowModal.bind(this)}
                                serviceFocus={this.handleFocus.bind(this)}
                                quotationFocus={this.handleFocusQuotation.bind(this)}
                            />
                            : <NotFound/>
                    )}
                </SearchComponent>
                <div className="conteiner-page-sector1">
                    <FilterComponent
                        type={'mantenimiento'}
                        handleShowModal={this.handleShowModal.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                        handleGetCsv={this.handleGetCsv.bind(this)}
                        getData={this.handleGetData.bind(this)}
                        states={statesService}
                        activeModal={false}
                    />
                    {servicesMaintenance.length > 0
                        ? <TableMaintenance
                            services={servicesMaintenance}
                            showModal={this.handleShowModal.bind(this)}
                            serviceFocus={this.handleFocus.bind(this)}
                            quotationFocus={this.handleFocusQuotation.bind(this)}
                        />
                        : <NotFound/>
                    }
                    {this.state.isService && (
                        <ModalService
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            disable={true}
                        />
                    )}
                    {this.state.modalTransfer && (
                        <ModalConfirmedWireTransfer
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            confirmed={this.handleConfirmedWireTransfer.bind(this)}
                            requesting={requestingConfirmed}
                        />
                    )}
                    {this.state.modalQuotation && (
                        <ModalQuotation
                            stop={this.stopPropagation.bind(this)}
                            hidden={this.handleHiddenModal.bind(this)}
                            quotation={quotation}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    service: state.service,
    client: state.client,
});

const connected = connect(mapStateToProps, {
    servicesDesignGetRequesting,
    servicesInstallationGetRequesting,
    servicesMaintenanceGetRequesting,
    servicesSearchDesignRequesting,
    servicesSearchInstallationRequesting,
    servicesSearchMaintenanceGetRequesting,
    statesServiceGetRequesting,
    serviceFocus,
    confirmedWireTransferDesignPutRequesting,
    confirmedWireTransferInstallationPutRequesting,
    confirmedWireTransferMaintenancePutRequesting,
    resetStates,
    quotationFocus,
    confirmedWireTransferQuotationDesignPutRequesting,
    confirmedWireTransferQuotationInstallationPutRequesting,
    confirmedWireTransferQuotationMaintenancePutRequesting,
})(ServiceMaintenanceComponent);

const formed = reduxForm({
    form: 'ServiceComponent'
})(connected);

export default formed