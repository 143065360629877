import React from 'react';
import TableMaintenanceValue from "../table/table-maintenance-value";
import FilterConfigMaintenanceComponent from "../../filter/filter-config-maintenance";

export class ConfigurationComponentMantenance
    extends React.Component {

    state = {
        isAddBrand: false,
    };

    handleShowModal = (modal) => {
        this.setState({
            isAddBrand: modal === 'agregar marca',
        })
    };
    handleHiddenModal = () => {
        this.setState({
            isAddBrand: false,
        })
    };
    stopPropagation = (e) => {
        e.stopPropagation();
    };

// *******************************

    render() {
        return (
            <div className="styles-container">
                <div className="conteiner-page-sector1">
                    <div className="styles-title-page-h1">
                        <h1>Configuración servicio mantenimiento</h1>
                        <FilterConfigMaintenanceComponent
                            type={'Gerente lider'}
                            handleShowModal={this.handleShowModal.bind(this)}
                        />
                    </div>
                    <TableMaintenanceValue/>
                </div>
            </div>
        )
    }
}
