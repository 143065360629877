import {call, put, all, takeEvery} from 'redux-saga/effects';
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {
    CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING,
    CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING,
    SERVICES_DESIGN_GET_REQUESTING,
    SERVICES_INSTALLATION_GET_REQUESTING,
    SERVICES_MAINTENANCE_GET_REQUESTING,
    SERVICES_SEARCH_DESIGN_REQUESTING,
    SERVICES_SEARCH_INSTALLATION_REQUESTING,
    SERVICES_SEARCH_MAINTENANCE_REQUESTING,
    STATES_SERVICE_GET_REQUESTING,
} from "./constants";
import {
    servicesDesignGetSuccess,
    servicesDesignGetError,
    servicesInstallationGetSuccess,
    servicesInstallationGetError,
    servicesMaintenanceGetSuccess,
    servicesMaintenanceGetError,
    servicesSearchDesignSuccess,
    servicesSearchDesignError,
    servicesSearchInstallationSuccess,
    servicesSearchInstallationError,
    servicesSearchMaintenanceGetSuccess,
    servicesSearchMaintenanceGetError,
    statesServiceGetSuccess,
    statesServiceGetError,
    confirmedWireTransferDesignPutSuccess,
    confirmedWireTransferDesignPutError,
    confirmedWireTransferInstallationPutSuccess,
    confirmedWireTransferInstallationPutError,
    confirmedWireTransferMaintenancePutSuccess,
    confirmedWireTransferMaintenancePutError,
    confirmedWireTransferQuotationDesignPutSuccess,
    confirmedWireTransferQuotationDesignPutError,
    confirmedWireTransferQuotationInstallationPutSuccess,
    confirmedWireTransferQuotationInstallationPutError,
    confirmedWireTransferQuotationMaintenancePutSuccess, confirmedWireTransferQuotationMaintenancePutError,
} from "./actions";

const servicesURL = `${process.env.REACT_APP_API_URL}/api/services`;
const confirmedWireTransferService = `${process.env.REACT_APP_API_URL}/api/confirmedWireTransferService`;
const confirmedWireTransferQuotation = `${process.env.REACT_APP_API_URL}/api/confirmedWireTransferQuotation`;

const servicesDesignGetApi = (paginateDesign, filterDesign, token) => {
    const url = `${servicesURL}/filterType/${filterDesign}?page=${paginateDesign}`;
    let body = {
        subcategory: 'Diseño'
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesDesignGetFLow(action) {
    try {
        const {paginateDesign, filterDesign, token} = action;
        const servicesDesignGet = yield call(servicesDesignGetApi, paginateDesign, filterDesign, token);
        yield put(servicesDesignGetSuccess(servicesDesignGet));
    } catch (error) {
        yield put(servicesDesignGetError(error));
    }
}

const servicesInstallationGetApi = (paginateInstallation, filterInstallation, token) => {
    const url = `${servicesURL}/filterType/${filterInstallation}?page=${paginateInstallation}`;
    let body = {
        subcategory: 'Instalación'
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesInstallationGetFLow(action) {
    try {
        const {paginateInstallation, filterInstallation, token} = action;
        const servicesInstallationGet = yield call(servicesInstallationGetApi, paginateInstallation, filterInstallation, token);
        yield put(servicesInstallationGetSuccess(servicesInstallationGet));
    } catch (error) {
        yield put(servicesInstallationGetError(error));
    }
}

const servicesMaintenanceGetApi = (paginateMaintenance, filterMaintenance, token) => {
    const url = `${servicesURL}/filterType/${filterMaintenance}?page=${paginateMaintenance}`;
    let body = {
        subcategory: 'Mantenimiento'
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesMaintenanceGetFLow(action) {
    try {
        const {paginateMaintenance, filterMaintenance, token} = action;
        const servicesMaintenanceGet = yield call(servicesMaintenanceGetApi, paginateMaintenance, filterMaintenance, token);
        yield put(servicesMaintenanceGetSuccess(servicesMaintenanceGet));
    } catch (error) {
        yield put(servicesMaintenanceGetError(error));
    }
}

const servicesDesignSearchApi = (search, token) => {
    const url = `${servicesURL}/search`;
    let body = {
        search: search,
        subcategory: 'Diseño',
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesDesignSearchFlow(action) {
    try {
        const {search, token} = action;
        const servicesDesignSearch = yield call(servicesDesignSearchApi, search, token);
        yield put(servicesSearchDesignSuccess(servicesDesignSearch));
    } catch (error) {
        yield put(servicesSearchDesignError(error));
    }
}

const servicesInstallationSearchApi = (search, token) => {
    const url = `${servicesURL}/search`;
    let body = {
        search: search,
        subcategory: 'Instalación',
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesInstallationSearchFlow(action) {
    try {
        const {search, token} = action;
        const servicesInstallationSearch = yield call(servicesInstallationSearchApi, search, token);
        yield put(servicesSearchInstallationSuccess(servicesInstallationSearch));
    } catch (error) {
        yield put(servicesSearchInstallationError(error));
    }
}

const servicesMaintenanceSearchApi = (search, token) => {
    const url = `${servicesURL}/search`;
    let body = {
        search: search,
        subcategory: 'Mantenimiento',
    };

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
            {
                return json.data.data;
            }
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* servicesMaintenanceSearchFlow(action) {
    try {
        const {search, token} = action;
        const servicesMaintenanceSearch = yield call(servicesMaintenanceSearchApi, search, token);
        yield put(servicesSearchMaintenanceGetSuccess(servicesMaintenanceSearch));
    } catch (error) {
        yield put(servicesSearchMaintenanceGetError(error));
    }
}

const statesServiceGetApi = (token) => {
    const url = `${servicesURL}/get/states`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.data.data.length > 0)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* statesServicesGetFLow(action) {
    try {
        const {token} = action;
        const states = yield call(statesServiceGetApi, token);
        yield put(statesServiceGetSuccess(states));
    } catch (error) {
        yield put(statesServiceGetError(error));
    }
}

const confirmedWereTransferServicePutApi = (token, serviceId) => {
    let url = `${confirmedWireTransferService}/${serviceId}`;
    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200 && json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* confirmedWereTransferDesignPutFlow(action) {
    try {
        const {token, serviceId} = action;
        const service = yield call(confirmedWereTransferServicePutApi, token, serviceId);
        yield put(confirmedWireTransferDesignPutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferDesignPutError(error));
    }
}

function* confirmedWereTransferInstallationPutFlow(action) {
    try {
        const {token, serviceId} = action;
        const service = yield call(confirmedWereTransferServicePutApi, token, serviceId);
        yield put(confirmedWireTransferInstallationPutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferInstallationPutError(error));
    }
}

function* confirmedWereTransferMaintenancePutFlow(action) {
    try {
        const {token, serviceId} = action;
        const service = yield call(confirmedWereTransferServicePutApi, token, serviceId);
        yield put(confirmedWireTransferMaintenancePutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferMaintenancePutError(error));
    }
}

const confirmedWereTransferQuotationPutApi = (token, quotationId) => {
    let url = `${confirmedWireTransferQuotation}/${quotationId}`;
    return fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: 'Bearer' + token,
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 200 && json.data.data.hasOwnProperty('id'))
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        })
};

function* confirmedWereTransferQuotationDesignPutFlow(action) {
    try {
        const {token, quotationId} = action;
        const service = yield call(confirmedWereTransferQuotationPutApi, token, quotationId);
        yield put(confirmedWireTransferQuotationDesignPutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferQuotationDesignPutError(error));
    }
}

function* confirmedWereTransferQuotationInstallationPutFlow(action) {
    try {
        const {token, quotationId} = action;
        const service = yield call(confirmedWereTransferQuotationPutApi, token, quotationId);
        yield put(confirmedWireTransferQuotationInstallationPutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferQuotationInstallationPutError(error));
    }
}

function* confirmedWereTransferQuotationMaintenancePutFlow(action) {
    try {
        const {token, quotationId} = action;
        const service = yield call(confirmedWereTransferQuotationPutApi, token, quotationId);
        yield put(confirmedWireTransferQuotationMaintenancePutSuccess(service));
    } catch (error) {
        yield put(confirmedWireTransferQuotationMaintenancePutError(error));
    }
}

function* serviceWatcher() {
    yield all([
        takeEvery(SERVICES_DESIGN_GET_REQUESTING, servicesDesignGetFLow),
        takeEvery(SERVICES_INSTALLATION_GET_REQUESTING, servicesInstallationGetFLow),
        takeEvery(SERVICES_MAINTENANCE_GET_REQUESTING, servicesMaintenanceGetFLow),
        takeEvery(SERVICES_SEARCH_DESIGN_REQUESTING, servicesDesignSearchFlow),
        takeEvery(SERVICES_SEARCH_INSTALLATION_REQUESTING, servicesInstallationSearchFlow),
        takeEvery(SERVICES_SEARCH_MAINTENANCE_REQUESTING, servicesMaintenanceSearchFlow),
        takeEvery(STATES_SERVICE_GET_REQUESTING, statesServicesGetFLow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_DESIGN_PUT_REQUESTING, confirmedWereTransferDesignPutFlow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_INSTALLATION_PUT_REQUESTING, confirmedWereTransferInstallationPutFlow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_MAINTENANCE_PUT_REQUESTING, confirmedWereTransferMaintenancePutFlow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_QUOTATION_DESIGN_PUT_REQUESTING, confirmedWereTransferQuotationDesignPutFlow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_QUOTATION_INSTALLATION_PUT_REQUESTING, confirmedWereTransferQuotationInstallationPutFlow),
        takeEvery(CONFIRMED_WIRE_TRANSFER_QUOTATION_MAINTENANCE_PUT_REQUESTING, confirmedWereTransferQuotationMaintenancePutFlow),
    ])
}

export default serviceWatcher
