import React from "react";
import SearchComponent from "../search/search";
import FilterComponent from "../filter/filter";
import { TableClient } from "./table/table-client";
import ModalAddClient from "./modals/add-client";
import {
  getUserRequesting,
  searchUserRequesting,
  changeStateUserRequesting,
  setFocusUser,
  deleteDeviceRequesting,
} from "../../redux/user/actions";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NotFound } from "../../commons/errors/notFound";
import { statesUserRequesting } from "../../commons/states/actions";
import { getTypeIdentificationRequesting } from "../../commons/type_identification/actions";
import { getCountryRequesting } from "../../commons/countries/actions";

class ClientComponent extends React.Component {
  state = {
    isClients: false,
    isAddClients: false,
  };

  componentWillMount() {
    const {
      client: { token },
      user: { cursor, filter },
      getUserRequesting,
      statesUserRequesting,
      getTypeIdentificationRequesting,
      getCountryRequesting,
    } = this.props;

    getUserRequesting(cursor, filter, token);
    statesUserRequesting(token);
    getTypeIdentificationRequesting(token);
    getCountryRequesting(token);
  }

  componentWillUnmount() {
    const {
      searchUserRequesting,
      client,
      user: { search },
    } = this.props;
    if (search) {
      searchUserRequesting(undefined, client.token);
    }
  }

  handleGetData = (cursor) => {
    const { getUserRequesting, client, user } = this.props;
    if (cursor === 1 && user.hasMore)
      getUserRequesting(user.cursor + cursor, user.filter, client.token);
    if (cursor === -1 && user.hasLess)
      getUserRequesting(user.cursor + cursor, user.filter, client.token);
  };
  handleStateChangeUser = (id) => {
    const { changeStateUserRequesting, client } = this.props;
    changeStateUserRequesting(id, client.token);
  };
  handleSearch = (value) => {
    const { searchUserRequesting, client, user } = this.props;
    if (user.search !== value.search && value.search !== "")
      searchUserRequesting(value.search, client.token);
  };
  handleFilter = (value) => {
    const { getUserRequesting, client, user } = this.props;
    if (user.filter !== value)
      getUserRequesting(user.cursor, value, client.token);
  };
  handleShowModal = (modal) => {
    this.setState({
      isClients: modal === "clientes",
      isAddClients: modal === "cliente",
    });
  };
  handleHiddenModal = () => {
    this.setState({
      isClients: false,
      isAddClients: false,
    });
  };

  handleFocusUser = (id, user) => {
    const { setFocusUser } = this.props;
    setFocusUser(id, user);
  };
  handleGetCsv = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/exports/user`, "_blank");
  };

  handleDeleteDevice = (id) => {
    const { deleteDeviceRequesting } = this.props;
    deleteDeviceRequesting(id);
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      user: { users, search, user },
      state: { statesUser },
    } = this.props;
    return (
      <div className="styles-container">
        <SearchComponent
          type={"clientes"}
          placeholder={"Digite el nombre"}
          handleSearch={this.handleSearch.bind(this)}
        >
          {search &&
            (user.length > 0 ? (
              <TableClient
                users={user}
                handleDeleteDevice={this.handleDeleteDevice.bind(this)}
                handleStateChangeUser={this.handleStateChangeUser.bind(this)}
                focus={this.handleFocusUser.bind(this)}
              />
            ) : (
              <NotFound />
            ))}
        </SearchComponent>
        <div className="conteiner-page-sector1">
          <FilterComponent
            activeModal={true}
            type={"cliente"}
            handleShowModal={this.handleShowModal.bind(this)}
            handleGetCsv={this.handleGetCsv.bind(this)}
            handleFilter={this.handleFilter.bind(this)}
            getData={this.handleGetData.bind(this)}
            states={statesUser}
          />
          {users.length > 0 ? (
            <TableClient
              handleDeleteDevice={this.handleDeleteDevice.bind(this)}
              users={users}
              handleStateChangeUser={this.handleStateChangeUser.bind(this)}
              focus={this.handleFocusUser.bind(this)}
            />
          ) : (
            <NotFound />
          )}

          {this.state.isAddClients && (
            <ModalAddClient
              stop={this.stopPropagation.bind(this)}
              hidden={this.handleHiddenModal.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  user: state.user,
  state: state.state,
});

const connected = connect(mapStateToProps, {
  getUserRequesting,
  searchUserRequesting,
  statesUserRequesting,
  changeStateUserRequesting,
  getTypeIdentificationRequesting,
  getCountryRequesting,
  setFocusUser,
  deleteDeviceRequesting,
})(ClientComponent);

const formed = reduxForm({
  form: "ClientComponent",
})(connected);

export default formed;
