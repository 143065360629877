import React from 'react';

export class ModalRemove extends React.Component {


    render() {
        return (
            <div className={'body-blur'} onClick={this.props.hidden.bind(this)}>
                <div className="remove-modal " onClick={this.props.stop.bind(this)}>

                    <div className="button-container-close">
                        <button className="Btn-modal-cancel " onClick={this.props.hidden.bind(this)}><i
                            className="icon-cancel"/>
                        </button>
                    </div>
                    <div className="text-modal">
                    <h1>Estas seguro de eliminar estos datos</h1>
                    </div>
                    <br/>
                    <div className="button-container">
                        <div className="btn-container">
                            <button className="Btn-modal" onClick={this.props.hidden.bind(this)}>Cancelar<i className="icon-cancel styles-icons"/></button>
                            <button className="Btn-modal">Eliminar<i className="icon-ok styles-icons"/></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
