import React from 'react';
import RowPercentageVisitRoca from "../rows/rows-installation/rowPercentageVisitRoca";
import RowPercentageVisitLeaderManager from "../rows/rows-installation/rowPercentageVisitLeaderManager";
import RowPercentageRoca from "../rows/rows-installation/rowPercentageRoca";
import RowPercentageLeaderManager from "../rows/rows-installation/rowPercentageLeaderManager";
import RowPriceVisit from '../rows/rows-installation/rowPriceVisit';
import RowPriceService from '../rows/rows-installation/rowPriceService';

import connect from "react-redux/es/connect/connect";
import {reduxForm} from "redux-form";

class TableInstallationValue
    extends React.Component {

    state = {
        editable: false,
        editable2: false,
        editable3: false,
        editable4: false,
        editable5: false,
        editable6: false,
        value: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
    };

    handleChange = (key, value) => {
        this.setState({key: value})
        // if (Number.isInteger(value))
        //
    };

    handleEditable = (value, editable) => {
        this.setState({
            editable: value === 'percentageVisitRoca' && editable,
            editable2:value === 'percentageVisitLeaderManager' && editable,
            editable3: value === 'percentageRoca' && editable,
            editable4: value === 'percentageLeaderManager' && editable,
            editable5: value === 'priceVisit' && editable,
            editable6: value === 'priceService' && editable,
        });
    };

    render() {
        return (
            <div className="Conteiner-table">
                <table className="table-values" style={{width: '45.7%'}}>
                    <thead>
                    <tr>
                        <th >Nombre</th>
                        <th>
                            <th style={{width: '400px'}}>Item</th>
                            <th>Accíon</th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <RowPercentageVisitRoca editable={this.state.editable} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPercentageVisitLeaderManager editable={this.state.editable2} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPercentageRoca editable={this.state.editable3} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPercentageLeaderManager editable={this.state.editable4} handleEditable={this.handleEditable.bind(this)}/>
                        <RowPriceVisit editable={this.state.editable5} handleEditable={this.handleEditable.bind(this)}/>
                    </tbody>
                </table>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    client: state.client,
    rate: state.rate,
});

const connected = connect(mapStateToProps, {

})(TableInstallationValue);

const formed = reduxForm({
    form: 'valueConfigInstallation'
})(connected);

export default formed